import { CalendarMonth } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ResponseModal from "../../../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import { addDays } from "date-fns";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import Modal from "../../../../../global/Modal";
import FormField from "../../../../../global/FormComponents/field/FormField";
import { autoCompleteStyles, inputStyles, selectStyles } from "../../constant";

export default function ActionItemAdd({
  addOpen,
  setAddOpen,
  activeActionItem,
  setActiveActionItem,
  caseObj,
  trigger,
  setTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [dateTimeVal, setDateTimeValue] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [selectedCase, setSelectedCase] = useState(!caseObj ? "" : caseObj.pk);
  const [selectedActingUser, setSelectedActingUser] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [lawFirm, setLawFirm] = useState(false);
  const [assignableUserList, setAssignableUserList] = useState([]);

  const [clientCases, setClientCases] = useState([]);
  const [caseSearch, setCaseSearch] = useState("");
  const [actionDetail, setActionDetail] = useState("");
  const [toggleAddProvider, setToggleAddProvider] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [userSearch, setUserSearch] = useState("");

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setTrigger(!trigger);
    setAddOpen(false);
    setSelectedActingUser("");
    setSelectedCase("");
    setActionDetail("");
    setDateTimeValue("");
    if (!setActiveActionItem) {
      return;
    } else {
      setActiveActionItem(null);
    }
  };

  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      setSelectedCase(caseObj);
    }
  }, [caseObj, setSelectedCase, addOpen]);

  useEffect(() => {
    if (!activeActionItem) {
      return;
    } else {
      setSelectedCase(activeActionItem.on_case);
      setSelectedActingUser(
        !activeActionItem.acting_user ? "" : activeActionItem.acting_user
      );
      setActionDetail(activeActionItem.action_detail);
      setDateTimeValue(new Date(activeActionItem.due_date));
      setSelectedProvider(
        !activeActionItem.on_provider ? "" : activeActionItem.on_provider.pk
      );
      setToggleAddProvider(!activeActionItem.on_provider ? false : true);
      setLawFirm(!activeActionItem.on_firm ? false : true);
    }
  }, [
    addOpen,
    setSelectedActingUser,
    setSelectedCase,
    setActionDetail,
    setDateTimeValue,
    activeActionItem,
  ]);

  const getUsers = () => {
    if (!selectedCase) {
      return;
    } else {
      let apiUrl = `/api/users/all_assignable_users/?ordering=name&${userSearch}`;
      axios
        .get(apiUrl, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setAssignableUserList(data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    }
  };

  const getProviders = () => {
    if (!selectedCase) {
      return;
    } else {
      axios
        .get(`/api/pro_cli_status/?case=${selectedCase.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setProviderList(data);
        });
    }
  };

  useDebouncedEffect(() => getUsers(), [selectedCase, userSearch], 250);

  useDebouncedEffect(() => getProviders(), [selectedCase], 250);

  useDebouncedEffect(
    () => {
      if (!addOpen) {
        return;
      } else {
        axios
          .get(
            `/api/case/?ordering=name${
              !caseSearch ? "" : `&search=${caseSearch}`
            }`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            setClientCases(data);
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response);
            }
          });
      }
    },
    [caseSearch, addOpen],
    250
  );

  const handleNewActionSubmit = () => {
    if (!selectedActingUser) {
      setOpenError(true);
      setErrorArray([
        "Acting User : You must attach an Acting User before proceeding",
      ]);
      return;
    } else {
      let postData = {
        on_case: selectedCase.pk,
        acting_user: selectedActingUser.pk,
        action_detail: actionDetail,
        ...(!selectedProvider ? {} : { on_provider: selectedProvider }),
        ...(!lawFirm ? {} : { on_firm: selectedCase.law_firm }),
        due_date: !dateTimeVal ? new Date() : dateTimeVal,
        status: "pending",
      };

      console.log(postData);

      axios
        .post("/api/actionable_items/", postData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        })
        .then(() => {
          setOpenSuccess(!openSuccess);
        })
        .catch((error) => {
          if (error.response) {
            setOpenError(!openError);
            let res = error.response.data;
            let errArr = ApiRequestErrorHandler(res);
            setErrorArray(errArr);
          }
        });
    }
  };

  const patchAction = () => {
    let patchData = {
      on_case: selectedCase.pk,
      acting_user: selectedActingUser.pk,
      action_detail: actionDetail,
      due_date: !dateTimeVal ? new Date() : dateTimeVal,
      ...(!selectedProvider ? {} : { on_provider: selectedProvider }),
      ...(!lawFirm ? {} : { on_firm: selectedCase.law_firm }),
    };

    axios
      .patch(`/api/actionable_items/${activeActionItem.id}/`, patchData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
      })
      .catch((error) => {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const handleClose = () => {
    setAddOpen(false);
    setSelectedActingUser("");
    setSelectedCase("");
    setActionDetail("");
    setDateTimeValue("");
    setLawFirm("");
    if (!activeActionItem) {
      return;
    } else {
      setActiveActionItem(null);
    }
  };

  const handleProvCheck = () => {
    setToggleAddProvider(!toggleAddProvider);
    setSelectedProvider("");
  };

  const handleActingUserSelect = (e, user) => {
    setSelectedActingUser(user);
  };

  const handleCaseSelect = (e, caseItem) => {
    setSelectedCase(caseItem);

    if (!selectedActingUser) {
      return;
    } else {
      setSelectedActingUser("");
    }
  };

  return (
    <Modal
      open={addOpen}
      onClose={() => handleClose()}
      actions={[
        {
          label: !activeActionItem ? "Add Action Item" : "Update Action Item",
          onClick: !activeActionItem ? handleNewActionSubmit : patchAction,
          className: "add-action",
        },
      ]}
      title={!activeActionItem ? "Create Action Item" : "Edit Action Item"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="w-full">
            <div className="w-full flex flex-col gap-4 justify-center bg-white">
              <div className="flex flex-col gap-1">
                <FormField label={"Cases"}>
                  <Autocomplete
                    className="w-full"
                    disablePortal
                    options={!clientCases ? [] : clientCases}
                    getOptionLabel={(option) => option.client.name}
                    noOptionsText="No Results"
                    sx={{
                      width: 300,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onChange={(e, value) => {
                      handleCaseSelect(e, value);
                    }}
                    value={!selectedCase ? null : selectedCase}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          id={option.client.name}
                          key={option.client.name}
                        >
                          {option.client.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={autoCompleteStyles}
                        onChange={(e) => setCaseSearch(e.target.value)}
                      />
                    )}
                  />
                </FormField>
              </div>
              {!selectedCase ? (
                ""
              ) : (
                <>
                  <Autocomplete
                    className="w-full"
                    disablePortal
                    options={!assignableUserList ? [] : assignableUserList}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="No Results"
                    sx={{
                      width: 300,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onChange={(e, value) => {
                      handleActingUserSelect(e, value);
                    }}
                    value={!selectedActingUser ? null : selectedActingUser}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} id={option.name} key={option.name}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <FormField label={"Acting User"}>
                        <TextField
                          {...params}
                          sx={autoCompleteStyles}
                          onChange={(e) => setUserSearch(e.target.value)}
                        />
                      </FormField>
                    )}
                  />
                  <FormControlLabel
                    name="on_firm"
                    label={<p>Involves the Law Firm</p>}
                    control={<Checkbox color="success" checked={lawFirm} />}
                    onChange={() => setLawFirm(!lawFirm)}
                  />
                  <FormControlLabel
                    name="on_provider"
                    label={<p>Involves a Medical Provider</p>}
                    control={
                      <Checkbox color="success" checked={toggleAddProvider} />
                    }
                    onChange={() => handleProvCheck()}
                  />
                  <div>
                    {!toggleAddProvider ? (
                      ""
                    ) : (
                      <FormField label={"Related Provider"}>
                        <Select
                          sx={selectStyles}
                          required
                          className="w-full"
                          id="related-provider"
                          value={selectedProvider}
                          onChange={(event) => {
                            setSelectedProvider(event.target.value);
                          }}
                        >
                          {providerList.map((prov, idx) => {
                            return (
                              <MenuItem
                                value={prov.provider.pk}
                                key={idx}
                                onClick={() =>
                                  setSelectedProvider(prov.provider.pk)
                                }
                              >
                                {prov.provider.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormField>
                    )}
                  </div>
                </>
              )}
              <FormField label="Due Date">
                <DesktopDatePicker
                  value={!dateTimeVal ? addDays(new Date(), 7) : dateTimeVal}
                  className="w-full mx-auto text-black flex"
                  onChange={(newValue) => setDateTimeValue(newValue)}
                  components={{
                    OpenPickerIcon: CalendarMonth,
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: "start",
                    },
                  }}
                  sx={{
                    ...inputStyles,
                    "& input" :{
                      marginLeft: "-8px"
                    }
                  }}
                />
              </FormField>

              <FormField label={"Action Detail"}>
                <TextField
                  multiline
                  sx={inputStyles}
                  placeholder="Action Detail"
                  name="Action Detail"
                  value={actionDetail}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => setActionDetail(event.target.value)}
                />
              </FormField>
            </div>
            <ResponseModal
              title={
                !activeActionItem
                  ? "Action Item Created"
                  : "Action Item Updated"
              }
              description={
                !activeActionItem
                  ? "Action Item has been succesfully created"
                  : "Action item has been succesfully updated"
              }
              openBool={openSuccess}
              setOpenBool={setOpenSuccess}
              handleCloseFunc={handleOpenSuccess}
            />
            <ResponseModal
              title={
                !activeActionItem
                  ? "Error creating Action Item"
                  : "Error updating Action Item"
              }
              isError={true}
              openBool={openError}
              setOpenBool={setOpenError}
              errorMessage={errorArray}
            />
          </div>
        </LocalizationProvider>
      </div>
    </Modal>
  );
}
