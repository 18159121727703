import { ACTION_TYPE } from "../types/actionTypes";
import FollowUpItem from "./FollowUpItem";

const FollowUpList = ({ listOfItems, label, actionType }) => {
  return (
    <div id="Follow-Up-List" data-testid="Follow-Up-List">
      {actionType !== ACTION_TYPE.PROVIDER_FOLLOW_UP && (
        <FollowUpItem
          key={"follow-up-item_" + listOfItems[actionType].id}
          id={listOfItems[actionType].id}
          clock={listOfItems[actionType].clock_state}
          label={label}
          name={listOfItems[actionType].fieldName}
          timeTillDueDate={listOfItems[actionType].relative_due_date}
          dueDate={listOfItems[actionType].due_date}
          detail={listOfItems[actionType].action_detail}
          status={listOfItems[actionType].status}
          actionType={actionType}
          completedAt={listOfItems[actionType].completedAt}
          completedBy={listOfItems[actionType].completedBy}
          updatedAt={listOfItems[actionType].updatedAt}
          updatedBy={listOfItems[actionType].updatedBy}
          client={listOfItems[actionType].client}
        />
      )}
      {actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP && (
        <div className="px-1 bg-white max-h-[10vh] overflow-y-auto [&::-webkit-scrollbar]:[width:10px]
            [&::-webkit-scrollbar-thumb]:bg-purple-600
            ">
          {Object.keys(listOfItems).map((item) => (
            <div
              className="border-y border-solid border-white mb-2"
              key={"follow-up-item_" + listOfItems[item].id}
            >
              <FollowUpItem
                id={listOfItems[item].id}
                clock={listOfItems[item].clock_state}
                label={listOfItems[item].label}
                name={listOfItems[item].fieldName}
                timeTillDueDate={listOfItems[item].relative_due_date}
                dueDate={listOfItems[item].due_date}
                detail={listOfItems[item].action_detail}
                status={listOfItems[item].status}
                actionType={actionType}
                completedAt={listOfItems[item].completedAt}
                completedBy={listOfItems[item].completedBy}
                updatedAt={listOfItems[item].updatedAt}
                updatedBy={listOfItems[item].updatedBy}
                provider={
                  listOfItems[item].provider ? listOfItems[item].provider : null
                }
                client={listOfItems[item].client}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FollowUpList;
