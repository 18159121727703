import React from "react";
import CommunicationStatus from "./CommunicationStatus";
import ClientAvailability from "./ClientAvailability";

export default function AvailabilitySection({ caseObj }) {
  return (
    <div className="flex items-center flex-col max-h-fit p-1 m-0 w-full">
      <div className="w-full">
        <CommunicationStatus caseObj={caseObj} />
        <ClientAvailability caseObj={caseObj} />
      </div>
    </div>
  );
}
