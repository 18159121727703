import React from "react";
import { Box, InputLabel } from "@mui/material";

const FormField = ({ label, children, ...props }) => {
  return (
    <Box className="w-full flex flex-col gap-1" {...props}>
      {/* Label */}
      <InputLabel className="text-[10px] text-[#505050]">{label}</InputLabel>
      {/* Render children passed from outside */}
      {children}
    </Box>
  );
};

export default FormField;
