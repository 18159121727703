import React from "react";
import { useState, useContext } from "react";
import {
  List,
  ListItem,
  Button,
  Dialog,
  TextField,
  Tooltip,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import {
  DeleteForeverOutlined,
  ControlPointOutlined,
  EditOutlined,
  Close,
  AspectRatio,
  Search,
  ExpandMore,
  CalendarMonthOutlined,
} from "@mui/icons-material";
import EditApptv2 from "./EditApptv2";
import DeleteApptv2 from "./DeleteApptv2";
import CreateAppointmentv3 from "./CreateAppointmentv3";
import ProviderApptProceduresv2 from "./ProviderApptProceduresv2";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import DataContext from "../../../../../../context/DataContext";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import ModalShell from "../../../../../global/ModalShell";

export default function ProviderApptsv2({
  providerApptsList,
  setSelectedAppt,
  selectedAppt,
  isModalVersion,
  setApptsOpen,
  apptsOpen,
  printable,
  apptResLimit,
  setApptResLimit,
  showMoreApptsVisible,
  activeProvObj,
  activeBills,
  loading,
  apptSearch,
  setApptSearch,
  caseInfo,
  proCliTrigger,
  setProCliTrigger,
}) {
  const { userType, userRoles } = useContext(DataContext);

  const [openEditAppt, setOpenEditAppt] = useState(false);
  const [openDeleteAppt, setOpenDeleteAppt] = useState(false);
  const [openAppts, setOpenAppts] = useState(false);
  const [openAddAppts, setOpenAddAppts] = useState(false);
  const [bills, setBills] = useState(false);
  const [hasBills, setHasBills] = useState();

  const { width } = useWindowDimensions();

  const handleOpenAppts = () => {
    setOpenAppts(!openAppts);
  };

  const handleAddAppointment = () => {
    setOpenAddAppts(!openAddAppts);
  };

  function handleApptDelete(appt) {
    setSelectedAppt(appt);
    setOpenDeleteAppt(!openDeleteAppt);
  }

  function handleProviderApptEdit(appt) {
    setSelectedAppt(appt);
    setOpenEditAppt(!openEditAppt);
  }

  return (
    <>
      {width < 640 ? (
        <div style={{ padding: `${printable ? "0 2rem" : "0"}` }}>
          {!providerApptsList ? (
            <div className="grid self-center">
              <h3>Loading Appointment Data...</h3>
            </div>
          ) : (
            <>
              <div
                className={`${!bills ? "block" : "hidden"} relative`}
                style={{ display: `${!bills ? "block" : "none"}` }}
              >
                <div
                  className="header-wrapper"
                  style={{
                    position: "sticky",
                    width: "100%",
                    background: "#FFF",
                  }}
                >
                  <div
                    className="px-3 flex justify-between flex-row  border-black border-b mx-3 pt-2"
                    style={{
                      padding: "10px 16px 0",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                      borderBottom: "1px solid black",
                      marginBottom: "1rem",
                    }}
                  >
                    <div></div>
                    <div className="rounded-t-md">
                      <h2
                        className="w-full text-center py-1 text-lg flex items-center"
                        style={{
                          fontSize: "24px",
                        }}
                      >
                        <span
                          className="block mr-1 mb-1"
                          style={{ marginRight: "10px" }}
                        >
                          <CalendarMonthOutlined />
                        </span>
                        Appointments
                        {printable ||
                        !userRoles.permissions.includes(
                          "provider.add_appointment"
                        ) ? (
                          ""
                        ) : (
                          <Tooltip title="Add Appointment" placement="left">
                            <Button
                              className="flex mx-auto text-green-500 hover:text-white hover:bg-green-500 overflow-x-hidden"
                              onClick={handleAddAppointment}
                            >
                              <span className="mx-4">
                                <ControlPointOutlined />
                              </span>
                            </Button>
                          </Tooltip>
                        )}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="px-3">
                  {printable ? (
                    ""
                  ) : (
                    <div className="xl:h-1/6 w-full mt-4 flex items-center pb-4">
                      <TextField
                        className="w-full mx-4 placeholder:text-center rounded-lg"
                        placeholder="Search" //{`Search ${filter}`}
                        size="small"
                        value={apptSearch}
                        style={{ marginRight: "1rem" }}
                        onChange={(event) => setApptSearch(event.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        variant="outlined"
                        size="medium"
                        color="error"
                        onClick={() => setApptSearch("")}
                        className="w-1/8 mr-4 rounded-md hover:bg-red-500 hover:text-white"
                      >
                        Reset
                      </Button>
                    </div>
                  )}
                </div>
                <>
                  {providerApptsList && (
                    <div className="mt-2 max-h-[50vh] overflow-y-auto">
                      {!providerApptsList
                        ? "Please select a Provider to see Appointments"
                        : providerApptsList.map((appt, index) => (
                            <Accordion key={index}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className="flex items-center justify-between">
                                  <div>
                                    {appt.provider.name} -{" "}
                                    <span className="font-bold">
                                      {new Date(appt.scheduled).toLocaleString(
                                        "en-us",
                                        {
                                          dateStyle: "medium",
                                          timeStyle: "short",
                                        }
                                      )}
                                    </span>
                                    &nbsp;
                                    {!hasBills ? (
                                      ""
                                    ) : !hasBills[appt.pk] ? (
                                      ""
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9333EA",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        $
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails className="flex">
                                <div className="p-2 m-1 flex flex-col border-purple-600 border rounded-md w-full text-center">
                                  <p>
                                    <span className="font-bold">Time:</span>{" "}
                                    {new Date(appt.scheduled).toLocaleString(
                                      "en-us",
                                      {
                                        dateStyle: "medium",
                                        timeStyle: "short",
                                      }
                                    )}
                                  </p>
                                  <p>
                                    <span className="font-bold">Status:</span>{" "}
                                    {appt.status === "Overdue"
                                      ? "Missed"
                                      : appt.status}{" "}
                                  </p>
                                  <div>
                                    {userRoles.permissions.includes(
                                      "provider.change_appointment"
                                    ) ? (
                                      <Tooltip
                                        title="Edit Appointment"
                                        placement="bottom"
                                      >
                                        <Button
                                          className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                                          style={{ color: "#9333ea" }}
                                          onClick={(e) =>
                                            handleProviderApptEdit(appt)
                                          }
                                        >
                                          <span>
                                            <EditOutlined />
                                          </span>
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                    {userRoles.permissions.includes(
                                      "provider.delete_appointment"
                                    ) ? (
                                      <Tooltip
                                        title="Delete Appointment"
                                        placement="bottom"
                                      >
                                        <Button
                                          onClick={(e) =>
                                            handleApptDelete(appt)
                                          }
                                          className="rounded-md hover:text-white text-red-300 hover:bg-red-300"
                                          style={{ color: "#D32F2F" }}
                                        >
                                          {/* <span style={{margin: '0 .5rem'}}>Delete</span> */}
                                          <span>
                                            <DeleteForeverOutlined />
                                          </span>
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="p-2 m-1 flex flex-col border-purple-600 border rounded-md w-full text-center">
                                    <ProviderApptProceduresv2
                                      selectedAppt={appt}
                                      setBills={setBills}
                                      bills={bills}
                                      isModalVersion={isModalVersion}
                                      printable={printable}
                                      hasBills={hasBills}
                                      setHasBills={setHasBills}
                                      activeProvObj={activeProvObj}
                                      activeBills={activeBills}
                                      apptResLimit={apptResLimit}
                                      caseInfo={caseInfo}
                                      proCliTrigger={proCliTrigger}
                                      setProCliTrigger={setProCliTrigger}
                                    />
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      <div>
                        <ShowMoreButton
                          showMoreVisible={showMoreApptsVisible}
                          setResLimit={setApptResLimit}
                          resLimit={apptResLimit}
                          showMoreAmt={5}
                        />
                      </div>
                    </div>
                  )}
                </>
              </div>
            </>
          )}
        </div>
      ) : (
        <div style={{ padding: `${printable ? "0 2rem" : "0"}` }}>
          {!providerApptsList ? (
            <div className="grid self-center">
              <h3>Loading Appointment Data...</h3>
            </div>
          ) : (
            <div
              className={`${
                !bills ? "block" : "hidden"
              } h-[60vh] overflow-y-hidden relative `}
              style={{ display: `${!bills ? "block" : "none"}` }}
            >
              <div
                className="header-wrapper"
                style={{
                  position: "sticky",
                  width: "100%",
                  background: "#FFF",
                }}
              >
                {apptsOpen ? (
                  <div
                    className="px-3 flex justify-between flex-row-reverse border-black border-b mx-3 pt-2"
                    style={{
                      padding: "10px 16px 0",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                      borderBottom: "1px solid black",
                      marginBottom: "1rem",
                    }}
                  >
                    <div className="self-end rounded-t-md">
                      <h2
                        className="w-full text-center py-1 text-lg flex items-center"
                        style={{
                          fontSize: "24px",
                        }}
                      >
                        <span
                          className="block mr-1 mb-1"
                          style={{ marginRight: "10px" }}
                        >
                          <CalendarMonthOutlined />
                        </span>
                        Appointments
                      </h2>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="px-3">
                  {printable ? (
                    ""
                  ) : (
                    <div className="flex justify-between w-full">
                      <div className="xl:h-1/6 w-1/2 mt-4 flex items-center">
                        <TextField
                          className="w-full mx-4 placeholder:text-center rounded-lg"
                          placeholder="Search" //{`Search ${filter}`}
                          size="small"
                          value={apptSearch}
                          style={{ marginRight: "1rem" }}
                          onChange={(event) =>
                            setApptSearch(event.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          variant="outlined"
                          size="medium"
                          color="error"
                          onClick={() => setApptSearch("")}
                          className="w-1/8 mr-4 rounded-md hover:bg-red-500 hover:text-white"
                        >
                          Reset
                        </Button>
                      </div>
                      {printable ||
                      !userRoles.permissions.includes(
                        "provider.add_appointment"
                      ) ? (
                        ""
                      ) : (
                        <div className="flex items-center">
                          <Tooltip title="Add Appointment" placement="left">
                            <Button
                              className="self-center text-green-500 hover:text-white hover:bg-green-500"
                              style={{ color: "#32CD30" }}
                              onClick={handleAddAppointment}
                            >
                              <span className="mx-4">
                                <ControlPointOutlined />
                              </span>
                            </Button>
                          </Tooltip>
                          {apptsOpen ? (
                            <div></div>
                          ) : printable ? (
                            <div></div>
                          ) : width < 640 ? (
                            <div></div>
                          ) : (
                            <Tooltip
                              title="Expand Appointments List"
                              placement="left"
                            >
                              <Button
                                onClick={() => setApptsOpen(true)}
                                className="text-sky-600 hover:bg-sky-600 hover:text-white self-center"
                              >
                                <span className="mx-4">
                                  <AspectRatio />
                                </span>
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* render out list of appts here */}
              <div className="w-full p-0 mb-2 overflow-y-auto max-h-[45vh] ">
                {providerApptsList && (
                  <div className="mt-2 pb-2">
                    {loading ? (
                      <div className="w-fit mx-auto">
                        <CircularProgress color="secondary" />
                      </div>
                    ) : !providerApptsList ? (
                      ""
                    ) : (
                      providerApptsList.map((appt, index) => (
                        <Accordion
                          key={index}
                          defaultExpanded={printable ? true : false}
                        >
                          <AccordionSummary
                            expandIcon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background:
                                      appt.status === "Done"
                                        ? "#86CFAC"
                                        : appt.status === "Overdue"
                                        ? "#FCA5A5"
                                        : "#7DD3FC",
                                  }}
                                ></div>
                                <ExpandMore />
                              </div>
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              {appt.provider.name} | {appt.provider.type}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                textAlign: "right",
                                margin: "0 8px 0 0",
                                fontWeight: "bold",
                              }}
                            >
                              {appt.scheduled
                                ? new Date(appt.scheduled).toLocaleString(
                                    "en-us",
                                    {
                                      dateStyle: "short",
                                      timeStyle: "short",
                                    }
                                  )
                                : ""}
                              &nbsp;
                              {!hasBills ? (
                                ""
                              ) : !hasBills[appt.pk] ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    color: "#9333EA",
                                    fontWeight: "bold",
                                  }}
                                >
                                  $
                                </span>
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List
                              key={index}
                              className="p-0 w-full"
                              style={{
                                borderTop: `${
                                  isModalVersion === true
                                    ? "1px solid #e5e7eb"
                                    : "none"
                                }`,
                              }}
                            >
                              <div
                                className={`my-2 p-0 w-full grid grid-cols-2  rounded-lg`}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: printable
                                    ? "repeat(2, minmax(0, 1fr))"
                                    : "repeat(2, minmax(0, 1fr))",
                                  overflowX: "hidden",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <ListItem
                                  style={{
                                    display: "block",
                                    borderRight: "1px solid #e5e7eb",
                                    padding: "8px 16px",
                                    height: "100%",
                                    textAlign: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Time:&nbsp;
                                    </span>
                                    {appt.scheduled
                                      ? new Date(
                                          appt.scheduled
                                        ).toLocaleTimeString()
                                      : ""}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Status:&nbsp;
                                    </span>
                                    {appt.status === "Overdue"
                                      ? "Missed"
                                      : appt.status}
                                  </div>
                                </ListItem>
                                <ListItem
                                  className="justify-center place-content-center overflow-x-hidden text-center "
                                  style={{
                                    padding: "8px 16px",
                                    height: "100%",
                                    textAlign: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {printable ||
                                  (userType === "Provider" && !activeProvObj) ||
                                  userType === "unknown" ||
                                  (userType === "Provider" &&
                                    appt.provider.pk !==
                                      activeProvObj[appt.provider.pk]) ? (
                                    ""
                                  ) : (
                                    <div className="justify-evenly place-content-center text-center flex  w-fit">
                                      {userRoles.permissions.includes(
                                        "provider.change_appointment"
                                      ) ? (
                                        <Tooltip
                                          title="Edit Appointment"
                                          placement="bottom"
                                        >
                                          <Button
                                            className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                                            style={{ color: "#9333ea" }}
                                            onClick={(e) =>
                                              handleProviderApptEdit(appt)
                                            }
                                          >
                                            <span>
                                              <EditOutlined />
                                            </span>
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                      {userRoles.permissions.includes(
                                        "provider.change_appointment"
                                      ) ? (
                                        <Tooltip
                                          title="Delete Appointment"
                                          placement="bottom"
                                        >
                                          <Button
                                            onClick={(e) =>
                                              handleApptDelete(appt)
                                            }
                                            className="rounded-md hover:text-white text-red-300 hover:bg-red-300"
                                            style={{ color: "#D32F2F" }}
                                          >
                                            <span>
                                              <DeleteForeverOutlined />
                                            </span>
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </ListItem>
                              </div>
                            </List>

                            <Accordion
                              style={{ margin: "1rem" }}
                              disabled={!appt.notes ? true : false}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                {!appt.notes
                                  ? "No Notes - Edit appointment info to add"
                                  : "Appointment Notes"}
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="bg-white">
                                  <h3
                                    className="notes"
                                    dangerouslySetInnerHTML={{
                                      __html: appt.notes,
                                    }}
                                  ></h3>
                                </div>
                              </AccordionDetails>
                            </Accordion>

                            <div className="p-2 m-1 flex flex-col border-purple-600 border rounded-md w-full text-center">
                              {userRoles.permissions.includes(
                                "provider.view_administeredservices"
                              ) ? (
                                <ProviderApptProceduresv2
                                  selectedAppt={appt}
                                  setBills={setBills}
                                  bills={bills}
                                  isModalVersion={isModalVersion}
                                  printable={printable}
                                  hasBills={hasBills}
                                  setHasBills={setHasBills}
                                  activeProvObj={activeProvObj}
                                  activeBills={activeBills}
                                  apptResLimit={apptResLimit}
                                  caseInfo={caseInfo}
                                  proCliTrigger={proCliTrigger}
                                  setProCliTrigger={setProCliTrigger}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    )}
                    <div>
                      <ShowMoreButton
                        showMoreVisible={showMoreApptsVisible}
                        setResLimit={setApptResLimit}
                        resLimit={apptResLimit}
                        showMoreAmt={5}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* end */}
        </div>
      )}
      <CreateAppointmentv3
        open={openAddAppts}
        onClose={() => setOpenAddAppts(false)}
        title={"Create Appointment"}
        setOpenAddAppts={setOpenAddAppts}
        activeProvObj={activeProvObj}
        caseInfo={caseInfo}
      />

      <ModalShell
        open={openEditAppt}
        setOpen={setOpenEditAppt}
        color={"rgb(234 88 12)"}
        title={"Edit Appointment Details"}
      >
        <EditApptv2
          selectedAppt={selectedAppt}
          openEditAppt={openEditAppt}
          setOpenEditAppt={setOpenEditAppt}
          proCliTrigger={proCliTrigger}
          setProCliTrigger={setProCliTrigger}
        />
      </ModalShell>

      <ModalShell
        open={openDeleteAppt}
        setOpen={setOpenDeleteAppt}
        color={"rgb(220 38 38)"}
        title={"Delete Appointment"}
      >
        <DeleteApptv2
          selectedAppt={selectedAppt}
          openDeleteAppt={openDeleteAppt}
          setOpenDeleteAppt={setOpenDeleteAppt}
          proCliTrigger={proCliTrigger}
          setProCliTrigger={setProCliTrigger}
        />
      </ModalShell>
      <Dialog
        open={openAppts}
        maxWidth={"lg"}
        fullScreen={width < 640 ? true : false}
      >
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={handleOpenAppts}>
              <Close />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              // color: "orange",
              margin: "0 0 1rem 0",
              textAlign: "center",
            }}
          >
            Bills / Procedures
          </h1>
          <br />
          <br />
          {userRoles.permissions.includes(
            "provider.view_administeredservices"
          ) ? (
            <ProviderApptProceduresv2
              selectedAppt={selectedAppt}
              setBills={setBills}
              bills={bills}
              activeProvObj={activeProvObj}
              activeBills={activeBills}
              proCliTrigger={proCliTrigger}
              setProCliTrigger={setProCliTrigger}
              caseInfo={caseInfo}
            />
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </>
  );
}
