import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import InlineEditRow from "../components/InlineEditRow";
import axios from "../../../../../../api/axios";
import CaseSummaryContext from "../../CaseSummaryV2/context/CaseSummaryContext";

export default function BasicInfoSection({
  caseObj,
  viewPermissions,
  displayUrl,
}) {
  const { accessToken } = useContext(DataContext);
  const { caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const [userValueLabels, setUserValueLabels] = useState("");
  const [languageLabels, setLanguageLabels] = useState("");
  const [userData, setUserData] = useState("");
  const params = useParams();

  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      axios
        .get(`/api/users/${caseObj.client.pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setUserData(data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [
    setUserData,
    accessToken,
    displayUrl,
    params.id,
    caseObj,
    caseSummaryTrigger,
  ]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/user/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setUserValueLabels(data);
      })
      .catch((err) => {
        return err;
      });

    axios
      .get(`/api/utilities/language/?ordering=created_at`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        let obj = {};

        for (const item in data) {
          obj[data[item].pk] = data[item];
        }
        setLanguageLabels(obj);
      })
      .catch((err) => {
        return err;
      });
  }, [setLanguageLabels, accessToken]);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const inputs = [
    {
      group: [
        {
          keyVal: "name_prefix",
          type: "select",
          title: "Prefix",
          optionsUrl:
            "/api/field_options/?content_type=user&field_name=name_prefix&ordering=order&",
          nullable: true,
        },
        {
          keyVal: "gender",
          type: "select",
          title: "Gender",
          optionsUrl:
            "/api/field_options/?content_type=user&field_name=gender&ordering=order&",
          nullable: true,
        },
      ],
    },
    {
      group: [
        {
          keyVal: "name",
          type: "text",
          title: "Client Name",
        },
        {
          keyVal: "marital_status",
          type: "select",
          title: "Marital Status",
          optionsUrl:
            "/api/field_options/?content_type=user&field_name=marital_status&ordering=order&",
          nullable: true,
        },
      ],
    },
    {
      group: [
        {
          keyVal: "date_of_birth",
          type: "date",
          title: "Date of Birth",
          group: 2,
        },
        {
          keyVal: "age",
          type: "text",
          title: "Client Age",
          group: 2,
          disabled: true,
          staticVal: userData ? getAge(userData.date_of_birth) : "",
        },
      ],
    },
    {
      group: [
        {
          keyVal: "preferred_language",
          type: "search-select",
          title: "Preferred Language",
          optionsUrl: "/api/utilities/language/?ordering=created_at&limit=1000",
          customLimit: "25",
          postKey: "pk",
          displayNameKey: "description",
          customValueLabels: languageLabels,
        },
        {
          keyVal: "spoken_language",
          type: "multi-search-select",
          title: "Spoken Languages",
          optionsUrl: "/api/utilities/language/?ordering=created_at&limit=1000",
          customLimit: "25",
          postKey: "pk",
          displayNameKey: "description",
          customValueLabels: languageLabels,
        },
      ],
    },
  ];
  return !caseObj ? (
    <h2 className="text-center underline my-2 text-gray-500">
      Content details not available for this account at this time
    </h2>
  ) : (
    <div className="pr-2 pl-4 py-4 w-full">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <div className="w-full">
          {" "}
          {inputs.map((key, idx) =>
            key.group ? (
              <div className="flex items-baseline w-full">
                {key.group.map((subKey, idx) => (
                  <div
                    className={
                      key.group.length <= 1
                        ? "w-full"
                        : `w-1/${key.group.length} my-2 mx-4`
                    }
                  >
                    {" "}
                    <InlineEditRow
                      key={idx}
                      title={subKey.title}
                      type={subKey.type}
                      keyVal={subKey.keyVal}
                      displayNameKey={subKey.displayNameKey}
                      caseDetail={userData}
                      apiUrl={`/api/users/${caseObj.client.pk}/`}
                      customDisplayUrl={subKey.customValueLabels ? true : false}
                      optionsUrl={subKey.optionsUrl}
                      customLimit={subKey.customLimit}
                      postKeyVal={subKey.postKey}
                      trigger={caseSummaryTrigger}
                      setTrigger={setCaseSummaryTrigger}
                      optionsDisplayKey={subKey.optionsDisplayKey}
                      valueHistory={null}
                      displayDetail={userData}
                      valueLabels={
                        subKey.customValueLabels
                          ? subKey.customValueLabels
                          : userValueLabels
                      }
                      disabled={subKey.disabled ? true : false}
                      nullable={subKey.nullable}
                      staticVal={subKey.staticVal}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <InlineEditRow
                key={idx}
                title={key.title}
                type={key.type}
                keyVal={key.keyVal}
                displayNameKey={key.displayNameKey}
                caseDetail={userData}
                apiUrl={`/api/users/${caseObj.client.pk}/`}
                customDisplayUrl={key.customValueLabels ? true : false}
                optionsUrl={key.optionsUrl}
                customLimit={key.customLimit}
                postKeyVal={key.postKey}
                trigger={caseSummaryTrigger}
                setTrigger={setCaseSummaryTrigger}
                optionsDisplayKey={key.optionsDisplayKey}
                valueHistory={null}
                displayDetail={userData}
                valueLabels={
                  key.customValueLabels
                    ? key.customValueLabels
                    : userValueLabels
                }
                disabled={key.disabled ? true : false}
                nullable={key.nullable}
                staticVal={key.staticVal}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}
