export const ButtonsConfig = {
  SubmitButtons: {
    create: {
      displayText: "Create",
      submitType: "Create-FollowUp",
      permissions:["casemanager.can_add_follow_ups", "casemanager.add_actionableitems"]
    },
    update: {
      displayText: "Update",
      submitType: "Update-FollowUp",
      permissions:["casemanager.can_add_follow_ups", "casemanager.change_actionableitems"]
    },
    markComplete: {
      displayText: "Mark Complete",
      submitType: "Complete-FollowUp",
      permissions:["casemanager.can_add_follow_ups", "casemanager.change_actionableitems"]
    },
  },
};
