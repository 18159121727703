export const emptyField = {
  required: false,
  value: "",
  displayValue: "",
  errorText: "",
  isError: false,
  helperText: "",
  isHelperTextVisible: false,
  isValid: true,
  isTouched: true,
};
