import { CircularProgress, ListItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  AddCircleOutline,
  ArrowForward,
  Delete,
  Edit,
  Help,
  OpenInNew,
  SettingsApplications,
} from "@mui/icons-material";
import LogListRow from "./LogListRow";
import TooltipIconButton from "../../../../../global/TooltipIconButton";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import LogAccordionShell from "./LogAccordionShell";

export default function LogItem({
  log,
  actionGroupLabels,
  actionLabels,
  crudLabels,
}) {
  const { accessToken } = useContext(DataContext);
  const navigate = useNavigate();
  const [logData, setLogData] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expanded, setExpanded] = useState(false);

  const navigateToCase = (casePk) => {
    navigate(`/client-detail/${casePk}`);
  };
  const ActionIcon = ({ action }) => {
    let DisplayIcon;
    if (action === 1) {
      DisplayIcon = (
        <Tooltip
          title={`${
            !crudLabels ? "App Operation Unknown" : crudLabels[action]
          }`}
        >
          <AddCircleOutline className="text-green-600" />
        </Tooltip>
      );
    } else if (action === 2) {
      DisplayIcon = (
        <Tooltip
          title={`${
            !crudLabels ? "App Operation Unknown" : crudLabels[action]
          }`}
        >
          <Edit className="text-orange-500" />
        </Tooltip>
      );
    } else if (action === 3) {
      DisplayIcon = (
        <Tooltip
          title={`${
            !crudLabels ? "App Operation Unknown" : crudLabels[action]
          }`}
        >
          <SettingsApplications className="text-sky-600" />
        </Tooltip>
      );
    } else if (action === 4) {
      DisplayIcon = (
        <Tooltip
          title={`${
            !crudLabels ? "App Operation Unknown" : crudLabels[action]
          }`}
        >
          <Delete className="text-red-600" />
        </Tooltip>
      );
    } else {
      DisplayIcon = (
        <Tooltip
          title={`${
            !crudLabels ? "App Operation Unknown" : crudLabels[action]
          }`}
        >
          <Help />
        </Tooltip>
      );
    }
    return DisplayIcon;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setLoading(true);
      axios
        .get(`/api/logs/${log.id}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setLogData(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
          return error;
        });
    } else {
      setLogData("");
      setLoading(false);
    }
  };

  const disableAccordion = false;

  //? I am leaving the disabled accordion set up in the OFF chance we want to disable the ability for say, a certain log type or something of having an accordion/being able to access sub data.

  return !log ? (
    <ListItem className="rounded-md shadow-md">No Data Found</ListItem>
  ) : disableAccordion ? (
    <div className="flex justify-between items-center">
      <ListItem className="rounded-sm shadow-sm mx-4 w-[92%]">
        <LogListRow
          log={log}
          ActionIcon={ActionIcon}
          actionGroupLabels={actionGroupLabels}
          actionLabels={actionLabels}
        />
      </ListItem>
      <div className={`w-[5%]`}>
        {log.related_case ? (
          <TooltipIconButton
            Icon={<OpenInNew />}
            toolTipTitle="Open Case Detail"
            color="sky"
            actionFunc={() => navigateToCase(log.related_case.id)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <ListItem>
      <LogAccordionShell
        title={
          <LogListRow
            log={log}
            ActionIcon={ActionIcon}
            actionGroupLabels={actionGroupLabels}
            actionLabels={actionLabels}
          />
        }
        customTitle={log.id}
        onOpen={() => handleChange(log.id)}
        isExpanded={expanded}
        setIsExpanded={setExpanded}
      >
        {loading ? (
          <CircularProgress color="secondary" />
        ) : errorMessage ? (
          errorMessage
        ) : logData ? (
          <div className="px-4 flex items-center justify-start w-[100%]">
            <div className="font-bold">
              {crudLabels && logData.crud_type
                ? crudLabels[logData.crud_type]
                : "Unknown"}{" "}
              | {logData.action_label ? logData.action_label : "Unknown"}
            </div>
            &nbsp;
            <div className="mx-4">
              {!logData.old_value ? "No Data" : logData.old_value}{" "}
              <ArrowForward className="text-sky-600" />{" "}
              {!logData.new_value ? "No Data" : logData.new_value}
            </div>
          </div>
        ) : (
          errorMessage
        )}
      </LogAccordionShell>
      <div className={`w-[5%]`}>
        {log.related_case ? (
          <TooltipIconButton
            Icon={<OpenInNew />}
            toolTipTitle="Open Case Detail"
            color="sky"
            actionFunc={() => navigateToCase(log.related_case.id)}
          />
        ) : (
          ""
        )}
      </div>
    </ListItem>
  );
}
