import { useContext } from "react";
import Clock from "../../../../../CaseFollowUps/components/ui/Clock/Clock";
import {
  CONTENT_EDIT_FOLLOWUP,
  setSelectedFollowUp,
  showContent,
  toggleDialog,
} from "../../../../../CaseFollowUps/state/actions";
import { getColor } from "../../../../../CaseFollowUps/components/utils/getColor";
import { FollowUpDispatchContext } from "../../../../../CaseFollowUps/context/FollowUpContext";
import DateModel from "../../../../../CaseFollowUps/components/fields/DateModel";
import NameModel from "../../../../../CaseFollowUps/components/fields/NameModel";
import { CONTENT_VIEW_FOLLOWUP } from "../state/actions";
import { STATUS_TYPES } from "../../../../../CaseFollowUps/components/types/statusTypes";
import { ACTION_TYPE, ACTIONABLE_ITEM_TYPE_STRING } from "../../../../../CaseFollowUps/components/types/actionTypes";
import STRINGS from "../../../../../CaseFollowUps/components/utils/strings";
import { tooltipData } from "../../../../../CaseFollowUps/components/utils/tooltipData";
import { Button } from "@mui/material";

const FollowUpListItem = ({
  id,
  clock,
  label,
  timeTillDueDate,
  dueDate,
  detail,
  provider,
  actionType,
  status,
  completedAt,
  completedBy,
  updatedAt,
  updatedBy,
  client
}) => {
  const dispatch = useContext(FollowUpDispatchContext);
  const bgShade = 100;
  const defaultShade = 500;

  const getMainColor = (value) => {
    return getColor(value, defaultShade);
  };
  const getBgColor = (value) => {
    return getColor(value, bgShade) + '50';
  };
  const showDialog = () => {
    let dialogContent = CONTENT_VIEW_FOLLOWUP;

    if (status === STATUS_TYPES.PENDING) {
      dialogContent = CONTENT_EDIT_FOLLOWUP;
    }
    showContent(dispatch, dialogContent);
    toggleDialog(dispatch, true);

    setSelectedFollowUp(dispatch, {
      id: id,
      clock: clock,
      label: label,
      timeTillDueDate: timeTillDueDate,
      status: status,
      actionType: actionType,
      completedBy: completedBy,
      completedAt: completedAt,
      updatedBy: updatedBy,
      updatedAt: updatedAt,
      client:client,
      fields: {
        dueDate: dueDate,
        detail: detail,
        ...(provider
          ? { provider: provider }
          : {}),
      },
    });
  };

  return (
    <Button
      className="followup-item text-slate-900 capitalize w-full grid grid-cols-10 gap-0 py-2 border-t-[2px] border-solid border-slate-500  rounded-md bg-slate-100 rounded-md opacity-100 hover:opacity-80"
      style={{
        borderTop: clock
          ? "2px solid " + getMainColor(clock)
          : "2px solid bg-slate-500",
        backgroundColor: clock ? getBgColor(clock) : "bg-slate-100",
      }}
      onClick={() => showDialog()}
    >
      <div className="col-span-1 text-sm text-center">
        {clock && (
          <Clock
            clockValue={clock}
            tooltipTitle={null}
            tooltipDesc={null}
            tooltipObj={tooltipData.clock_state_tooltip}
            size={1}
          />
        )}
      </div>
      <div className="col-span-2 text-sm text-center">
        {dueDate && DateModel(dueDate)}
      </div>
      <div className="col-span-2 text-sm text-center">
        {ACTIONABLE_ITEM_TYPE_STRING[actionType]}
      </div>
      <div className="col-span-2 text-sm text-center">
        {completedAt ? DateModel(completedAt) : STRINGS.NA}
      </div>
      <div className="col-span-3 text-sm text-center">
        {completedBy ? NameModel(completedBy.name) : STRINGS.NA}
      </div>
    </Button>
  );
};

export default FollowUpListItem;
