import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import PrintableClientList from "../../../global/PrintableClientList";
import ResponseModal from "../../../global/ResponseModal";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";

export default function LawfirmSortByReport({ isDashboardVersion }) {
  const { accessToken, trigger, setTrigger, userRoles } =
    useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [policeReport] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const disableButtons = userRoles.permissions.includes(
    "casemanager.change_case"
  )
    ? false
    : true;

  const format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const navigateToDetail = (client) => {
    navigate(`/client-detail/${client.pk}`);
  };

  const sendRequest = () => {
    setLoading(true);
    axios
      .get(
        `/api/case/?${qs.stringify(
          {
            police_report_received: false,
            archived: false,
            lost__isnull: true,
            settled: false,
            search: !search ? null : search,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [policeReport, search, paginationUrl, setPaginationUrl, trigger],
    250
  );

  const handleProgressCheck = (e, casePk) => {
    const statusVal = {
      [e.target.name]: e.target.checked,
    };

    const timer = setTimeout(() => {
      axios
        .patch(`/api/case/${casePk}/`, statusVal, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        })
        .then(function () {
          setTrigger(!trigger);
          setOpenSuccess(true);
        })
        .catch(function (error) {
          if (error.response) {
            let res = error.response.data;
            let errArr = [];
            for (const item in res) {
              errArr.push(item + `: ${res[item]}`);
            }
            setErrorMessage(errArr);
          }
          setOpenError(!openError);
        });
    }, "1000");
    return () => clearTimeout(timer);
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const componentRef = useRef();

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={` px-4 ${
          isDashboardVersion
            ? ""
            : "h-full overflow-hidden"
        } flex`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className={`w-full md:ml-4`}>
          {isDashboardVersion ? (
            ""
          ) : (
            <h1
              className={`text-xl text-purple-600 text-center my-4 font-bold`}
            >
              Needs Police Report
            </h1>
          )}
          <div className="flex justify-center mt-4">
            <Tooltip title="Search by Name">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Tooltip>
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="pl-5 py-4">Results: {responseData.length}</p>
            </div>{" "}
            <div className="flex md:px-4 px-2 text-center py-2 border-b border-1 border-gray">
              <div className="md:w-1/5 w-1/3 font-bold">
                <h2>CLIENT NAME</h2>
              </div>
              <div className="w-1/5 md:block hidden font-bold">
                <h2>CASE NAME</h2>
              </div>
              <div className="w-1/5 md:block hidden font-bold">
                <h2>DATE CREATED</h2>
              </div>
              <div className="md:w-1/5 w-1/3 font-bold">
                <h2>STATUS</h2>
              </div>
              <div className="md:w-1/5 w-1/3 font-bold">
                {" "}
                <h2>QUICK UPDATE</h2>
              </div>
            </div>
            <List
              className={`grid ${
                isDashboardVersion ? `max-h-[65vh]` : "max-h-[100%]"
              } overflow-y-auto`}
            >
              {loading === "true" ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {responseData.map((client, index) => (
                    <ListItem
                      key={index}
                      value={client}
                      className="py-0 pt-0 text-sm"
                    >
                      <div
                        className={
                          "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y justify-between"
                        }
                      >
                        <Button
                          onClick={() => navigateToDetail(client)}
                          className="hover:bg-purple-600 hover:text-white md:w-1/5 w-1/3 justify-start "
                        >
                          <span
                            className={`block text-left pl-2 text-sm ${resultClasses}`}
                          >
                            {currentPage > 1
                              ? index + 1 * ((currentPage - 1) * 25) + 1
                              : index + 1}
                            .&nbsp;
                            {!client ? "" : client.client.name}
                          </span>
                        </Button>
                        <span
                          className={`block w-1/5 md:block hidden text-left pl-2 text-center ${resultClasses}`}
                        >
                          <Tooltip title={`${!client ? "" : client.name}`}>
                            {!client
                              ? ""
                              : isDashboardVersion
                              ? truncate(client.name, 5, 15)
                              : client.name}{" "}
                          </Tooltip>
                        </span>
                        <span
                          className={`block w-1/5 md:block hidden text-left pl-2 text-center  ${resultClasses}`}
                        >
                          {!client.created_at
                            ? ""
                            : new Date(client.created_at)
                                .toLocaleString("en-us", format)
                                .split(",")[0]}
                        </span>
                        <span
                          className={`block md:w-1/5 w-1/3 text-left pl-2 text-center ${resultClasses}`}
                        >
                          {!client ? "" : client.status}
                        </span>
                        <div
                          className={`block md:w-1/5 w-1/3 text-left pl-2 text-center ${resultClasses} border-none`}
                        >
                          <FormControlLabel
                            name="police_report_received"
                            disabled={disableButtons}
                            control={
                              <Tooltip title="Set Documents Recieved">
                                <Checkbox
                                  color="success"
                                  checked={policeReport}
                                />
                              </Tooltip>
                            }
                            onChange={(e) => handleProgressCheck(e, client.pk)}
                          />
                        </div>
                      </div>
                      {/* </Tooltip> */}
                    </ListItem>
                  ))}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
        {/* PRINTABLE SECTION */}
        <PrintableClientList
          responseData={responseData}
          componentRef={componentRef}
          loading={loading}
          format={format}
          title="Clients Missing Police Report"
        />

        <ResponseModal
          title="Police Report Status Updated"
          description="Successfully updated client Police Report Status"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
        />
        <ResponseModal
          title="Error Updating Police Report Status"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
