import React, { useContext } from "react";
import Flag from "../Flag";
import Clock from "../../../../../../../../global/Clock";
import "./BaseField.css";
import { StaticBarStateContext } from "../../../context/StaticBarContext";
import LabelText from "../LabelText";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "../../utils/TooltipStyling";

export default function BaseField({
  fieldName,
  id,
  testId,
  label,
  flag,
  clock,
  customFontSize,
  children,
}) {
  const state = useContext(StaticBarStateContext);
  const generateCustomLineHeight = (value) => {
    let reg = /\d+/;
    let origLineHeight = value.match(reg);
    let newLineHeight = parseInt(origLineHeight[0]) + 3;
    return newLineHeight + "px";
  };
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <div className="w-full">
      {state?.ui[fieldName] && state?.ui[fieldName].isLoading ? (
        <div className="loader"></div>
      ) : (
        <HtmlInfoTooltip
          title={
            state?.ui?.[fieldName]?.tooltips?.title ||
            (state?.ui?.[fieldName]?.tooltips?.description && (
              <>
                {state?.ui?.[fieldName]?.tooltips?.title && (
                  <div>{state?.ui?.[fieldName]?.tooltips?.title}</div>
                )}
                {state?.ui?.[fieldName]?.tooltips?.description && (
                  <div>
                    <hr />
                    {state?.ui?.[fieldName]?.tooltips?.description}
                  </div>
                )}
              </>
            ))
          }
        >
          <div id={id} data-testid={testId} className="text-base-[6px] mb-2">
            <div className="flex">
              <div className="w-[95%] p-0 m-0">
                {label && <LabelText label={label} />}
              </div>
              <div className="w-[5%] p-0 m-0">
                {flag && (
                  <Flag
                    fieldName={fieldName}
                    flagValue={flag}
                    tooltipTitle={
                      state?.ui?.[fieldName]?.tooltips?.flag_state_tooltip_title
                    }
                    tooltipDesc={
                      state?.ui?.[fieldName]?.tooltips
                        ?.flag_state_tooltip_description
                    }
                    tooltipObj={
                      state?.ui?.[fieldName]?.tooltips?.flag_state_tooltip
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex">
              <div
                className="text-[16px]"
                style={{
                  fontSize: customFontSize ? customFontSize : "16px",
                  lineHeight: customFontSize
                    ? generateCustomLineHeight(customFontSize)
                    : "18px",
                }}
              >
                {children}
              </div>
              {clock && (
                <Clock
                  fieldName={fieldName}
                  clockValue={clock}
                  tooltipTitle={
                    state?.ui?.[fieldName]?.tooltips?.clock_state_tooltip_title
                  }
                  tooltipDesc={
                    state?.ui?.[fieldName]?.tooltips
                      ?.clock_state_tooltip_description
                  }
                  tooltipObj={
                    state?.ui?.[fieldName]?.tooltips?.clock_state_tooltip
                  }
                />
              )}
            </div>
          </div>
        </HtmlInfoTooltip>
      )}
    </div>
  );
}
