import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import InlineEditRow from "../../components/InlineEditRow";
import CaseSummaryContext from "../../../CaseSummaryV2/context/CaseSummaryContext";

export default function CommunicationStatus({ caseObj }) {
  const { accessToken } = useContext(DataContext);
  const [userValueLabels, setUserValueLabels] = useState({});
  const [userData, setUserData] = useState("");
  const { caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const params = useParams();

  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      axios
        .get(`/api/users/${caseObj.client.pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setUserData(data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [setUserData, accessToken, params.id, caseObj, caseSummaryTrigger]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/user/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setUserValueLabels(data);
      })
      .catch((err) => {
        return err;
      });
  }, [setUserValueLabels, accessToken]);

  return !caseObj ? (
    <h2 className="text-center underline my-2 text-gray-500">
      Content details not available for this account at this time
    </h2>
  ) : (
    <div className="w-full mb-5">
      <InlineEditRow
        title="Communication Status"
        type="search-select"
        keyVal="comm_status"
        apiUrl={`/api/users/${caseObj.client.pk}/`}
        postKey={"key"}
        postKeyVal={"key"}
        optionsUrl="/api/field_options/?content_type=user&field_name=comm_status&ordering=order"
        displayNameKey={"label"}
        customLimit={20}
        trigger={caseSummaryTrigger}
        setTrigger={setCaseSummaryTrigger}
        displayDetail={userData}
        valueLabels={userValueLabels}
        disabled={false}
        nullable={false}
      />
    </div>
  );
}
