import validateField from "../utils/validateField";
import {
  SET_FIELD_DATA,
  SET_FORM_IS_INITIALIZED,
  SET_FORM_IS_TOUCHED,
  SET_FORM_IS_VALID,
} from "./actions";

export const initialState = {
  
  fields: null,
};

export function followUpFormReducer(state, action) {
  switch (action.type) {
    case SET_FIELD_DATA: {
      let checkIsValid = validateField(state.fields[action.payload.fieldName].required, action.payload.data);
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.fieldName]: {
            ...state.fields[action.payload.fieldName],
            isTouched: true,
            value: action.payload.data,
            isValid: checkIsValid,
            isError: !checkIsValid ? true : false,
          },
        },
      };
    }
    case SET_FORM_IS_TOUCHED: {
      return { ...state, isTouched: action.payload };
    }
    case SET_FORM_IS_VALID: {
      return { ...state, isValid: action.payload };
    }
    case SET_FORM_IS_INITIALIZED: {
      return { ...state, isFormInitialized: action.payload };
    }
    default:
      return state;
  }
}
