import React from "react";
import { Check } from "@mui/icons-material";

export default function InlineResponse({
    responseBreakdown, 
    responseSuccess
})
{
    return(
        !responseSuccess && responseBreakdown
        ?
        (
            <p className="text-red-600 text-[10px]">{responseBreakdown}</p>
        )
        :
        (
            <p className="text-[12px]">
                {responseBreakdown}{" "}
                {responseSuccess ? <Check className="text-green-600" /> : ""}
            </p>
       )
    );
}