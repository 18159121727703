import React, { useEffect, useState } from "react";
import axios from "../../../../../../api/axios";
import { useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import { Gallery, Item } from "react-photoswipe-gallery";
import HideImageIcon from "@mui/icons-material/HideImage";
import ShowMoreButton from "../../../../../../global/ShowMoreButton";
import { useParams } from "react-router-dom";
import "photoswipe/dist/photoswipe.css";
import { Button, Tooltip } from "@mui/material";
import { AddPhotoAlternate, ArrowForward } from "@mui/icons-material";

export default function PhotoGallery({ docTrigger, openUploadModal }) {
  const params = useParams();
  const { userRoles, accessToken } = useContext(DataContext);
  const [loaded, setLoaded] = useState(false);
  const [images, setImages] = useState([]);
  const [imgResLimit, setImgResLimit] = useState(5);
  const [showMoreImgsVisible, setShowMoreImgsVisible] = useState(false);

  function onLoad() {
    setLoaded(!loaded);
  }
  const getImgWidth = (dataURL) => {
    const img = new Image();

    new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });
    return img.width;
  };
  const getImgHeight = (dataURL) => {
    const img = new Image();

    new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });
    return img.height;
  };
  useEffect(() => {
    axios
      .get(
        `/api/documents/?case=${params.id}&limit=${imgResLimit}&document_type__in=8,11,25,10,13,14`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        const data = response.data.results;
        if (response.data.count <= imgResLimit) {
          setShowMoreImgsVisible(false);
        } else {
          setShowMoreImgsVisible(true);
        }
        setImages(data);
      })
      .catch((error) => {
        return error;
      });
  }, [showMoreImgsVisible, docTrigger, imgResLimit, accessToken, params.id]);

  return (
    <div>
      <Gallery>
        <div
          className={`flex items-center ${
            !images ? "" : images.length >= 3 ? "" : "justify-center"
          } max-w-[40vw] xl:max-h-[50vh] overflow-x-auto py-4`}
        >
          {!images ? (
            ""
          ) : images.length === 0 ? (
            <p className="text-[2rem] text-gray-300	 text-center p-2">
              No Images <HideImageIcon />
            </p>
          ) : (
            images.map((img, idx) => (
              <Item
                key={idx}
                original={img.file}
                width={getImgWidth(img.file)}
                height={getImgHeight(img.file)}
              >
                {({ ref, open }) => (
                  <img
                    onLoad={onLoad}
                    ref={ref}
                    onClick={open}
                    src={img.file}
                    style={{ maxHeight: "43vh" }}
                    className="w-auto mr-4 my-2"
                    alt={img.file_name}
                  />
                )}
              </Item>
            ))
          )}
          <div className="mx-2">
            <ShowMoreButton
              showMoreVisible={showMoreImgsVisible}
              setResLimit={setImgResLimit}
              resLimit={imgResLimit}
              showMoreAmt={5}
              CustomIcon={<ArrowForward />}
            />
          </div>
        </div>
      </Gallery>

      {userRoles.permissions.includes("filemanager.add_document") ? (
        <Tooltip title="Upload Image to Case">
          <Button
            className="w-full p-2 m-2 hover:bg-sky-600 hover:text-white border-none text-sky-600"
            onClick={() => openUploadModal()}
          >
            <AddPhotoAlternate />
            Upload Image
          </Button>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
}
