const Error = ({ error }) => {
  return (
    <div id="Follow-Up-Error" data-testid="Follow-Up-Error">
      <span className="text-sm text-red-600">
          {error}
      </span>
    </div>
  );
};

export default Error;
