import DateTimeModel from "../../../CaseFollowUps/components/fields/DateTimeModel";
import { ACTION_TYPE } from "../../../CaseFollowUps/components/types/actionTypes";

const ViewFollowUp = ({ title, selectedFollowUp, navigateToDetail }) => {
  return (
    <div id="Follow-Up-View" data-testid="Follow-Up-View">
      <div className="text-[2rem]">{title}</div>
      <div className="border-1 border-solid border-slate-500 text-left p-4">
        <div>
          For: <span className="text-[1.25rem]">{selectedFollowUp.label}</span>
        </div>
        <div>
          Due Date:{" "}
          <span className="text-[1.25rem]">
            {selectedFollowUp.fields.dueDate &&
              DateTimeModel(selectedFollowUp.fields.dueDate)}
          </span>
          <span className="italic">
            {selectedFollowUp.timeTillDueDate &&
              " (" + selectedFollowUp.timeTillDueDate + ")"}
          </span>
        </div>
        <div>Reason: {selectedFollowUp.fields.detail}</div>

        {selectedFollowUp?.fields.actionType ===
          ACTION_TYPE.PROVIDER_FOLLOW_UP && (
          <div>Provider: {selectedFollowUp?.fields.provider}</div>
        )}
      </div>
      <hr />
      <div className="border-1 border-solid border-slate-500 text-left p-4">
        Status:{" "}
        <span className="uppercase">
          {selectedFollowUp.status && selectedFollowUp.status === "pending" ? "In-Complete" : "Complete"}
        </span>
        <div className="text-[0.85rem]">
          Completed By:{" "}
          {selectedFollowUp.completedBy && selectedFollowUp.completedBy.name}
        </div>
        <div className="text-[0.85rem]">
          Completed At:{" "}
          {selectedFollowUp.completedAt &&
            DateTimeModel(selectedFollowUp.completedAt)}
        </div>
      </div>
      
    </div>
  );
};

export default ViewFollowUp;
