import { ACTION_TYPE } from "../components/types/actionTypes";
import {
  CONTENT_LOADING_TOGGLE,
  DIALOG_IS_TOUCHED,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  SET_CASE_INFO,
  SET_FIELD_DATA,
  SET_FIELD_DATA_IS_LOADING,
  SET_FIELD_META_DATA,
  SET_SELECTED_FOLLOWUP,
  SET_SHOW_ADD_BUTTON,
  SHOULD_FETCH_DATA,
  SHOULD_FETCH_META_DATA,
  SHOW_BACK_TO_FOLLOWUPLIST_BUTTON,
  SHOW_CONTENT_SET,
  TOGGLE_DIALOG,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  shouldFetchTooltipData: true,
  showAddButton: true,
  loading: false,
  success: null,
  error: null,
  errorArray: "",
  caseInfo: null,
  selectedFollowUp: null,
  provider_name: null,
  showComponent: null,
  showBackToFollowUpListButton: false,
  showContent: "",
  prevContent:"",
  showDialog: false,
  dialogContentIsLoading: true,
  dialogIsLoading: false,
  dialogIsTouched:false,
  data: null,
  ui: {
    [ACTION_TYPE.CLIENT_FOLLOW_UP]: {
      tooltips: null,
      isLoading: true,
    },
    [ACTION_TYPE.LAW_FIRM_FOLLOW_UP]: {
      tooltips: null,
      isLoading: true,
    },
    [ACTION_TYPE.PROVIDER_FOLLOW_UP]: {
      tooltips: null,
      isLoading: true,
    },
  },
};

export function followUpReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return {
        ...state,
        shouldFetchData: action.payload,
        success: null,
        error: null,
        errorArray: "",
      };
    }
    case SHOULD_FETCH_META_DATA: {
      return { ...state, shouldFetchTooltipData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: "" };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: action.payload.showError,
        errorArray: action.payload.errorMessage,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case SET_FIELD_DATA_IS_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: {
            ...state.ui[action.payload.fieldName],
            isLoading: action.payload.data,
          },
        },
      };
    }
    case SET_FIELD_DATA: {
      return {
        ...state,
        success: null,
        error: false,
        errorArray: "",
        data: {
          ...state.data,
          [action.payload.fieldName]: action.payload.data,
        },
        dialogIsTouched: action.payload
      };
    }
    case SET_FIELD_META_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    }
    case TOGGLE_DIALOG: {
      return { ...state, showDialog: action.payload };
    }
    case SHOW_BACK_TO_FOLLOWUPLIST_BUTTON: {
      return { ...state, showBackToFollowUpListButton: action.payload };
    }
    case SHOW_CONTENT_SET: {
      let temp = state.showContent;
      return { ...state, showContent: action.payload, prevContent: temp };
    }
    case CONTENT_LOADING_TOGGLE: {
      return { ...state, dialogContentIsLoading: action.payload };
    }
    case SET_SELECTED_FOLLOWUP: {
      return { ...state, selectedFollowUp: action.payload };
    }
    case SET_CASE_INFO: {
      return { ...state, caseInfo: action.payload };
    }
    case SET_SHOW_ADD_BUTTON: {
      return { ...state, showAddButton: action.payload };
    }
    case DIALOG_IS_TOUCHED: {
      return { ...state, dialogIsTouched: action.payload };
    }
    default:
      return state;
  }
}
