import React, { useState, useRef, useContext } from "react";
import { Add, Remove, Sort } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import ShowMoreButton from "../../../../../../../../../../global/ShowMoreButton";
import useToggleDropdown from "../../../../../../../../../../hooks/useToggleDropdown";
import { useDebouncedEffect } from "../../../../../../../../../../hooks/useDebounceEffect";
import axios from "../../../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../../../context/DataContext";

export default function MultiSelectFilterV3({
  title,
  searchTitle,
  selectedItems,
  setActiveItems,
  activeItems,
  setSelectedItems,
  nameKey,
  valueKey,
  apiUrl,
  searchKey,
  orderingVal,
  resetPagination,
  buttonFullW,
  dropdown,
  filterName,
  setFilterState,
}) {
  const { accessToken } = useContext(DataContext);
  const [showForm, setShowForm] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [loadingRes, setLoadingRes] = useState(false);
  const accordionRef = useRef(null);
  useToggleDropdown(dropdown, accordionRef, () => setShowForm(false));

  useDebouncedEffect(
    () => {
      if (!apiUrl || !showForm) {
        return;
      } else {
        setLoadingRes(true);
        let searchQuery = !searchVal
          ? ""
          : `&${!searchKey ? "search" : searchKey}=${searchVal}`;
        axios
          .get(
            `${apiUrl}limit=${resLimit}&ordering=${
            !orderingVal ? "name" : orderingVal
            }${searchQuery}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            if(filterName === 'followUpFilter')
            {
              data = data.map(item => {
                return item.key !== 1 ? item : null;
              }).filter(item => item !== null);
            }
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
            setResponseArray(data);
            setLoadingRes(false);
          });
      }
    },
    [resLimit, searchVal, showForm],
    250
  );

  const handleSelectedItem = (event, info) => {
    let compVar =
      typeof event.target.name === "string"
        ? event.target.name
        : +event.target.name;
    if (selectedItems.includes(compVar)) {
      let activeItemName = info[nameKey];
      setActiveItems(activeItems.filter((e) => e !== activeItemName));
      setSelectedItems(selectedItems.filter((e) => e !== compVar));
    } else {
      setActiveItems([...activeItems, info[nameKey]]);
      setSelectedItems([...selectedItems, compVar]);
    }
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  const truncate = (str, max) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, max) + "..." : str;
  };

  return (
    <div ref={accordionRef} className="relative w-full bg-white">
      <div className="flex items-center">
        <Button
          onClick={() => setShowForm(!showForm)}
          className={`text-left normal-case text-[15px] p-2 ${
            buttonFullW ? "w-full justify-between" : "w-full justify-between"
          }`}
        >
          <span className="text-black px-2">{title}</span>
          {!showForm ? (
            <Add className="text-sky-600 mr-2" />
          ) : (
            <Remove className="text-sky-600 mr-2" />
          )}
        </Button>
      </div>
      {!showForm ? (
        ""
      ) : (
          <>
          {" "}
          <FormControl
            className={`max-h-[20vh] overflow-y-auto w-full w-[100%] ${dropdown? 'shadow-md px-4':''}`}
          >{loadingRes ? (
            <CircularProgress
              color="secondary"
              className="block mx-auto my-4"
            />
          ) : (
            <List className="w-[100%] p-2">
              {selectedItems && selectedItems.length > 0 ? (
                <ListItem className="block border-b border-black px-0 mb-4">
                  <span className=" py-1 text-[16px] w-full">
                    <Sort />
                    &nbsp; Selected {searchTitle}
                  </span>
                  <List>
                    {responseArray
                      .filter((item) =>
                        selectedItems.includes(`${item[valueKey]}`)
                      )
                      .map((item) => (
                        <ListItem key={item[valueKey]} className="m-0 p-0">
                          <FormControlLabel
                            className="break-all"
                            control={
                              <Checkbox
                                checked={
                                  !selectedItems
                                    ? false
                                    : selectedItems.includes(
                                        `${item[valueKey]}`
                                      )
                                    ? true
                                    : false
                                }
                                className="text-sm"
                                onClick={(event) =>
                                  handleSelectedItem(event, item)
                                }
                                name={`${item[valueKey]}`}
                              />
                            }
                            label={
                              <Typography className="break-all">
                                {truncate(item[nameKey], 75)}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </ListItem>
              ) : (
                ""
              )}
              {!responseArray
                ? ""
                : responseArray.map((item) => (
                    <ListItem key={item[valueKey]} className="m-0 p-0">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !selectedItems
                                ? false
                                : selectedItems.includes(`${item[valueKey]}`)
                                ? true
                                : false
                            }
                            className="text-sm"
                            onClick={(event) => handleSelectedItem(event, item)}
                            name={`${item[valueKey]}`}
                          />
                        }
                        label={
                          <Typography className="break-all">
                            {truncate(item[nameKey], 75)}
                          </Typography>
                        }
                      />

                      <div
                        className={`w-[15px] h-[15px] rounded-full ${
                          !item.client && item.works_for
                            ? item.works_for === "law_firm"
                              ? "bg-yellow-400"
                              : "bg-purple-600"
                            : ""
                        }`}
                      ></div>
                    </ListItem>
                  ))}
            </List>
          )}
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={25}
            />
          </FormControl>
          </>
      )}
    </div>
  );
}
