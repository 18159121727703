import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";

export default function OverarchingProvMap() {
  const { accessToken } = useContext(DataContext);
  const [chiroData, setChiroData] = useState([]);
  const [mriData, setMRIData] = useState([]);
  const [orthoData, setOrthoData] = useState([]);
  const [activeInfoWindow, setActiveInfoWindow] = useState("");

  const containerStyle = {
    width: "80vw",
    height: "80vh",
  };
  const center = {
    lat: 33.871181,
    lng: -84.268303,
  };

  useEffect(() => {
    axios
      .get(`/api/provider/?limit=1000&in_network=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        sortType(data);
      });
  }, [accessToken]);

  function sortType(data) {
    let chiro = [];
    let mri = [];
    let ortho = [];

    for (const item in data) {
      if (data[item].type === "Chiropractor") {
        chiro.push(data[item]);
      }
      if (data[item].type === "MRI") {
        mri.push(data[item]);
      }
      if (data[item].type === "Orthopedic") {
        ortho.push(data[item]);
      }
    }

    setChiroData(chiro);
    setMRIData(mri);
    setOrthoData(ortho);
  }

  const infoWindowDisplay = (prov) => {
    return (
      <div className="grid p-15">
        <h1 className="pb-2 font-bold text-lg">{prov.name}</h1>
        <p className="">{prov.address.raw}</p>
      </div>
    );
  };

  const markerClicked = (index) => {
    setActiveInfoWindow(index);
  };

  return (
    <div className="h-full bg-white pb-6">
      <div className="text-center text-xl text-purple-600 font-bold mt-10">
        <h1 className="text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2">
          Global Providers Map
        </h1>
      </div>

      <div className="grid grid-cols-3 text-center my-4 font-bold text-lg">
        <div>
          Chiropractor:{" "}
          <span className="text-purple-600 px-2 ml-4">
            {!chiroData ? "" : chiroData.length}
          </span>
        </div>
        <div>
          MRI:{" "}
          <span className="text-yellow-600 px-2 ml-4">
            {!mriData ? "" : mriData.length}
          </span>
        </div>
        <div>
          Orthopedic:{" "}
          <span className="text-blue-500 px-2 ml-4">
            {!orthoData ? "" : orthoData.length}
          </span>
        </div>
      </div>

      <div className="flex justify-center">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {!chiroData
            ? ""
            : chiroData.map((marker, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: marker.address.latitude,
                    lng: marker.address.longitude,
                  }}
                  options={{
                    icon: "https://img.icons8.com/ios-filled/50/9333EA/map-pin.png",
                  }}
                  onClick={(event) => markerClicked(marker.name)}
                >
                  {activeInfoWindow === marker.name && (
                    <InfoWindow position={marker.position}>
                      {infoWindowDisplay(marker)}
                    </InfoWindow>
                  )}
                </Marker>
              ))}

          {!mriData
            ? ""
            : mriData.map((marker, index) => (
                <Marker
                  key={index}
                  options={{
                    icon: "https://img.icons8.com/ios-filled/50/ea9333/map-pin.png",
                  }}
                  position={{
                    lat: marker.address.latitude,
                    lng: marker.address.longitude,
                  }}
                  onClick={(event) => markerClicked(marker.name)}
                >
                  {activeInfoWindow === marker.name && (
                    <InfoWindow position={marker.position}>
                      {infoWindowDisplay(marker)}
                    </InfoWindow>
                  )}
                </Marker>
              ))}
          {!orthoData
            ? ""
            : orthoData.map((marker, index) => (
                <Marker
                  key={index}
                  options={{
                    icon: "https://img.icons8.com/ios-filled/50/1976d2/map-pin.png",
                  }}
                  position={{
                    lat: marker.address.latitude,
                    lng: marker.address.longitude,
                  }}
                  onClick={(event) => markerClicked(marker.name)}
                >
                  {activeInfoWindow === marker.name && (
                    <InfoWindow position={marker.position}>
                      {infoWindowDisplay(marker)}
                    </InfoWindow>
                  )}
                </Marker>
              ))}
        </GoogleMap>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://icons8.com/icon/43731/map-pin"
        className="hidden"
      >
        Map Pin icon by Icons8
      </a>
    </div>
  );
}
