import React from "react";
import FollowUpCalendarProvider from "./context/FollowUpCalendarContext";
import FCalendar from "./components/FCalendar";

function FollowUpCalendar() {
  return (
    //Unfortunatly Need extra level to pass provider through child of FollowUpCalendar to access state.
    <FollowUpCalendarProvider>
      <FCalendar />
    </FollowUpCalendarProvider>
  );
}

export default FollowUpCalendar;
