import FollowUpList from "./FollowUpList";
import NoAvailableFollowUps from "./NoAvailableFollowUps";
import Header from "./Header";
import FollowUpModal from "../../../FollowUpModal";
import {
  FollowUpDispatchContext,
  FollowUpStateContext,
} from "../../context/FollowUpContext";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import useAPIFieldData from "../../hooks/useAPIFieldData";
import { setCaseInfo, setShouldFetchData } from "../../state/actions";
import { ACTION_TYPE } from "../types/actionTypes";
import { CircularProgress } from "@mui/material";

const FollowUpSection = ({ divId, actionType, caseInfo, title, icon }) => {
  const params = useParams();
  const state = useContext(FollowUpStateContext);
  const dispatch = useContext(FollowUpDispatchContext);
  const { accessToken } = useContext(DataContext);
  const [fetchData] = useAPIFieldData(
    params.id,
    actionType,
    accessToken,
    dispatch,
    state
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (state && !state.fetchIsLoading && state.shouldFetchData) {
        fetchData();
      }
    }
    return () => {
      active = false;
    };
  }, [state, accessToken, fetchData]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (params) {
        setShouldFetchData(dispatch, true);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, params]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (caseInfo) {
        setCaseInfo(dispatch, caseInfo);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, caseInfo]);

  return (
    <div id={divId} data-testid={divId}>
      <div className="m-2 mb-2">
        <Header
          id={"header-" + divId}
          actionType={actionType}
          title={title}
          icon={icon}
          showAddButton={state.showAddButton}
        />
        {!state.shouldFetchData ? (
          state.data && state.data[actionType] ? (
            <FollowUpList
              listOfItems={
                actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP
                  ? state.data[actionType]
                  : state.data
              }
              label={
                actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP
                  ? null
                  : state.data[actionType].label
              }
              actionType={actionType}
            />
          ) : (
            <NoAvailableFollowUps divId={divId} />
          )
        ) : (
          <CircularProgress />
        )}
      </div>
      <FollowUpModal divId={divId} title={title} actionType={actionType} state={state} dispatch={dispatch} />
    </div>
  );
};

export default FollowUpSection;
