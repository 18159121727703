import React from "react";

export const Banner = () => {
  return (
    <div className="flex flex-col">
      <div data-testid="banner-gradient-1" className="h-2 bg-gradient-to-r from-[#F58626] to-[#F05A22]"></div>

      <div data-testid="banner-gradient-2" className="h-32 bg-gradient-to-r from-[#5248E6] to-[#2E2880] flex items-center justify-center md:justify-start">
        <img
          alt="NetProApp Banner"
          src="/assets/NetProApp_banner_logo.png"
          className="w-72 2xl:w-[346px] md:ml-11"
        />
      </div>

      <div data-testid="banner-gradient-3" className="h-2 bg-gradient-to-r from-[#817FE1] to-[#403F70]"></div>
    </div>
  );
};

