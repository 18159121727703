const DialogHeader = ({ children }) => {
  const titleStyle = {
    fontSize: "1.6rem",
    textAlign: "center",
    borderBottom: "1px solid #eee",
  };
  return (
    <div style={titleStyle}>
      <h2 className="text-black">{children}</h2>
    </div>
  );
};

export default DialogHeader;
