import { createContext, useReducer } from "react";
import { calendarReducer, initialState } from "../state/calendarReducer";

export const FollowUpCalendarDispatchContext = createContext();
export const FollowUpCalendarStateContext = createContext();

const FollowUpCalendarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(calendarReducer, initialState);

  return (
    <FollowUpCalendarDispatchContext.Provider value={dispatch}>
      <FollowUpCalendarStateContext.Provider value={state}>
        {children}
      </FollowUpCalendarStateContext.Provider>
    </FollowUpCalendarDispatchContext.Provider>
  );
};

export default FollowUpCalendarProvider;
