export const tooltipData = {
  title: null,
  description: null,
  flag_state_tooltip: { 0: null, 1: null, 2: null, 3: null },
  clock_state_tooltip: {
    0: null,
    1: "Due in 2+ Weeks",
    2: "Due in 1-2 Weeks",
    4: "Due: Less than 1 Week",
    3: "Overdue",
  },
  flag_state_tooltip_title: null,
  clock_state_tooltip_title: null,
  flag_state_tooltip_description: null,
  clock_state_tooltip_description: null,
};
