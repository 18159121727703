import React, { useContext } from "react";
import PrimaryContacts from "./PrimaryContacts";
import AlternateContacts from "./AlternateContacts";
import DataContext from "../../../../../../../context/DataContext";

export default function ContactMangementSection({ caseObj }) {
  const { userRoles } = useContext(DataContext);
  return (
    <div className="pb-5">
      <PrimaryContacts
        caseObj={caseObj}
        editPermissions={userRoles.permissions.includes("users.change_user")}
      />
      {!userRoles.permissions.includes("users.view_usercontactinfo") ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account at this time
        </h2>
      ) : (
        <AlternateContacts
          caseObj={caseObj}
          editPermissions={userRoles.permissions.includes(
            "users.change_usercontactinfo"
          )}
          deletePermissions={userRoles.permissions.includes(
            "users.delete_usercontactinfo"
          )}
        />
      )}
    </div>
  );
}
