import FollowUpViews from "./components/FollowUpViews";
import { PhoneOutlined } from "@mui/icons-material";

const CaseFollowUps = ({ clientId, caseId, lawFirmId }) => {
  const caseInfo = {
    clientId:clientId,
    caseId:caseId,
    lawFirmId:lawFirmId
  }
  return (
    <div id="Follow-Ups" data-testid="Follow-Ups">
      <FollowUpViews
          divId={"C-Follow-Ups"}
          title={"Client Follow Ups"}
          icon={<PhoneOutlined />}
          limit={1}
          actionType={2}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          divId={"LF-Follow-Ups"}
          title={"Law Firm Follow Ups"}
          icon={<PhoneOutlined />}
          limit={1}
          actionType={3}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          divId={"P-Follow-Ups"}
          title={"Provider Follow Ups"}
          icon={<PhoneOutlined />}
          limit={null}
          actionType={4}
          caseInfo={caseInfo}
        />
    </div>
  );
};

export default CaseFollowUps;
