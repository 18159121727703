import { Cancel, Check, Close, Edit, Info } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import HtmlTooltip from "../../../../../../global/HtmlTooltip";
import DynamicInput from "./inputs/DynamicInput";
import { currencyFormatter, truncate } from "../../../../../../global/helpers";

export default function InlineEditRow({
  title,
  type,
  keyVal,
  apiUrl,
  optionsUrl,
  permissions,
  displayNameKey,
  customLimit,
  postKeyVal,
  trigger,
  setTrigger,
  optionsDisplayKey,
  valueHistory,
  displayDetail,
  valueLabels,
  disabled,
  nullable,
  staticVal,
  customDisplayUrl,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [val, setVal] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [hoverActive, setHoverActive] = useState(false);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [activeEditObj, setActiveEditObj] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const value =
      staticVal || staticVal === 0 ? staticVal : displayDetail[keyVal];
    if (type === "multi-search-select" && value) {
      const customValArr = value.map((item) => valueLabels[item]);
      setVal(value);
      setActiveEditObj(customValArr);
    } else if (customDisplayUrl && valueLabels && value) {
      setVal(valueLabels[value][displayNameKey]);
      setActiveEditObj({
        [displayNameKey]: valueLabels[value][displayNameKey],
      });
    } else if (value !== "" && value !== null && valueLabels) {
      setVal(value);
      setActiveEditObj(
        optionsDisplayKey && value
          ? {
              [optionsDisplayKey]: value,
            }
          : valueLabels[keyVal] && displayNameKey && value
          ? {
              [displayNameKey]: valueLabels[keyVal][val],
            }
          : valueLabels[keyVal] && value
          ? {
              label: valueLabels[keyVal][val],
            }
          : ""
      );
    } else {
      setVal("");
      setActiveEditObj("");
    }
    // eslint-disable-next-line
  }, [displayDetail, valueLabels, keyVal, optionsDisplayKey, trigger]);

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr[0]);
    setResponseSuccess(false);
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    const res = response;
    setTrigger(!trigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setOpen(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const submitData = (title) => {
    const ids = typeof val[0] === "object" ? val.map(({ pk }) => pk) : [];

    const postVal =
      val === "No Selection" || val === ""
        ? null
        : type === "multi-search-select"
        ? ids
        : postKeyVal && !val
        ? null
        : postKeyVal
        ? val[postKeyVal]
        : val;
    const postData = {
      [keyVal]: postVal,
    };

    axios
      .patch(apiUrl, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, title);
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const cancelEdit = () => {
    const value = displayDetail[keyVal];
    if (type === "multi-search-select" && valueLabels) {
      setVal(value);
      setOpen(false);
      setResponseBreakdown("");
      setResponseSuccess(null);
    } else if (customDisplayUrl && valueLabels) {
      setVal(valueLabels[value][displayNameKey]);
      setActiveEditObj({
        [displayNameKey]: valueLabels[value][displayNameKey],
      });
      setOpen(false);
      setResponseBreakdown("");
      setResponseSuccess(null);
    } else {
      setVal(value ? value : "");
      setOpen(false);
      setResponseBreakdown("");
      setResponseSuccess(null);
    }
  };

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes(
          !permissions ? "utilities.view_fieldoptions" : permissions
        ) &&
        optionsUrl &&
        open &&
        type !== "search-select"
      ) {
        axios
          .get(
            optionsUrl +
              `&limit=${resLimit}` +
              `${search ? `&search=${search}` : ""}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(response.data.results);
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
          });
      } else {
        return;
      }
    },
    [
      userRoles.permissions,
      accessToken,
      setOptions,
      search,
      resLimit,
      optionsUrl,
      open,
    ],
    250
  );

  const typeDisplayFormat = (value) => {
    const chipColors = ["sky-600", "green-600", "orange-600", "purple-600"];

    if (value === "" || value === null) {
      return "Add Value";
    } else if (valueLabels[keyVal] && type !== "multi-search-select") {
      return valueLabels[keyVal][val];
    } else if (
      typeof val === "object" &&
      type !== "multi-search-select" &&
      type !== "address"
    ) {
      return "Add Value";
    } else if (type === "date") {
      return new Date(value).toLocaleDateString("en-US", {
        timeZone: "UTC",
      });
    } else if (type === "multi-search-select" && value) {
      return value.length > 0
        ? value.map((item, idx) => (
            <Chip
              className={`text-sm m-1 w-fit uppercase bg-${chipColors[idx]} text-white`}
              label={
                !valueLabels || !valueLabels[item]
                  ? ""
                  : valueLabels[item][displayNameKey]
              }
            />
          ))
        : "Add Value";
    } else if (type === "address" && value) {
      return value.raw;
    } else if (type === "number") {
      return currencyFormatter.format(value);
    } else {
      return truncate(value, 30);
    }
  };

  return open ? (
    <div className="w-full mb-2">
      <div className="flex items-center w-full justify-between mt-2">
        <div className="w-[80%] self-center">
          <DynamicInput
            setVal={setVal}
            val={val}
            title={title}
            type={type}
            keyVal={keyVal}
            caseDetail={displayDetail}
            options={options}
            apiUrl={optionsUrl}
            activeEditObj={activeEditObj}
            setSearch={setSearch}
            search={search}
            responseSuccess={responseSuccess}
            responseBreakdown={responseBreakdown}
            displayNameKey={displayNameKey}
            customLimit={customLimit}
            postKeyVal={postKeyVal}
            optionsDisplayKey={optionsDisplayKey}
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            nullable={nullable}
          />
        </div>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : responseSuccess === false ? (
          <Cancel className="text-red-600" />
        ) : (
          ""
        )}
        {responseSuccess ? (
          ""
        ) : (
          <div className="flex flex-wrap justify-end w-[20%]">
            <Tooltip title="Submit">
              <IconButton
                className="text-green-600"
                onClick={() => submitData(title)}
                size="small"
              >
                <Check className="text-[20px]" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className="text-red-600"
                onClick={() => cancelEdit()}
                size="small"
              >
                <Close className="text-[20px]" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      {responseSuccess === false && responseBreakdown ? (
        <p className="text-red-600 text-[10px]">{responseBreakdown}</p>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div>
      <div>
        <h2 className="text-gray-400">
          {title}:{" "}
          {valueHistory ? (
            <HtmlTooltip
              title={
                <div className="w-fit">
                  Previous value:{" "}
                  {valueHistory["old_value"] ? valueHistory["old_value"] : "-"}
                  <br />
                  Updated at:{" "}
                  {new Date(valueHistory["timestamp"]).toLocaleString()}
                  <br />
                  Updated by: {valueHistory["user"]}
                </div>
              }
              placement="right"
            >
              <Info className="text-[16px] text-black" />
            </HtmlTooltip>
          ) : (
            ""
          )}
        </h2>
        <Tooltip title={disabled ? "" : "Click to edit"}>
          <span>
            <Button
              onClick={() => {
                setOpen(true);
                setHoverActive(false);
              }}
              disabled={disabled}
              className="text-black hover:bg-gray-200 text-left break-all normal-case"
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
            >
              {typeDisplayFormat(val)}{" "}
              <span className="w-[20px] h-[20px]">
                {hoverActive ? <Edit className="text-gray-400" /> : ""}
              </span>
            </Button>
          </span>
        </Tooltip>
      </div>
      <p className="text-[12px]">
        {responseBreakdown}{" "}
        {responseSuccess ? <Check className="text-green-600" /> : ""}
      </p>
    </div>
  );
}
