import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadImage from "./UploadImage";
import { useSearchParams } from "react-router-dom";
import MessageDialog from "./MessageDialog";
import { DOCUMENT_TYPES } from "../../../globalConstants";
import axios from "../../api/axios";

const DEFAULT_ERR_MSG = "Something wrong! Contact your ITN Representative."

const isEmptyOrAllNull = obj => Object.keys(obj).length === 0 || Object.values(obj).every(value => !value);

function ClientUploadDocument() {
  const [identifyDataSubmit, setIdentifyDataSubmit] = useState({});
  const [identifyData, setIdentifyData] = useState({});
  const [params] = useSearchParams();
  const token = params.get("temporary_token");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const isDisableSubmitButton = isSubmitting || !!error || isEmptyOrAllNull(identifyDataSubmit)

  const onUploadFile = (file, type) => {
    setIdentifyDataSubmit(prev => ({
      ...prev,
      [type]: file
    }));
  };

  const onSubmit = async () => {
    const listFile = Object.entries(identifyDataSubmit)
        .filter(([key, value]) => !!value && !!key)
        .map(([key, value]) => {
          const formData = new FormData();
          formData.append("file", value);
          formData.append("document_type", DOCUMENT_TYPES[key]);
          return axios.post(`/api/documents_external_upload/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            params: {
              temporary_token: token
            }
          });
        });
    if (listFile.length > 0) {
      setIsSubmitting(true);
      const res = await Promise.allSettled(listFile)
      if (res.some(p => p.status === "fulfilled")) {
        const successPromise = res.find(p => p.status === "fulfilled")
        setSuccessMsg(successPromise.value?.data?.detail || "Worked");
        getUploadedDocuments()
        setIdentifyDataSubmit({})
      } else {
        const failPromise = res.find(p => p.status === "rejected")
        setErrorSubmit(failPromise.reason.response?.data?.detail || DEFAULT_ERR_MSG);
      }
      setIsSubmitting(false);
    }
  };

  const getUploadedDocuments = async () => {
    try {
      const res = await axios.get("/api/documents_external_upload/", {
        params: {
          temporary_token: token,
          document_types: [
            DOCUMENT_TYPES.DRIVER_LICENSE_FRONT,
            DOCUMENT_TYPES.DRIVER_LICENSE_BACK
          ]
        }
      });
      const objTmp = {};
      if (res.data.length > 0) {
        Object.keys(DOCUMENT_TYPES).forEach(k => {
          const document = res.data.find(
            d => d.document_type === DOCUMENT_TYPES[k]
          );
          if (document) {
            objTmp[k] = document.file;
          }
        });
      }
      setIdentifyData(objTmp);
    } catch (err) {
      setIdentifyData({});
    }
  };

  useEffect(
    () => {
      const validateToken = async () => {
        setIsLoading(true);
        try {
          await axios.get("/api/validate_token", {
            params: { temporary_token: token }
          });
          await getUploadedDocuments();
        } catch (err) {
          setError(err.response?.data?.detail || DEFAULT_ERR_MSG);
        } finally {
          setIsLoading(false);
        }
      };
      validateToken();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  return (
    <div className="max-w-3xl w-full m-auto p-8 min-h-screen flex">
      <div className="flex flex-col justify-between md:justify-start gap-10 flex-1">
        {isLoading &&
          <div className="w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-[1] bg-gray-500 bg-opacity-50">
            <CircularProgress />
          </div>}
        <div className="flex flex-col gap-10">
          <p className="text-lg font-bold text-lime-500">Identification</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <UploadImage
              fileUrl={identifyData.DRIVER_LICENSE_FRONT}
              onUploadFile={file => onUploadFile(file, "DRIVER_LICENSE_FRONT")}
              maxSizeByMB={30}
              annotation="Driver's License - FRONT"
            />
            <UploadImage
              fileUrl={identifyData.DRIVER_LICENSE_BACK}
              onUploadFile={file => onUploadFile(file, "DRIVER_LICENSE_BACK")}
              maxSizeByMB={30}
              annotation="Driver's License - BACK"
            />
          </div>
        </div>
        <div className="text-right">
          <Button
            onClick={onSubmit}
            size="large"
            endIcon={
              isSubmitting
                ? <CircularProgress size="14px" className="text-lime-500" />
                : <SendIcon />
            }
            className="text-lime-500 font-bold"
            disabled={isDisableSubmitButton}
          >
            Send
          </Button>
        </div>
      </div>
      <MessageDialog open={!!error} isError title={error} />
      <MessageDialog
        open={!!successMsg}
        title={successMsg}
        onClose={() => setSuccessMsg("")}
      />
      <MessageDialog
        isError
        open={!!errorSubmit}
        title={errorSubmit}
        onClose={() => setErrorSubmit("")}
      />
    </div>
  );
}

export default ClientUploadDocument;
