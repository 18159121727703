import {
  CONTENT_FOLLOWUPLIST,
  CONTENT_LOADING_TOGGLE,
  CURRENT_PAGE_SET,
  CURRENT_PAGE_URL_SET,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  ORDERING_VAL_SET,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SET_CASE_INFO,
  SET_FIELD_DATA,
  SET_FIELD_DATA_IS_LOADING,
  SET_FIELD_META_DATA,
  SET_NAVIGATION_HISTORY,
  SET_SELECTED_FOLLOWUP,
  SET_SHOW_ADD_BUTTON,
  SHOULD_FETCH_DATA,
  SHOULD_FETCH_META_DATA,
  SHOW_CONTENT_SET,
  TOGGLE_DIALOG,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  shouldFetchTooltipData: true,
  showAddButton: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  caseInfo: null,
  selectedFollowUp: null,
  provider_name: null,
  showComponent: null,
  showContent: CONTENT_FOLLOWUPLIST,
  navigationHistory:[],
  showDialog: false,
  dialogContentIsLoading: true,
  dialogIsLoading: false,
  data: [],
  resLimit: 25,
  currentPage: 1,
  paginationUrl: "",
  resultCount: { total: "", current: "" },
  orderingVal: "due_date",
};

export function historyListReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case SHOULD_FETCH_META_DATA: {
      return { ...state, shouldFetchTooltipData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case SET_NAVIGATION_HISTORY: {
      return { ...state, navigationHistory: action.payload };
    }
    case SET_FIELD_DATA_IS_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: {
            ...state.ui[action.payload.fieldName],
            isLoading: action.payload.data,
          },
        },
      };
    }
    case SET_FIELD_DATA: {
   
      return {
        ...state,
        data:  action.payload.data
        ,
      };
    }
    case SET_FIELD_META_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    }
    case TOGGLE_DIALOG: {
      return { ...state, showDialog: action.payload };
    }
    case SHOW_CONTENT_SET: {
      return { ...state, showContent: action.payload };
    }
    case CONTENT_LOADING_TOGGLE: {
      return { ...state, dialogContentIsLoading: action.payload };
    }
    case SET_SELECTED_FOLLOWUP: {
      return { ...state, selectedFollowUp: action.payload };
    }
    case SET_CASE_INFO: {
      return { ...state, caseInfo: action.payload };
    }
    case SET_SHOW_ADD_BUTTON: {
      return { ...state, showAddButton: action.payload };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case CURRENT_PAGE_URL_SET: {
      return { ...state, paginationUrl: action.payload };
    }
    case ORDERING_VAL_SET: {
      return { ...state, orderingVal: action.payload };
    }
    default:
      return state;
  }
}
