import axios from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.netproapp.com",
});

const handleForbiddenError = () => {
  const cookiesToRemove = [
    "pnAccessToken",
    "pnCmPk",
    "userType",
    "category",
    "token_exp",
  ];

  cookiesToRemove.forEach((cookie) => Cookies.remove(cookie, { path: "/" }));

  window.location.href = "/";
};

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && response.status === 403 && response.data?.detail === "Invalid token.") {
      handleForbiddenError();
    }

    return Promise.reject(error);
  }
);

export default apiClient;
