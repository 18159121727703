import React from "react";
import ValueWithFallback from "../ui/ValueWithFallback";
import BaseField from "../ui/BaseField";
export default function CommunicationStatus({
    fieldName,
    value,
    flag,
    clock,
})
{
    return(
     <BaseField
        id="SB-Communication-Status"
        testId="SB-Communication-Status"
        fieldName={fieldName}
        label={"Communication Status"}
        flag={flag}
        clock={clock}
      >
        <span>{ValueWithFallback(value)}</span>

      </BaseField>
    )
}