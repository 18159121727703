import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import { ControlPointOutlined, OpenInNew } from "@mui/icons-material";
import ModalShell from "../../../../../../global/ModalShell";
import ResponseModal from "../../../../../../global/ResponseModal";
import { useNavigate } from "react-router-dom";
import ProviderToLocation from "../ProviderToLocation";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import ContactControlsMobile from "../../../../../../global/ContactControlsMobile";

export default function OrgChart({
  userInfo,
  myAccount,
  profile,
  managedLocations,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [userManagerAssignments, setUserManagerAssignments] = useState([]);
  const [managedUserAssignments, setManagedUserAssignments] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAddManager, setOpenAddManager] = useState(false);
  const [utcmType, setUtcmType] = useState("");
  const [openUserDetail, setOpenUserDetail] = useState(false);
  const [activeUserDetail, setActiveUserDetail] = useState("");
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (userRoles.permissions.includes("users.view_user")) {
      axios
        .get(
          `/api/users/all_assignable_users/?ordering=name${
            !userSearch ? "" : `&search=${userSearch}`
          }`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          setAssignableUsers(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
        });
    } else {
      return;
    }
  }, [
    accessToken,
    userSearch,
    setAssignableUsers,
    trigger,
    userRoles.permissions,
  ]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("users.view_usertomanagerassignments") &&
      userInfo
    ) {
      axios
        .get(`/api/user_manager/?user=${userInfo.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setUserManagerAssignments(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
        });
    } else {
      return;
    }
  }, [
    accessToken,
    setUserManagerAssignments,
    trigger,
    userInfo,
    userRoles.permissions,
  ]);

  useEffect(() => {
    setLoading(true);
    if (
      userRoles.permissions.includes("users.view_usertomanagerassignments") &&
      userInfo
    ) {
      axios
        .get(`/api/user_manager/?managed_by=${userInfo.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setManagedUserAssignments(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
        });
    } else {
      return;
    }
  }, [
    accessToken,
    setManagedUserAssignments,
    trigger,
    userInfo,
    userRoles.permissions,
  ]);

  const assignUserToManager = () => {
    //assign a user to this user profile's team
    const postData =
      utcmType === "manager"
        ? {
            managed_by: selectedUser.pk,
            user: userInfo.pk,
          }
        : {
            user: selectedUser.pk,
            managed_by: userInfo.pk,
          };

    axios
      .post(`/api/user_manager/`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })

      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("User relation has been added");
        setIsError(false);
        setTrigger(!trigger);
        setSelectedUser("");
      })
      .catch((error) => {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const deleteUtma = (pk) => {
    //assign a user to this user profile's team

    axios
      .delete(`/api/user_manager/${pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("User has been deleted");
        setIsError(false);
        setTrigger(!trigger);
      })
      .catch((error) => {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const handleOpenUserDetail = (user) => {
    setActiveUserDetail(user);
    setOpenUserDetail(true);
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setSelectedUser("");
  };

  const openAddUtma = (type) => {
    setOpenAddManager(true);
    setUtcmType(type);
  };

  return (
    <div>
      {!profile ? (
        ""
      ) : errorMessage ? (
        errorMessage
      ) : loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <div>
          <div className="flex items-center w-full border-b mb-2 pb-1">
            <h2 className="font-bold">Management team</h2>
            {userRoles.permissions.includes(
              "users.add_usertocaseassignments"
            ) && !myAccount ? (
              <Tooltip title="Add Manager">
                {" "}
                <IconButton
                  onClick={() => openAddUtma("manager")}
                  className="text-green-600"
                >
                  <ControlPointOutlined className="block" />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div>
            {userManagerAssignments.length === 0 ? (
              <p>No managers found</p>
            ) : (
              userManagerAssignments.map((manager) => (
                <Chip
                  key={manager.pk}
                  label={manager.managed_by.name}
                  onClick={() => handleOpenUserDetail(manager.managed_by)}
                  onDelete={
                    userRoles.permissions.includes(
                      "users.delete_usertocaseassignments"
                    )
                      ? () => deleteUtma(manager.pk)
                      : false
                  }
                />
              ))
            )}
          </div>
        </div>
      )}
      <div className="my-2">
        <div className="flex items-center w-full border-b mb-2 pb-1">
          <h2 className="font-bold">Managed Users</h2>
          {userRoles.permissions.includes("users.add_usertocaseassignments") ? (
            <Tooltip title="Add User">
              {" "}
              <IconButton
                onClick={() => openAddUtma("user")}
                className="text-green-600"
              >
                <ControlPointOutlined className="block" />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div>
          {managedUserAssignments.length === 0 ? (
            <p>No managed users found</p>
          ) : (
            managedUserAssignments.map((user) => (
              <Chip
                label={user.user.name}
                onClick={() => handleOpenUserDetail(user.user)}
                className="mr-1 mb-1"
                onDelete={
                  userRoles.permissions.includes(
                    "users.delete_usertocaseassignments"
                  )
                    ? () => deleteUtma(user.pk)
                    : false
                }
              />
            ))
          )}
        </div>
      </div>
      {userRoles.permissions.includes(
        "users.view_usertoproviderassignments"
      ) ? (
        <ProviderToLocation
          managedLocations={managedLocations}
          userInfo={userInfo}
        />
      ) : (
        ""
      )}

      <ModalShell
        open={openUserDetail}
        setOpen={setOpenUserDetail}
        color="#1976d2"
        title="User Detail"
      >
        {!activeUserDetail ? (
          "No Details Found"
        ) : (
          <div>
            <List clas>
              <ListItem className="justify-center">
                {activeUserDetail.name}{" "}
                <Tooltip title="View User Profile">
                  {" "}
                  <IconButton
                    onClick={() => navigate(`/profile/${activeUserDetail.pk}`)}
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <ListItem className="justify-center">
                {!activeUserDetail.phone ? (
                  ""
                ) : (
                  <div className="flex items-center ">
                    <p className="font-bold mr-2">{activeUserDetail.phone}</p>
                    <ContactControlsMobile number={activeUserDetail.phone} />
                  </div>
                )}
              </ListItem>
              <ListItem className="justify-center">
                <Tooltip title="Send Email">
                  <a
                    href={`mailto:${activeUserDetail.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {activeUserDetail.email}
                  </a>
                </Tooltip>
              </ListItem>
            </List>
          </div>
        )}
      </ModalShell>

      <ModalShell
        open={openAddManager}
        setOpen={setOpenAddManager}
        color="#1976d2"
        title={`Assign ${utcmType}`}
      >
        <h2>
          Assign {utcmType} to <strong>{userInfo.name}</strong>
        </h2>
        <div
          style={{
            margin: "1rem auto 1rem",
            minWidth: "25vw",
          }}
        >
          <Autocomplete
            className="w-full"
            disablePortal
            options={!assignableUsers ? [] : assignableUsers}
            getOptionLabel={(option) => option.name}
            noOptionsText="No Results"
            sx={{ width: 300 }}
            onChange={(e, value) => {
              setSelectedUser(value);
            }}
            value={!selectedUser ? null : selectedUser}
            renderOption={(props, option) => {
              return (
                <li {...props} id={option.name} key={option.name}>
                  {option.name}
                </li>
              );
            }}
            ListboxProps={{
              style: {
                maxHeight: "100px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assignable Users"
                onChange={(e) => setUserSearch(e.target.value)}
              />
            )}
          />
          <div className="mt-4 mx-auto w-fit">
            <Button onClick={() => assignUserToManager()} variant="outlined">
              Assign
            </Button>
          </div>
        </div>
      </ModalShell>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
