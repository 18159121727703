import React, { useContext, useEffect } from "react";
import Filters from "./Filters";
import CalendarView from "./CalendarView";
import {
  FollowUpCalendarDispatchContext,
  FollowUpCalendarStateContext,
} from "../context/FollowUpCalendarContext";
import DataContext from "../../../../../../../../context/DataContext";
import { setDates, setShouldFetchData } from "../state/actions";
import { setActionTypes, setfilters, setSearch } from "../state/actions";
import useGetAllActionTypes from "../hooks/getAllActionTypes";
import Search from "./Search";
import { FilterProvider } from "../context/FilterContext";
import { useDebouncedEffect } from "../../../../../../../hooks/useDebounceEffect";

function FCalendar() {
  const state = useContext(FollowUpCalendarStateContext);
  const dispatch = useContext(FollowUpCalendarDispatchContext);
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [fetchAllFollowUpData] = useGetAllActionTypes(
    loggedInUser.pk,
    state.orderingVal,
    state.paginationUrl,
    state.actionTypes,
    state.managedUserFilter,
    state.providerFilter,
    state.followUpFilter,
    state.medicalStatusFilter,
    state.caseStatusFilter,
    state.lawFirmFilter,
    state.startDateFilter,
    state.endDateFilter,
    state.search,
    accessToken,
    dispatch
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (state.shouldFetchData) {
        fetchAllFollowUpData(
          loggedInUser.pk,
          state.orderingVal,
          state.paginationUrl,
          state.actionTypes,
          state.managedUserFilter,
          state.providerFilter,
          state.followUpFilter,
          state.medicalStatusFilter,
          state.caseStatusFilter,
          state.lawFirmFilter,
          state.startDateFilter,
          state.endDateFilter,
          state.search,
          accessToken,
          dispatch
        );
      }
    }
    return () => {
      active = false;
    };
  }, [
    loggedInUser.pk,
    state.shouldFetchData,
    state.actionTypes,
    state.search,
    state.managedUserFilter,
    state.providerFilter,
    state.followUpFilter,
    state.medicalStatusFilter,
    state.caseStatusFilter,
    state.lawFirmFilter,
    state.startDateFilter,
    state.endDateFilter,
    state.orderingVal,
    state.paginationUrl,
    accessToken,
    dispatch,
    fetchAllFollowUpData,
  ]);

  const updateStartEndDates = (dateObj) => {
    setDates(dispatch, dateObj);
    refreshCalendar();
  };

  const updateFilters = (filterObj) => {
    setfilters(dispatch, filterObj);
    refreshCalendar();
  };

  const updateSearch = (searchStr) => {
    setSearch(dispatch, searchStr);
  };

  const updateActionTypes = (actionTypeArr) => {
    setActionTypes(dispatch, actionTypeArr);
  };

  const refreshCalendar = () => {
    setShouldFetchData(dispatch, true);
  };

  useDebouncedEffect(
    () => {
      if (state.search) {
        refreshCalendar();
      }
    },
    [state.search],
    800
  );

  return (
    <FilterProvider>
      <div className="flex flex-row">
        <Filters
          isDashboardVersion={true}
          followUpDataFromAPI={state.followUpData}
          setFilterState={updateFilters}
          updateActionTypes={updateActionTypes}
          refreshCalendar={refreshCalendar}
        />
        <div className="flex-1 px-4 py-4">
          <Search
            currentSearchValue={state.search}
            setSearchState={updateSearch}
          />
          <CalendarView
            followUpEvents={state.calendarEvents}
            dataIsLoading={state.loading}
            refreshCalendar={refreshCalendar}
            setDateFilterState={updateStartEndDates}
          />
        </div>
      </div>
    </FilterProvider>
  );
}

export default FCalendar;
