import { useCallback, useContext, useEffect, useState } from "react";
import { ButtonsConfig } from "../../../CaseFollowUps/components/utils/buttonsConfig";
import STRINGS from "../../../CaseFollowUps/components/utils/strings";
import DialogHeader from "../DialogHeader";
import FollowUpForm from "./FollowUpForm/FollowUpForm";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import DataContext from "../../../../../../../../../context/DataContext";
import { case_detail } from "../../../../../../../../api/endpoints/case";
import { SelectOptionsProCliStatusDataProcessor } from "../../../../../../../../global/FormComponents/SelectBasic/utils/SelectOptionsProCliStatusDataProcessor";
import { ACTION_TYPE } from "../../../CaseFollowUps/components/types/actionTypes";

const CreateFollowUp = ({ title, onClickSubmit, actionType, caseInfo, formIsTouched, navigateToDetail }) => {
  const { accessToken } = useContext(DataContext);
  const [proCliOptions, setProCliOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [getProCliOptions, setGetProCliOptions] = useState(true);

  const buttons = [ButtonsConfig.SubmitButtons.create];

  const createFieldOptions = useCallback(async (results) => {
    return SelectOptionsProCliStatusDataProcessor(results, "No Selection");
  }, []);

  const fetchFieldOptionsData = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorMessage(errArr);
      });
  }, []);

  useEffect(() => {
    let active = true;

    if (active) {
      if (!proCliOptions) {
        if (getProCliOptions) {
          const fieldOptionsUrls = [
            case_detail.getProvidersOnCase(caseInfo.caseId, accessToken),
          ];
          fetchFieldOptionsData(fieldOptionsUrls)
            .then(async (results) => {
              if (results) {
                if (results[0]) {
                  let options = await createFieldOptions(results[0].results);
                  setProCliOptions(options);
                }
              }
              return results;
            })
            .then((results) => {
              if (getProCliOptions) {
                setGetProCliOptions(false);
              }
              return results;
            })
            .catch((error) => {
              let errArr = ApiRequestErrorHandler(error.response);
              setErrorMessage(errArr);
            });
        }
      }
    }
    return () => {
      active = false;
    };
  }, [
    accessToken,
    getProCliOptions,
    proCliOptions,
    caseInfo.caseId,
    createFieldOptions,
    fetchFieldOptionsData,
  ]);

  const handleSubmit = (submitType, formData) => {
    let newFormData = {
      ...formData,
      action_type: actionType,
      on_case: caseInfo?.caseId,
      ...(actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP && caseInfo?.lawFirmId
        ? { on_firm: caseInfo.lawFirmId }
        : {}),
    };
    onClickSubmit(submitType, newFormData);
  };

  return (
    <div id="Follow-Up-Create" data-testid="Follow-Up-Create">
      <DialogHeader>{title}</DialogHeader>
      <FollowUpForm
        title={STRINGS.DIALOG_HEADER_CREATE_FOLLOWUP}
        selectedFollowUp={null}
        submitForm={handleSubmit}
        buttonsArr={buttons}
        isProviderFollowUp={actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP}
        isProviderEditable={true}
        providerOptions={proCliOptions}
        setGetProCliOptions={setGetProCliOptions}
        isFormTouched={formIsTouched}
      />
    </div>
  );
};

export default CreateFollowUp;
