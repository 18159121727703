import { createContext, useReducer } from "react";
import { followUpFormReducer } from "../state/followUpFormReducer";

export const FollowUpFormDispatchContext = createContext();
export const FollowUpFormStateContext = createContext();

const FollowUpFormProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(followUpFormReducer, initialState);

  return (
    <FollowUpFormDispatchContext.Provider value={dispatch}>
      <FollowUpFormStateContext.Provider value={state}>
        {children}
      </FollowUpFormStateContext.Provider>
    </FollowUpFormDispatchContext.Provider>
  );
};

export default FollowUpFormProvider;
