import React, { useContext, useState } from "react";
import CurrencyValueEdit from "../../../../../global/CurrencyValueEdit";
import DataContext from "../../../../../../context/DataContext";
import ReceiveCheck from "./ReceiveCheck/ReceiveCheckButton";
import ModalShell from "../../../../../global/ModalShell";
import AddCheck from "./ReceiveCheck/Modals/AddCheck";
// import axios from "../../../../../api/axios";
import SettlementValueEdit from "./SettlementValueEdit";

export default function SettlementAttorneyDetail({
  caseDetail,
  trigger,
  setTrigger,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [openAddCheck, setOpenAddCheck] = useState(false);
  // const [hasCheck, setHasCheck] = useState([]);
  const isDisabled = userRoles.permissions.includes("casemanager.change_case")
    ? false
    : true;
  const caseDetailPk = !caseDetail ? "" : caseDetail.pk;

  return (
    <div className="my-4">
      <h2 className="text-[1.2rem] font-bold">Settlement</h2>
      <CurrencyValueEdit
        title="Medical Amount"
        val={caseDetail.medical_amount}
        valKey="medical_amount"
        trigger={trigger}
        setTrigger={setTrigger}
        apiUrl={`/api/case/${caseDetailPk}/`}
        isDisabled={true}
      />
      <div className="flex justify-between w-full items-center">
        <SettlementValueEdit
          title="Settlement Amount"
          val={caseDetail.actual_total_settlement_amount}
          valKey="actual_total_settlement_amount"
          trigger={trigger}
          setTrigger={setTrigger}
          apiUrl={`/api/case/${caseDetailPk}/`}
          isDisabled={
            !userRoles.permissions.includes("casemanager.change_case") ||
            caseDetail.has_check
              ? true
              : false
          }
          setOpenAddCheck={setOpenAddCheck}
        />
        {userRoles.permissions.includes("casemanager.view_settlementcheck") ? (
          <ReceiveCheck
            detail={caseDetail}
            checkReceived={caseDetail.has_check}
            display={
              caseDetail.actual_total_settlement_amount || caseDetail.has_check
            }
          />
        ) : (
          ""
        )}
      </div>
      <div className="flex justify-between w-full items-center">
        <SettlementValueEdit
          title="Settlement Date"
          val={caseDetail.insurance_settlement_date}
          valKey="insurance_settlement_date"
          trigger={trigger}
          setTrigger={setTrigger}
          apiUrl={`/api/case/${caseDetailPk}/`}
          isDateEdit
          setOpenAddCheck={setOpenAddCheck}
        />
      </div>
      <h2 className="text-[1.2rem] font-bold">Attorney</h2>
      <CurrencyValueEdit
        title="Attorney's Fees"
        val={
          !caseDetail.lawyer_settlement_amount
            ? ""
            : caseDetail.lawyer_settlement_amount
        }
        valKey="lawyer_settlement_amount"
        trigger={trigger}
        setTrigger={setTrigger}
        apiUrl={`/api/case/${caseDetailPk}/`}
        isDisabled={isDisabled}
      />
      <CurrencyValueEdit
        title="Attorney's Costs"
        val={
          !caseDetail.lawyer_settlement_costs
            ? ""
            : caseDetail.lawyer_settlement_costs
        }
        valKey="lawyer_settlement_costs"
        trigger={trigger}
        setTrigger={setTrigger}
        apiUrl={`/api/case/${caseDetailPk}/`}
        isDisabled={isDisabled}
      />
      <div className="flex justify-between">
        <span className="block font-bold underline">
          Total Attorney's Reduction
        </span>
        <span className="block">
          {!caseDetail
            ? ""
            : !caseDetail.lawyer_total_costs
            ? "-"
            : `$${caseDetail.lawyer_total_costs}`}
        </span>
      </div>
      <ModalShell
        open={openAddCheck}
        setOpen={setOpenAddCheck}
        // customCloseFunc,
        color={"black"}
        title={"Add Law Firm Check"}
      >
        <AddCheck
          setOpenAddCheck={setOpenAddCheck}
          caseDetail={caseDetail}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      </ModalShell>
    </div>
  );
}
