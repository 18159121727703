import React from "react";

export default function LogListRow({ log, ActionIcon, actionGroupLabels, actionLabels }) {
  const baseListClasses = "text-center text-sm";

  return (
    <div className="flex my-2 justify-between w-full">
      <div className={`w-[5%] ${baseListClasses}`}>
        <ActionIcon action={log.crud_type} />
      </div>
      <div className={`w-[15%] ${baseListClasses}`}>
        {log.user ? log.user.name : "Unknown"}
      </div>
      <div className={`w-[15%] ${baseListClasses}`}>
        {log.related_case && log.related_case.client
          ? log.related_case.client.name
          : "-"}{" "}
      </div>
      <div className={`w-[15%] ${baseListClasses}`}>
        {" "}
        {log.timestamp
          ? new Date(log.timestamp).toLocaleString()
          : "Unknown"}{" "}
      </div>
      <div className={`w-[15%] ${baseListClasses}`}>
        {log.action_group &&
        actionGroupLabels &&
        actionGroupLabels[log.action_group]
          ? actionGroupLabels[log.action_group]
          : "-"}
      </div>
      <div className={`w-[15%] ${baseListClasses}`}>
        {log.action_label &&
        actionLabels
        ? log.action_label
       : "-"}
      </div>
    </div>
  );
}
