import { AddCircleOutline, InfoRounded } from "@mui/icons-material";
import { useContext } from "react";
import { FollowUpDispatchContext, FollowUpStateContext } from "../../context/FollowUpContext";
import {
  CONTENT_ADD_FOLLOWUP,
  CONTENT_FOLLOWUPLIST,
  setShowBackToFollowUpListButton,
  showContent,
  toggleDialog,
} from "../../state/actions";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "./InfoTooltip/TooltipStyling";
import { ACTION_TYPE } from "../types/actionTypes";
import STRINGS from "../utils/strings";

const Header = ({ id, icon, title, showAddButton, actionType }) => {
  const state = useContext(FollowUpStateContext);
  const dispatch = useContext(FollowUpDispatchContext);

  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  const showDialog = (value) => {
    showContent(dispatch, value);
    toggleDialog(dispatch, true);
  };

  return (
    <div id={id + "_Header"} data-testid={id + "_Header"} className="flex">
      <div className="w-[90%]">
        {icon} {title}
      </div>
      <div className="flex flex-row w-[10%]">
        <HtmlInfoTooltip title={"Add Follow Up"}>
          <>
            {showAddButton && (
              <AddCircleOutline
                className="text-green-500 hover:text-green-800"
                onClick={() => showDialog(CONTENT_ADD_FOLLOWUP)}
              />
            )}
          </>
        </HtmlInfoTooltip>
        {actionType === ACTION_TYPE.CLIENT_FOLLOW_UP &&
        <HtmlInfoTooltip title={`Display ${STRINGS.FOLLOW_UP_LIST_TITLE}`}>
          <InfoRounded
            className="text-slate-900 hover:text-slate-500"
            onClick={() => showDialog(CONTENT_FOLLOWUPLIST)}
          />
        </HtmlInfoTooltip>}
      </div>
    </div>
  );
};

export default Header;
