import {
  AddBusinessOutlined,
  CalendarMonthOutlined,
  Close,
  ContactPhoneOutlined,
  DashboardOutlined,
  Group,
  Info,
  InsertChartOutlined,
  LogoutOutlined,
  MenuOpenOutlined,
  PersonAddAltOutlined,
  PersonSearchOutlined,
  SettingsAccessibilityOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, IconButton, List, ListItem, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import DataContext from "../../context/DataContext";
import PatchInfo from "../global/PatchInfo";
import axios from "../api/axios";
import useForceLogout from "../hooks/forceLogout";
import HtmlTooltip from "../global/HtmlTooltip";
import CustomPopUpMenu from "../global/CustomPopUpMenu";
import PopupState, {bindHover} from "material-ui-popup-state";

export default function SideBar() {
  const { setAuth } = useContext(AuthContext);
  const {
    getAccessToken,
    accessToken,
    setCaseManagerPk,
    setSelectedProvider,
    selectedClient,
    setSelectedClient,
    setUserType,
    userRoles,
    setDashCompLimit,
    setErrorMessage,
    loggedInUser,
  } = useContext(DataContext);
  const [openPatchInfo, setOpenPatchInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useForceLogout(!userRoles ? true : false);

  const generateMenuData = () => {
    let menuArr = [];
    if (!userRoles.permissions) {
      return;
    } else {
      if (userRoles.permissions.includes("casemanager.view_case")) {
        menuArr.push(
          {
            title: "DASHBOARD",
            url: "dashboard",
            icon: <DashboardOutlined />,
            order: 1,
          },
          {
            title: "SEARCH CLIENTS",
            url: "search",
            icon: <PersonSearchOutlined />,
            order: 2,
          },
          {
            title: "CLIENT DETAIL",
            url: `client-detail/${!selectedClient ? null : selectedClient}`,
            icon: <SettingsAccessibilityOutlined />,
            order: 7,
          } //Folder
        );
      }
      if (userRoles.permissions.includes("casemanager.add_case")) {
        menuArr.push({
          title: "ADD CLIENT",
          url: "add-client",
          icon: <PersonAddAltOutlined />,
          order: 3,
        });
      }
      if (userRoles.permissions.includes("provider.add_providerclientstatus")) {
        menuArr.push({
          title: "PROVIDER MAP",
          url: "provider-map",
          icon: <AddBusinessOutlined />,
          order: 4,
        });
      }

      if (
        userRoles.permissions.includes("provider.view_appointment") ||
        userRoles.permissions.includes(
          "provider.view_appointments_related_to_treating_clients"
        ) ||
        userRoles.permissions.includes(
          "provider.view_appointments_related_to_assigned_clients"
        )
      ) {
        menuArr.push(
          {
            title: "APPT CALENDAR",
            url: "appointment-calendar",
            icon: <CalendarMonthOutlined />,
            order: 5,
          },
          {
            title: "ANALYTICS",
            url: "analytics",
            icon: <InsertChartOutlined />,
            order: 6,
          }
        );
      }
    }
    return menuArr;
  };

  const Menus = !userRoles.permissions ? [] : generateMenuData();

  const generateUserMenuData = () => {

      let userMenuArr = [];
  
      if(userRoles.permissions.includes("users.add_user"))
      {
          userMenuArr.push(
            {
              icon:<PersonAddAltOutlined className="text-black"/>,
              title:"Add User",
              url:"/add-application-user"
            }
          );
      }
      if(userRoles.permissions.includes("users.view_user"))
      {
        userMenuArr.push(
          {
            icon:<Group className="text-black"/>,
            title:"View/Manage Users",
            url:"/user-profiles"
          },
          {
            icon:<ContactPhoneOutlined className="text-black"/>,
            title:"Contacts",
            url:"/contact"
          }
        );
      }
      if(userRoles.permissions.includes("lawyer.add_lawfirm"))
      {
        userMenuArr.push(
          {
            icon:<AddBusinessOutlined className="text-black"/>,
            title:"Add Law Firm",
            url:"/create-law-firm"
          }
        );
      }
      return userMenuArr;
    };

  const logout = () => {
    axios
      .post("/auth/logout", null, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setAuth(null);
          navigate("/");
          Cookies.remove("pnAccessToken");
          Cookies.remove("pnCmPk");
          Cookies.remove("userType");
          Cookies.remove("category");
          Cookies.remove("token_exp");
          getAccessToken("");
          setCaseManagerPk("");
          setSelectedProvider("");
          setSelectedClient("");
          setUserType("");
          setDashCompLimit(5);
          setErrorMessage("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="fixed bg-white z-10 shadow-lg w-full block sm:hidden flex items-center justify-between">
        <div className="w-1/3">
          <IconButton
            className="block pl-2 w-[100px] text-indigo-500"
            onClick={() => setOpen(!open)}
          >
            {!open ? (
              <MenuIcon style={{ fontSize: "40px" }} />
            ) : (
              <Close style={{ fontSize: "40px" }} />
            )}
          </IconButton>
        </div>
        <div className="md:hidden block w-1/3">
          <img
            alt="NetProApp"
            src="/assets/NetProApp_color_rebrand.jpg"
            className={`cursor-pointer duration-500 block mx-auto my-2`}
          />
        </div>
        <div className="w-1/3">&nbsp;</div>
      </div>
      <div className="bg-white flex fixed z-50 shadow-lg">
        <IconButton
          className="block pl-2 absolute m-4 z-10 text-indigo-500 sm:hidden"
          onClick={() => setOpen(!open)}
        >
          {!open ? (
            ""
          ) : (
            <Close className="md:text-[40px] text-[30px] self-center" />
          )}
        </IconButton>
        <div
          className={`w-0 ${
            open ? "sm:w-64" : "sm:w-20"
          }  h-screen sm:p-2 relative duration-300 bg-white`}
        >
          <div
            className={`sm:flex flex-col justify-between h-full ${
              open
                ? "w-[100vw] sm:w-64 h-[100vh] bg-white overflow-y-auto border-r"
                : "hidden"
            }`}
          >
            <button
              className="sm:hidden block pl-4 absolute w-100 mt-4 left-0"
              onClick={() => setOpen(!open)}
            >
              X
            </button>
            <div className="bg-white relative">
              <Button
                className={`hidden cursor-pointer relative sm:block 
              text-indigo-500 p-1 rounded-sm ml-2 my-2 hover:bg-indigo-500 hover:text-white transition-all ${
                !open && "rotate-180"
              }`}
                onClick={() => setOpen(!open)}
              >
                <MenuOpenOutlined style={{ fontSize: "32px" }} />
              </Button>
              <div className={`${open ? "mx-4" : "mx-2"}`}>
                {!open ? (
                  <img
                    alt="NetProApp"
                    src="/assets/apple-touch-icon.png"
                    className={`cursor-pointer duration-500 ease block max-w-full w-[100px] transition-all mx-auto mt-4`}
                  />
                ) : (
                  <img
                    alt="NetProApp"
                    src="/assets/NetProApp_color_rebrand.jpg"
                    className={`cursor-pointer duration-500 ease block max-w-full w-[250px] transition-all mx-auto mt-4`}
                  />
                )}
                {/* <img
                  alt=""
                  src="/assets/Progression_Logo.svg"
                  className={`cursor-pointer duration-500 ease block max-w-full w-[100px] transition-all mx-auto mt-4`}
                /> */}
              </div>
            </div>
            <List className="pt-0 bg-white sm:bg-white/[0]">
              {!Menus || !userRoles.permissions
                ? ""
                : Menus.sort((a, b) => a.order - b.order).map((Menu, index) =>
                    Menu === "" ? (
                      ""
                    ) : (
                      <ListItem
                        key={index}
                        className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-lg text-black-300 text-sm items-center gap-x-4 
                    ${Menu.gap ? "sm:mt-20" : "mt-2"} ${
                          index === 0 && "bg-light-white"
                        } `}
                      >
                        <Tooltip title={Menu.title} placement="right">
                          <Link
                            className="flex gap-6 items-center px-4 rounded-md hover:bg-indigo-500 hover:text-white w-full"
                            to={`${Menu.url}`}
                            onClick={() => setOpen(false)}
                          >
                            {Menu.icon}
                            <span
                              className={`${
                                !open && "hidden"
                              } pl-2 origin-left duration-200`}
                            >
                              {Menu.title}
                            </span>
                          </Link>
                        </Tooltip>
                      </ListItem>
                    )
                  )}
              {userRoles.permissions.includes(
                "utilities.view_softwareupdate"
              ) ? (
                <ListItem
                  className={`flex rounded-md cursor-pointer hover:bg-light-white text-lg text-black-300 text-sm items-center p-0 
                                mt-2 relative`}
                >
                  <Tooltip title="Open Info" placement="right">
                    <IconButton
                      className="flex gap-6 items-center justify-start px-4 rounded-md hover:bg-indigo-500 hover:text-white w-full text-black pl-[23px] relative"
                      onClick={() => setOpenPatchInfo(!openPatchInfo)}
                    >
                      <Info />
                      <span
                        className={`${
                          !open && "hidden"
                        } pl-2 origin-left duration-200 text-[14px]`}
                      >
                        PATCH INFO
                      </span>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ) : (
                ""
              )}
            </List>
            <List className="bg-white">
              {userRoles.permissions.includes("users.view_user") ? (
                <ListItem
                  className={`${open? 'px-2':''} flex rounded-md p-0 cursor-pointer hover:bg-light-white text-lg text-black-300 text-sm items-center`}
                >
                  <PopupState variant="popover" popupId = "user-menu-popup">
                    {
                      (popupState)=>(
                        <div className = {`${open?'w-full':'w-fit'} flex items-center justify-stretch rounded-md cursor-pointer`}>
                          <Button 
                            variant={open?"contained":""} 
                            {...bindHover(popupState)} 
                            className={`${open?'gap-6 justify-start':'justify-center'} capitalize bg-inherit flex items-center rounded-md text-black hover:bg-indigo-500 hover:text-white relative w-full`}
                            disableElevation={true}>
                            <Group/>
                            <span
                            className={`${
                              !open && "hidden"
                            } origin-left duration-200`}
                            >
                            Users
                            </span>
                          </Button>
                          <CustomPopUpMenu menuItems={generateUserMenuData()} popupState={popupState}/>
                        </div>
                      )
                    }
                  </PopupState>
                </ListItem>
              ) : (
                ""
              )}
              <ListItem
                className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-lg text-black-300 text-sm items-center gap-x-4`}
              >
                <Tooltip title="My Account" placement="right">
                  <Link
                    className="flex gap-2 items-center p-0 rounded-md hover:bg-indigo-500 hover:text-white w-fit px-2"
                    to={`/my-account`}
                    onClick={() => setOpen(false)}
                  >
                    <div className="rounded-full overflow-hidden max-w-[35px] mx-auto relative shadow-md">
                      <img
                        src={
                          !loggedInUser.profile_picture
                            ? "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                            : loggedInUser.profile_picture
                        }
                        alt=""
                      />
                    </div>
                    <span
                      className={`${
                        !open && "hidden"
                      } pl-2 origin-left duration-200`}
                    >
                      My Account
                    </span>
                  </Link>
                </Tooltip>
              </ListItem>
              <ListItem>
                <button onClick={logout} className="pl-2 mt-2 w-full bg-white">
                  <div className="flex gap-6 px-auto items-center rounded-md hover:bg-red-600 hover:text-white text-red-600">
                    <LogoutOutlined />
                    <span
                      className={`${
                        !open && "hidden"
                      } pl-2 origin-left duration-200`}
                    >
                      LOGOUT
                    </span>
                  </div>
                </button>
              </ListItem>
            </List>
          </div>
        </div>
        {!openPatchInfo ? (
          ""
        ) : (
          <PatchInfo
            open={open}
            openPatchInfo={openPatchInfo}
            setOpenPatchInfo={setOpenPatchInfo}
          />
        )}
      </div>
    </>
  );
}
