import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import AlternateContactRow from "../components/AlternateContactRow";
import CaseSummaryContext from "../../CaseSummaryV2/context/CaseSummaryContext";
import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ContactControlsNoStyling from "../../../../../../global/ContactControlsNoStyling";
import Colors from "../../../../../../global/Colors";
import ShowMoreButton from "../../../../../../global/ShowMoreButton";

export default function AlternateContacts({
  caseObj,
  editPermissions,
  deletePermissions,
}) {
  const { accessToken } = useContext(DataContext);
  const { caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const [contactLabels, setContactLabels] = useState("");
  const [contactData, setContactData] = useState([]);
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  // /api/user_contact/
  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      axios
        .get(
          `/api/user_contact/?user=${caseObj.client.pk}&ordering=contact_type&limit=${resLimit}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          const data = response.data.results;
          setContactData(data);
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }, [setContactData, accessToken, caseObj, caseSummaryTrigger, resLimit]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/usercontactinfo/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setContactLabels(data);
      })
      .catch((err) => {
        return err;
      });
  }, [setContactLabels, accessToken]);

  return (
    <div className="pr-2 pl-4 py-4 shadow-md rounded-md">
      <h2 className="text-lg font-bold text-gray-600">Alternate Contact</h2>
      <div className="max-h-[30vh] overflow-y-auto">
        {contactData &&
        contactLabels &&
        contactData.filter(
          (item) =>
            contactLabels["contact_label"][item.contact_label] !== "Preferred"
        ).length &&
        contactLabels ? (
          contactData
            .filter(
              (item) =>
                contactLabels["contact_label"][item.contact_label] !==
                "Preferred"
            )
            .map((item) => (
              <div className="flex items-center w-full">
                <AlternateContactRow
                  key={item.id}
                  title={contactLabels["contact_type"][item.contact_type]}
                  contactLabel={
                    contactLabels["contact_label"][item.contact_label]
                  }
                  type={"text"}
                  keyVal={"contact_label"}
                  typeKeyVal={"display"}
                  apiUrl={`/api/user_contact/${item.id}/`}
                  optionsUrl={`/api/field_options/?content_type=usercontactinfo&field_name=contact_label&ordering=order&`}
                  trigger={caseSummaryTrigger}
                  setTrigger={setCaseSummaryTrigger}
                  valueHistory={null}
                  displayDetail={item}
                  valueLabels={contactLabels}
                  disabled={editPermissions ? false : true}
                  nullable={false}
                  deleteDisabled={deletePermissions ? false : true}
                />
                {contactLabels["contact_type"][item.contact_type] ===
                "Phone" ? (
                  <div>
                    {item.display && (
                      <ContactControlsNoStyling
                        number={item.display}
                        buttonWidth={"40px"}
                        buttonHeight={"30px"}
                        customIconSize={"20px"}
                        smsTitle={"Text Client"}
                        smsIconColor={Colors.sky[500]}
                        smsBackgroundColor={Colors.white}
                        phoneTitle={"Call Client"}
                        phoneIconColor={Colors.green[500]}
                        phoneBackgroundColor={Colors.white}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
        ) : (
          <div>
            <p className="text-center">
              No alternate contacts found{" "}
              <Tooltip title="Update primary contacts above to create and manage additional contacts">
                <Info />
              </Tooltip>
            </p>
          </div>
        )}
        <ShowMoreButton
          resLimit={resLimit}
          showMoreAmt={10}
          showMoreVisible={showMoreVisible}
          setResLimit={setResLimit}
        />
      </div>
    </div>
  );
}
