import { Check, Close, Edit } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ResponseModal from "../../../../../global/ResponseModal";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";

export default function SettlementValueEdit({
  title,
  val,
  valKey,
  trigger,
  setTrigger,
  apiUrl,
  isDisabled,
  isDateEdit = false
}) {
  const { accessToken } = useContext(DataContext);
  const [newVal, setNewVal] = useState(val || null);
  const [editActive, setEditActive] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleOpenSuccess = () => {
    setTrigger(!trigger);
    setOpenSuccess(!openSuccess);
    setEditActive(false);
  };

  const updateVal = () => {
    let data = null;
    if (newVal) data = !isDateEdit ? Number(newVal) : newVal;
    let patchedData = { [valKey]: data };

    axios
      .patch(apiUrl, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setEditActive(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
      });
  };

  const handleExitEdit = () => {
    setEditActive(false);
    setNewVal(val);
  };

  const generateViewVal = () => {
    if (!newVal) {
      return (
        <div>-</div>
      );
    } else if (!isDateEdit) {
      return (
        <div>
          <span className="pr-2">$</span>
          {newVal}
        </div>
      );
    } else {
      return (
        <div>{new Date(newVal).toLocaleDateString("en-US")}</div>
      )
    }
  }

  const generateEditVal = () => {
    let editInput = null;
    if (!isDateEdit) {
      editInput = (
        <Input
          id="standard-adornment-amount"
          value={newVal}
          type="number"
          onChange={(e) => setNewVal(e.target.value)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          endAdornment={
            <InputAdornment position="end" className="flex items-center">
              <Tooltip title="Submit">
                <IconButton
                  className="text-green-600"
                  variant="outlined"
                  onClick={updateVal}
                >
                  <Check />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className="text-red-600"
                  variant="outlined"
                  onClick={handleExitEdit}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      )
    } else {
      editInput = (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={title}
              value={newVal ? parseISO(newVal) : null}
              onChange={(newValue) => {
                console.log('newValue', newValue)
                setNewVal(newValue ? format(new Date(newValue), "yyyy-MM-dd") : null)
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  error: false,
                  InputLabelProps: { shrink: true }
                },
                actionBar: { actions: ['clear'] },
              }}
            />
          </LocalizationProvider>
          <div className="flex items-center absolute bottom-0.5 right-7 h-[32px] bg-white">
            <Tooltip title="Submit">
              <IconButton
                className="text-green-600"
                variant="outlined"
                onClick={updateVal}
              >
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                className="text-red-600"
                variant="outlined"
                onClick={handleExitEdit}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )
    }

    return (
      <FormControl fullWidth sx={{ m: 1 }} variant="standard">
        {!isDateEdit && <InputLabel htmlFor="standard-adornment-amount">{title}</InputLabel>}
        {editInput}
      </FormControl>
    )
  }

  return (
    <div className="my-2 w-full">
      {!editActive ? (
        <div className="flex justify-between">
          <div>
            <InputLabel>{title}</InputLabel>
            {generateViewVal()}
          </div>
          <div className="self-end">
            {" "}
            <IconButton
              className="text-black"
              onClick={() => setEditActive(!editActive)}
              disabled={isDisabled}
            >
              <Tooltip title={`Edit ${title}`}>
                <Edit />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      ) : generateEditVal()}
      <ResponseModal
        title={`${title} Update Successful`}
        description="Case information has been updated"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title={`${title} Update Failed`}
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
