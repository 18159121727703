import {
  CONTENT_LOADING_TOGGLE,
  DIALOG_IS_TOUCHED,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  SET_ACTION_TYPES,
  SET_CALENDAR_EVENTS,
  SET_CASE_INFO,
  SET_DATES,
  SET_FILTERS,
  SET_SEARCH,
  SET_SELECTED_FOLLOWUP,
  SET_SHOW_ADD_BUTTON,
  SHOULD_FETCH_DATA,
  SHOW_BACK_TO_FOLLOWUPLIST_BUTTON,
  SHOW_CONTENT_SET,
  TOGGLE_DIALOG,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  data: null,
  calendarEvents:null, 
  search: null,
  filters: null,
  resLimit: 25,
  currentPage: 1,
  paginationUrl: null,
  resultCount: { total: "", current: "" },
  orderingVal: null,
  actionTypes: "2,3,4",
  managedUserFilter:[],
  providerFilter: [],
  followUpFilter:[],
  medicalStatusFilter: [],
  caseStatusFilter: [],
  lawFirmFilter: [],
  startDateFilter: new Date().toISOString().substring(0, 10),
  endDateFilter: new Date().toISOString().substring(0, 10),
  caseInfo: null,
  selectedFollowUp: null,
  provider_name: null,
  showComponent: null,
  showBackToFollowUpListButton: false,
  showContent: "",
  prevContent:"",
  showDialog: false,
  dialogContentIsLoading: true,
  dialogIsLoading: false,
  dialogIsTouched:false,
};

export function calendarReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case SET_FILTERS: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.data,
      };
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case SET_ACTION_TYPES: {
      const actionTypesList = action.payload.join(',');
      return { ...state, actionTypes: actionTypesList  };
    }
    case SET_CALENDAR_EVENTS: {
      return { ...state, calendarEvents: action.payload };
    }
    case TOGGLE_DIALOG: {
      return { ...state, showDialog: action.payload };
    }
    case SHOW_BACK_TO_FOLLOWUPLIST_BUTTON: {
      return { ...state, showBackToFollowUpListButton: action.payload };
    }
    case SHOW_CONTENT_SET: {
      let temp = state.showContent;
      return { ...state, showContent: action.payload, prevContent: temp };
    }
    case CONTENT_LOADING_TOGGLE: {
      return { ...state, dialogContentIsLoading: action.payload };
    }
    case SET_SELECTED_FOLLOWUP: {
      return { ...state, selectedFollowUp: action.payload };
    }
    case SET_CASE_INFO: {
      return { ...state, caseInfo: action.payload };
    }
    case SET_SHOW_ADD_BUTTON: {
      return { ...state, showAddButton: action.payload };
    }
    case DIALOG_IS_TOUCHED: {
      return { ...state, dialogIsTouched: action.payload };
    }
    case SET_DATES: {
      return { ...state, startDateFilter: action.payload.startDate, endDateFilter: action.payload.endDate };
    }
    default:
      return state;
  }
}
