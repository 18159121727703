import Colors from "../../../../../../../../global/Colors";

const IndicatorColors = {
  none: Colors.white,
  green: Colors.green[500],
  yellow: Colors.yellow[500],
  red: Colors.red[500],
  black: Colors.black,
  orange: Colors.orange[500],
  grey: Colors.slate[500],
};

export default IndicatorColors;
