import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Info } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ResponseModal from "../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../global/ApiRequestErrorHandler";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import ShowMoreButton from "../../../global/ShowMoreButton";
import SmartyAutoComplete from "../../../global/SmartyAutoComplete";

export default function CreateLawfirm() {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [creationForm, setCreationForm] = useState("NewLFInfo");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(new Date());
  const [address, setAddress] = useState("");
  const [lawFirmName, setLawFirmName] = useState("");
  const [lawFirmAddress, setLawFirmAddress] = useState("");
  const [lawFirmEmail, setLawFirmEmail] = useState("");
  const [lawFirmPhone, setLawFirmPhone] = useState("");
  const [lawFirmUrl, setLawFirmUrl] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [selectedPerms, setSelectedPerms] = useState([]);
  const [selectedPermsArr, setSelectedPermsArr] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedEntitiesArr, setSelectedEntitiesArr] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  // const componentRef = useRef();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useDebouncedEffect(
    () => {
      const apiUrl = "/api/entities/?is_related_to_law_firm=true";

      axios
        .get(`${apiUrl}&ordering=name&${resLimit}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
          setEntityOptions(data);
        });
    },
    [accessToken, setEntityOptions],
    250
  );

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `NetProApp - ${name} - Login Information`,
  // });

  // const printInfo = () => {
  //   handlePrint();
  // };

  const handleOpenSuccess = () => {
    setOpenSuccess(false);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setDob("");
    setLawFirmUrl("");
    setLawFirmName("");
    setLawFirmEmail("");
    setLawFirmPhone("");
    setLawFirmAddress("");
    setSelectedPerms([]);
    setSelectedPermsArr([]);
    setSelectedEntities([]);
    setSelectedEntitiesArr([]);
    setCreationForm("NewLFInfo");
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPerms(value);
    let newPermArr = [];
    value.forEach((element) => {
      newPermArr.push(element.group);
    });
    setSelectedPermsArr(newPermArr);
  };

  const handleEntitiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEntities(value);
    let newEntityArr = [];
    value.forEach((element) => {
      newEntityArr.push(element.pk);
    });
    setSelectedEntitiesArr(newEntityArr);
  };

  const handlePostLawFirm = () => {
    let lawFirmData = {
      law_firm: {
        name: lawFirmName,
        address: !lawFirmAddress ? null : lawFirmAddress,
        phone: lawFirmPhone,
        email: lawFirmEmail,
        homepage_url: lawFirmUrl,
      },

      law_firm_managed_by: {
        name: name,
        email: email,
        phone: phone,
        date_of_birth: new Date(dob).toLocaleDateString("en-CA"),
        address: !address ? null : address,
      },
      permission_sets: selectedPermsArr,
      case_manager_entities: selectedEntitiesArr,
    };

    axios
      .post("/api/lawfirm/", lawFirmData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(true);
          // setNewUserName(response.data.law_firm_managed_by.generated_username);
          // setNewUserPswd(response.data.law_firm_managed_by.generated_password);
        }
      })
      .catch(function (error) {
        setOpenError(true);
        let errArr = ApiRequestErrorHandler(error.response.data);
        setErrorMessage(errArr);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="bg-white justify-items-center mx-2 md:mx-auto md:w-[80%]">
        <h1
          className={`text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2 bg-white z-10`}
        >
          Law Firm Form
        </h1>
        <div className="mt-14 mx-auto  px-6">
          <form className="">
            <div className="text-center mb-6">
              {creationForm === "NewLFAdminInfo" ? (
                <div>
                  <Button
                    onClick={(e) => {
                      setCreationForm("NewLFInfo");
                    }}
                    className="hover:text-white hover:bg-gray-600 text-gray-600 rounded-md flex w-full justify-center border-solid border-gray-600 border w-fit px-8 py-1 m-auto"
                  >
                    <ArrowBackIcon /> Previous Page
                  </Button>
                  <h2 className="text-xl font-bold pt-4">
                    Law Firm Admin Information
                  </h2>
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Name (First & Last)"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Email"
                    placeholder="ex. email@email.com"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Phone"
                    placeholder="ex. 404-444-4444"
                    value={phone}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                  <DesktopDatePicker
                    className="w-full my-4 text-center"
                    label="Date of Birth"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                      // setDob(new Date(newValue).toLocaleDateString('en-US', {timeZone: 'UTC'}));
                    }}
                    slotProps={{ textField: { variant: "outlined" } }}
                  />
                  <FormControl sx={{ my: 2, width: "100%" }} required={true}>
                    <InputLabel id="perm-options">Permissions</InputLabel>
                    <Select
                      labelId="perm-options"
                      id="permissions-options-select"
                      multiple
                      value={selectedPerms}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={() =>
                        selectedPerms.map((item) => item.name + ",")
                      }
                      MenuProps={MenuProps}
                    >
                      {!loggedInUser
                        ? ""
                        : loggedInUser.permission_sets
                            .filter((perm) => perm.shareable === true)
                            .map((perm) => (
                              <MenuItem key={perm.group} value={perm}>
                                <Checkbox
                                  checked={selectedPermsArr.includes(
                                    perm.group
                                  )}
                                />
                                <ListItemText primary={perm.name} />
                                <Tooltip
                                  title={perm.description}
                                  placement="right"
                                >
                                  <Info className="text-gray-400" />
                                </Tooltip>
                              </MenuItem>
                            ))}
                    </Select>
                  </FormControl>
                  <SmartyAutoComplete
                    address={address}
                    setAddress={setAddress}
                  />
                  <Button
                    variant="outlined"
                    className="block mx-auto"
                    onClick={(e) => {
                      handlePostLawFirm();
                    }}
                  >
                    Create Law Firm
                  </Button>
                </div>
              ) : (
                <div className="overflow-auto">
                  <h2 className="text-xl font-bold">Law Firm Information</h2>

                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Name"
                    value={lawFirmName}
                    onChange={(event) => {
                      setLawFirmName(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Website URL"
                    value={lawFirmUrl}
                    onChange={(event) => {
                      setLawFirmUrl(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Email"
                    placeholder="ex. email@email.com"
                    value={lawFirmEmail}
                    onChange={(event) => {
                      setLawFirmEmail(event.target.value);
                    }}
                  />
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Phone"
                    placeholder="ex. 404-444-4444"
                    value={lawFirmPhone}
                    onChange={(event) => {
                      setLawFirmPhone(event.target.value);
                    }}
                  />
                  <FormControl sx={{ my: 2, width: "100%" }} required={true}>
                    <InputLabel id="entity-options">Entities</InputLabel>
                    <Select
                      labelId="entity-options"
                      id="entity-options-select"
                      multiple
                      value={selectedEntities}
                      onChange={handleEntitiesChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={() =>
                        selectedEntities.map((item) => item.name + ",")
                      }
                      MenuProps={MenuProps}
                    >
                      {!entityOptions || entityOptions.length === 0
                        ? ""
                        : entityOptions.map((entity) => (
                            <MenuItem key={entity.pk} value={entity}>
                              <Checkbox
                                checked={selectedEntitiesArr.includes(
                                  entity.pk
                                )}
                              />
                              <ListItemText primary={entity.name} />
                              <Tooltip
                                title={
                                  entity.entity_type_label
                                    ? entity.entity_type_label
                                    : "Unknown"
                                }
                                placement="right"
                              >
                                <Info className="text-gray-400" />
                              </Tooltip>
                            </MenuItem>
                          ))}
                      <ShowMoreButton
                        showMoreVisible={showMoreVisible}
                        setResLimit={setResLimit}
                        resLimit={resLimit}
                        showMoreAmt={5}
                      />
                    </Select>
                  </FormControl>
                  <SmartyAutoComplete
                    address={lawFirmAddress}
                    setAddress={setLawFirmAddress}
                    inputRequired={true}
                  />
                  <div className="mb-6">
                    <Button
                      onClick={(e) => {
                        setCreationForm("NewLFAdminInfo");
                      }}
                      className="hover:text-white hover:bg-green-500 text-green-500 rounded-md flex justify-center border-solid border-green-500 border w-fit px-8 py-1 m-auto"
                    >
                      Next Page <ArrowForwardIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Create Law Firm Success</AlertTitle>
            <p>
              The Law Firm and Law Firm Admin User were successfully created. An
              email will be sent to the Law Firm Admin email entered.
            </p>
            <p>
              {" "}
              <span style={{ color: "red" }}>
                **If no email was entered for the Law Firm Admin, please contact
                a ITN admin to redeem.
              </span>
            </p>
            {/* <div className="m-4">
              <AccordionShell title="Law Firm Admin Login Details">
                <div
                  ref={componentRef}
                  style={{
                    margin: "0 2rem",
                    fontSize: "1rem",
                    color: "",
                    // position: "absolute",
                    // left: "-8000px",
                  }}
                >
                  <div style={{ maxWidth: "50%", margin: "auto" }}>
                    {" "}
                    <img
                      alt="NetProApp"
                      src="/assets/NetProApp_color_rebrand.jpg"
                    />
                  </div>
                  <ul style={{ margin: "1rem auto", listStyle: "none" }}>
                    <li style={{ textAlign: "center", marginBottom: "5px" }}>
                      <strong>{name}</strong>
                    </li>
                    <li style={{ textAlign: "center", marginBottom: "5px" }}>
                      <strong>{phone}</strong>
                    </li>
                    <li style={{ textAlign: "center", marginBottom: "5px" }}>
                      <strong>{email}</strong>
                    </li>
                    <li style={{ textAlign: "center", marginBottom: "5px" }}>
                      <strong>Username:</strong> {newUserName}
                    </li>
                    <li style={{ textAlign: "center", marginBottom: "5px" }}>
                      <strong>Temporary Password:</strong> {newUserPswd}
                    </li>
                  </ul>
                  <p style={{ textAlign: "center" }}>
                    The password above is only necessary for your first login.
                    You will be prompted to create a custom password on your
                    first login attempt.{" "}
                  </p>
                </div>
              </AccordionShell>
            </div> */}
          </Alert>

          {/* <Button
              variant="outlined"
              color="success"
              onClick={() => {
                printInfo();
              }}
              style={{ width: "50%" }}
            >
              {" "}
              Print
            </Button> */}
          <Button
            onClick={() => {
              handleOpenSuccess();
            }}
            className="w-full"
            variant="outlined"
          >
            Close
          </Button>
        </Dialog>
      </div>
      <ResponseModal
        title="Error adding new Law Firm"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </LocalizationProvider>
  );
}
