import { useEffect } from "react";

export default function useToggleDropdown(isDropdown, ref, onClick){
    
    useEffect(()=>{
        if(isDropdown)
        {
            const handleClickOutside = (event) => {
                if(ref.current && !ref.current.contains(event.target))
                {
                    onClick();
                }
            };

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        }
    }, [ref, onClick]);
}