import STRINGS from "../utils/strings";

const emptyString = STRINGS.EMPTY;

const nameValue = (value) => {
  if (!value && value === null) {
    return emptyString;
  }
  return value;
};

const NameModel = (name) => {
  return nameValue(name);
};
export default NameModel;
