import MultiSelectSearchV3 from "./MultiSelectSearchV3";
import { ListItem } from "@mui/material";

export default function ProviderFilter({
  selectedItems,
  setSelectedItems,
  setActiveItems,
  activeItems,
  resetPagination,
  setFilterState,
}) {

  const setSelectedState = (selectedValues) => {
    setSelectedItems(selectedValues);
    setFilterState({ fieldName: "providerFilter", data: selectedValues });
  };

  return (
    <ListItem>
      <div className="shadow p-2 w-full">
        <MultiSelectSearchV3
          title="Filter by Provider"
          searchTitle="Provider Search"
          selectedItems={selectedItems}
          setSelectedItems={setSelectedState}
          setActiveItems={setActiveItems}
          activeItems={activeItems}
          resetPagination={resetPagination}
          nameKey={"name"}
          valueKey={"pk"}
          apiUrl={`/api/provider/?`}
          buttonFullW={true}
          dropdown={false}
        />
      </div>
    </ListItem>
  );
}
