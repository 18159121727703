import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDebouncedEffect } from "../../../../../../../../hooks/useDebounceEffect";
import DataContext from "../../../../../../../../../context/DataContext";
import ShowMoreButtonAutoComp from "./ShowMoreButtonAutoComp";
import axios from "../../../../../../../../api/axios";

export default function AutoCompleteSearch({
  apiUrl,
  title,
  setVal,
  displayKey,
  activeEditObj,
  customLimit,
  permissions,
  trigger,
  required,
  customSize,
  isDisabled,
  disabledReason,
  optionsDisplayKey,
  error,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(customLimit ? customLimit : 25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes(
          !permissions ? "utilities.view_fieldoptions" : permissions
        )
      ) {
        axios
          .get(
            apiUrl +
              `&limit=${resLimit}` +
              `${search ? `&search=${search}` : ""}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(response.data.results);
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        return;
      }
    },
    [userRoles.permissions, accessToken, setOptions, search, resLimit, trigger],
    250
  );

  const labelKey = optionsDisplayKey ? optionsDisplayKey : displayKey;

  return (
    <Tooltip title={disabledReason && isDisabled ? disabledReason : ""}>
      <Autocomplete
        className="w-full"
        disablePortal
        freeSolo
        size={customSize ? customSize : "small"}
        clearOnBlur={true}
        options={!options ? [] : options}
        getOptionLabel={(option) => {
          return !option[labelKey] ? "" : option[labelKey];
        }}
        noOptionsText="No Results"
        onChange={(e, value) => {
          setVal(value);
          setSearch("");
        }}
        disabled={isDisabled ? true : false}
        defaultValue={!activeEditObj ? "" : activeEditObj}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              textAlign: "left",
              borderBottom: "1px solid #EBEBEB",
              fontSize: "14px",
            }}
            id={option[labelKey]}
            key={option[labelKey] + " " + Math.random()}
          >
            {option[labelKey]}
          </li>
        )}
        renderInput={(params) => (
          <div className="flex items-center">
            <TextField
              {...params}
              label={title}
              onChange={(e) => setSearch(e.target.value)}
              required={!required ? false : true}
              error={error}
            />
            <ShowMoreButtonAutoComp
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={10}
              customTitle={title}
            />
          </div>
        )}
      />
    </Tooltip>
  );
}
