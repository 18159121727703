export const SET_FORM_IS_INITIALIZED = "SET_FORM_IS_INITIALIZED";
export const SET_FORM_IS_TOUCHED = "SET_FORM_IS_TOUCHED";
export const SET_FORM_IS_VALID = "SET_FORM_IS_VALID";

export const SET_FIELD_IS_TOUCHED = "SET_FIELD_IS_TOUCHED";
export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const SET_FIELD_IS_VALID = "SET_FIELD_IS_VALID";

export const setFormIsInitialized = (dispatch, bool) =>
  dispatch({ type: SET_FORM_IS_INITIALIZED, payload: bool });

export const setFieldData = (dispatch, obj) =>
  dispatch({ type: SET_FIELD_DATA, payload: obj });

export const setFieldIsTouched = (dispatch, bool) =>
  dispatch({ type: SET_FIELD_IS_TOUCHED, payload: bool });

export const setFieldIsValid = (dispatch, bool) =>
  dispatch({ type: SET_FIELD_IS_VALID, payload: bool });

export const setFormIsTouched = (dispatch, bool) =>
  dispatch({ type: SET_FORM_IS_TOUCHED, payload: bool });

export const setFormIsValid = (dispatch, bool) =>
  dispatch({ type: SET_FORM_IS_VALID, payload: bool });
