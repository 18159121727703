import React, { useContext, useEffect, useState, useRef } from "react";
import { Add, Remove, Sort } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import ShowMoreButton from "../../../../../../../../../../global/ShowMoreButton";
import axios from "../../../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../../../context/DataContext";
import { useDebouncedEffect } from "../../../../../../../../../../hooks/useDebounceEffect";
import useToggleDropdown from "../../../../../../../../../../hooks/useToggleDropdown";


export default function MultiSelectSearchV3({
  title,
  searchTitle,
  selectedItems,
  setActiveItems,
  activeItems,
  setSelectedItems,
  nameKey,
  valueKey,
  resetPagination,
  apiUrl,
  searchKey,
  orderingVal,
  buttonFullW,
  dropdown,

}) {
  const { accessToken } = useContext(DataContext);
  const [showForm, setShowForm] = useState(false);
  const [resLimit, setResLimit] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [loadingRes, setLoadingRes] = useState(false);
  const [selectedObjArr, setSelectedObjArr] = useState([]);
  const accordionRef = useRef(null);

  useToggleDropdown(dropdown, accordionRef, () => setShowForm(false));

  useDebouncedEffect(
    () => {
      if (!apiUrl || !showForm) {
        return;
      } else {
        setLoadingRes(true);
        let searchQuery = !searchVal
          ? ""
          : `&${!searchKey ? "search" : searchKey}=${searchVal}`;
        axios
          .get(
            `${apiUrl}limit=${resLimit}&ordering=${
              !orderingVal ? "name" : orderingVal
            }${searchQuery}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
            setResponseArray(data);
            setLoadingRes(false);
          });
      }
    },
    [resLimit, searchVal, showForm],
    250
  );

  const handleSelectedItem = (event, info) => {
    console.log(info);
    let compVar =
      typeof event.target.name === "string"
        ? event.target.name
        : +event.target.name;

    if (selectedItems.includes(compVar)) {
      let activeItemName = info[nameKey];
      let activeItemValue = info[valueKey];
      setActiveItems(activeItems.filter((item) => item !== activeItemName));
      setSelectedItems(selectedItems.filter((item) => item !== compVar));
      setSelectedObjArr(
        selectedObjArr.filter((item) => item[valueKey] !== activeItemValue)
      );
    } else {
      setActiveItems([...activeItems, info[nameKey]]);
      setSelectedItems([...selectedItems, compVar]);
      setSelectedObjArr([...selectedObjArr, info]);
    }

    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  const truncate = (str, max) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, max) + "..." : str;
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length === 0) {
      setSelectedObjArr([]);
    }
  }, [selectedItems]);

  return (
    <div ref = {accordionRef} className="relative w-full bg-white">
      <div className="flex items-center">
        <Button
          onClick={() => setShowForm(!showForm)}
          className={`text-left normal-case text-[15px] p-2 ${
            buttonFullW ? "w-full justify-between" : "w-full justify-between"
          }`}
        >
          <span className="text-black px-2">{title}</span>
          {!showForm ? (
            <Add className="text-sky-600 mr-2" />
          ) : (
            <Remove className="text-sky-600 mr-2" />
          )}
        </Button>
      </div>
      {!showForm ? (
        ""
      ) : (
        <>
          {" "}
          <div className={`relative mt-4 bg-white`}>
            <div className="flex items-center">
              <TextField
                className={`placeholder:text-center rounded-lg w-full text-sm ${dropdown? 'p-2':''}`}
                placeholder={searchTitle}
                size="small"
                value={searchVal}
                InputLabelProps={{ shrink: true }}
                label={searchTitle}
                onChange={(event) => setSearchVal(event.target.value)}
              />
            </div>
          </div>
          <FormControl
            className={`max-h-[20vh] overflow-y-auto w-[100%] max-w-full ${dropdown? 'px-2':''}`}
          >
            {loadingRes ? (
              <CircularProgress
                color="secondary"
                className="block mx-auto my-4"
              />
            ) : (
              <List className="w-[100%] m-0 p-2 left-0">
                {selectedObjArr.length > 0 ? (
                  <ListItem className="block border-b border-black px-0 mb-4">
                    <span className=" py-1 text-[16px] w-full">
                      <Sort />
                      &nbsp; Selected {searchTitle}
                    </span>
                    <List>
                      {selectedObjArr.length === 0
                        ? ""
                        : selectedObjArr.map((item) => (
                            <ListItem key={item[valueKey]} className="m-0 p-0">
                              <FormControlLabel
                                className="break-all"
                                control={
                                  <Checkbox
                                    checked={
                                      !selectedItems
                                        ? false
                                        : selectedItems.includes(
                                            `${item[valueKey]}`
                                          )
                                        ? true
                                        : false
                                    }
                                    className="text-sm"
                                    onClick={(event) =>
                                      handleSelectedItem(event, item)
                                    }
                                    name={`${item[valueKey]}`}
                                  />
                                }
                                label={
                                  <Typography className="break-all">
                                    {truncate(item[nameKey], 75)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                    </List>
                  </ListItem>
                ) : (
                  ""
                )}
                {!responseArray
                  ? ""
                  : responseArray.map((item) => (
                      <ListItem key={item[valueKey]} className="m-0 p-0">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                !selectedItems
                                  ? false
                                  : selectedItems.includes(`${item[valueKey]}`)
                                  ? true
                                  : false
                              }
                              className="text-sm"
                              onClick={(event) =>
                                handleSelectedItem(event, item)
                              }
                              name={`${item[valueKey]}`}
                            />
                          }
                          label={
                            <Typography className="break-all">
                              {truncate(item[nameKey], 75)}
                            </Typography>
                          }
                        />
                        <div
                          className={`w-[15px] h-[15px] rounded-full ${
                            !item.client && item.entity
                              ? !item.entity.entity_type_label ===
                                "Client Case Management"
                                ? "bg-yellow-400"
                                : "bg-purple-600"
                              : ""
                          }`}
                        ></div>
                      </ListItem>
                    ))}
              </List>
            )}
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={25}
            />
          </FormControl>
        </>
      )}
    </div>
  );
}
