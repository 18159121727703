import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import PermissionWrapper from "../../../PermissionWrapper";
import SelectBasic from "../../SelectBasic";
import { CircularProgress } from "@mui/material";

export default function ProCliStatusDropdown({
  proCliStatus,
  setProCliStatusParent,
  optionsList,
  getOptionsList,
}) {
  const { userRoles } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [selectedProCliStatus, setSelectedProCliStatus] = useState("");

  const perms = {
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
  };

  const STRINGS = {
    TITLE: "Provider",
  };
  const setSelectedProCliStatusState = (value) => {
    setSelectedProCliStatus(value);
    setProCliStatusParent(value.id);
  };
  /**
   * Use Effect for setting selected option when optionlist is available
   */
  useEffect(() => {
    let active = true;

    if (active) {
      if (optionsList && !options) {
        setOptions(optionsList);
        setSelectedProCliStatus(optionsList.find((g) => g.id === proCliStatus));
        setIsLoading(false);
      }
    }
    return () => {
      active = false;
    };
  }, [options, proCliStatus, optionsList]);

  /**
   * Use Effect for triggering API Call in Parent when Options list is undefined/null
   */
  useEffect(() => {
    let active = true;

    if (active) {
      if (!optionsList) {
        getOptionsList(true);
      }
    }
    return () => {
      active = false;
    };
  }, [optionsList, getOptionsList]);

  return (
    <div
      data-testid="ProCliStatus-Dropdown"
      style={{ margin: "0rem 0rem", width: "100%" }}
    >
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
          {options && options.length > 0 ? (
            <SelectBasic
              selectedVal={selectedProCliStatus}
              setSelectedVal={setSelectedProCliStatusState}
              placeholder={STRINGS.TITLE}
              title={STRINGS.TITLE}
              optionsData={options}
            />
          )
          :
          ""
        }
        </PermissionWrapper>
      )}
    </div>
  );
}
