import {
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  SET_FIELD_DATA,
  SET_FIELD_DATA_IS_LOADING,
  SET_FIELD_META_DATA,
  SET_UNVERIFIED_FLAG,
  SHOULD_FETCH_DATA,
  SHOULD_FETCH_META_DATA,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  shouldFetchTooltipData: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  data: null,
  ui: {
    latest_provider_name: {
      tooltips: null,
      isLoading: true,
    },
    status_updated_by: {
      tooltips: null,
      isLoading: true,
    },
    overall_status_updated_by: {
      tooltips: null,
      isLoading: true,
    },
    overall_status_updated_at: {
      tooltips: null,
      isLoading: true,
    },
    phone: {
      tooltips: null,
      isLoading: true,
    },
    legal_status_updated_by: {
      tooltips: null,
      isLoading: true,
    },
    incident_state: {
      tooltips: null,
      isLoading: true,
    },
    status_updated_at: {
      tooltips: null,
      isLoading: true,
    },
    liability_status: {
      tooltips: null,
      isLoading: true,
    },
    running_total: {
      tooltips: null,
      isLoading: true,
    },
    medical_limits: {
      tooltips: null,
      isLoading: true,
    },
    overall_status: {
      tooltips: null,
      isLoading: true,
    },
    age: {
      tooltips: null,
      isLoading: true,
    },
    max_pain_body_part: {
      tooltips: null,
      isLoading: true,
    },
    next_appointment_date: {
      tooltips: null,
      isLoading: true,
    },
    latest_appointment_date: {
      tooltips: null,
      isLoading: true,
    },
    all_in_network_providers_count: {
      tooltips: null,
      isLoading: true,
    },
    all_out_of_network_providers_count: {
      tooltips: null,
      isLoading: true,
    },
    statute_of_limitations: {
      tooltips: null,
      isLoading: true,
    },
    max_pain_level: {
      tooltips: null,
      isLoading: true,
    },
    legal_status_updated_at: {
      tooltips: null,
      isLoading: true,
    },
    comm_status: {
      tooltips: null,
      isLoading: true,
    },
    coverage_status: {
      tooltips: null,
      isLoading: true,
    },
    name_prefix: {
      tooltips: null,
      isLoading: true,
    },
    preferred_language: {
      tooltips: null,
      isLoading: true,
    },
    date_of_birth: {
      tooltips: null,
      isLoading: true,
    },
    name: {
      tooltips: null,
      isLoading: true,
    },
    date_of_accident: {
      tooltips: null,
      isLoading: true,
    },
    incident_type: {
      tooltips: null,
      isLoading: true,
    },
    incident_city: {
      tooltips: null,
      isLoading: true,
    },
    property_damage_severity: {
      tooltips: null,
      isLoading: true,
    },
    incident_role: {
      tooltips: null,
      isLoading: true,
    },
    status: {
      tooltips: null,
      isLoading: true,
    },
    legal_status: {
      tooltips: null,
      isLoading: true,
    },
    related_cases: {
      isLoading: false,
    },
    next_provider_name: {
      isLoading: false,
    },
  },
};

export function staticBarReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case SHOULD_FETCH_META_DATA: {
      return { ...state, shouldFetchTooltipData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case SET_FIELD_DATA_IS_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: {
            ...state.ui[action.payload.fieldName],
            isLoading: action.payload.data,
          },
        },
      };
    }
    case SET_FIELD_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    }
    case SET_FIELD_META_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    }
    case SET_UNVERIFIED_FLAG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
}
