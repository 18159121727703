export const ACTION_TYPE = {
  MANUAL: 1,
  CLIENT_FOLLOW_UP: 2,
  LAW_FIRM_FOLLOW_UP: 3,
  PROVIDER_FOLLOW_UP: 4,
};

export const ACTIONABLE_ITEM_TYPE_STRING = {
  1: "Manual",
  2: "Client Follow Up",
  3: "Law Firm Follow Up",
  4: "Provider Follow Up",
};
