import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { AspectRatioOutlined, CloseOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import "../../../../styles/FullCalMissedAppt.css";
import axios from "../../../api/axios";
import AssignAppt from "./AssignAppt/AssignAppt";
import EditApptv2 from "./ClientDetail/ProvApptDetails/EditApptv2";
import LoadingIndicator from "../../../global/LoadingIndicator";

export default function SearchByMissedAppts({ isDashboardVersion }) {
  const { accessToken, trigger, setTrigger, setSelectedClient } =
    useContext(DataContext);
  const [callbacks, setCallbacks] = useState();
  const [openCbModal, setOpenCbModal] = useState(false);
  const [activeClientPk, setActiveClientPk] = useState();
  const [activeAppt, setActiveAppt] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [today, setToday] = useState();
  const [navigateToRelatedCase, setNavigateToRelatedCase] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const todayMin1 = new Date(today);

    setToday(todayMin1.setDate(todayMin1.getDate() - 1));

    if (!startDate) {
      setIsLoading(true);
      return;
    }
    axios
      .get(
        `/api/appointments/?limit=250&status=Assigned&scheduled__gte=${startDate}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        let testArr = [];
        response.data.results.map((appt) => {
          testArr.push({
            //MAKE THE CASE ID THE PK PLEASE and make the name display
            id: appt.pk,
            title: appt.client.name,
            description: !appt.notes ? "" : "",
            start: new Date(appt.scheduled),
            extendedProps: {
              appointmentObj: appt,
              activeClient: appt.client.pk,
              provider: appt.provider.name,
            },
          });
          return testArr;
        });
        setCallbacks(testArr);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
  }, [accessToken, trigger, startDate, setStartDate, setEndDate, endDate]);

  const handleEventClick = (clickInfo) => {
    let eventInfo = clickInfo.event._def;
    setActiveAppt(eventInfo.extendedProps.appointmentObj);
    setActiveClientPk(eventInfo.extendedProps.activeClient);
    setOpenCbModal(true);
  };

  const handleModalClose = () => {
    setOpenCbModal(false);
    setTrigger(!trigger);
    setActiveAppt("");
    setActiveClientPk("");
  };

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
          </div>
        </div>
      </div>
    );
  }

  const handleNavToRelated = (clientId) => {
    setNavigateToRelatedCase(true);
    axios
      .get(`/api/case/?client=${clientId}&simplified=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        const foundCasePk = data[0].pk;
        setSelectedClient(foundCasePk);
        setNavigateToRelatedCase(false);
        navigate(`/client-detail/${foundCasePk}`);
      })
      .catch((error) => {
        setNavigateToRelatedCase(false);
        console.log(error);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            boxShadow: "0 0 10px #D8B4FE",
            borderRadius: "5px",
            left: " 50%",
            top: "50%",
            background: "white",
            zIndex: "10",
            padding: "1rem",
          }}
        >
          <CircularProgress style={{ color: "#6b21a8" }} />
        </div>
      ) : (
        ""
      )}
      <LoadingIndicator isLoading={navigateToRelatedCase} />
      <div className="mt-2 pb-8 bg-white">
        <div className="text-center my-2 max-w-[95%] mx-auto">
          <h1 className="text-orange-500	text-xl font-bold">
            Scheduled Appointments - Need Review
          </h1>
        </div>
        <div className="max-w-[95%] mx-auto full-calendar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "today",
              center: "title",
              right: "prev,next",
            }}
            validRange={{
              end: today,
            }}
            slotMinTime={"07:00:00"}
            slotMaxTime={"20:00:00"}
            datesSet={(dateInfo) => {
              setStartDate(
                new Date(dateInfo.start).toISOString().substring(0, 10)
              ); //start of the range the calendar date
              setEndDate(new Date(dateInfo.end).toISOString().substring(0, 10)); //end of the range the calendar date
            }}
            events={callbacks}
            selectable={false}
            eventClick={handleEventClick}
            eventContent={(arg) => {
              return (
                <Tooltip
                  title={
                    <Typography color="inherit">
                      {!arg.event._def.extendedProps.provider
                        ? "No Description"
                        : arg.event._def.extendedProps.provider}
                    </Typography>
                  }
                  arrow
                >
                  {renderInnerContent(arg)}
                </Tooltip>
              );
            }}
          />
          <Dialog open={openCbModal} fullWidth>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem 0",
              }}
            >
              {" "}
              <Tooltip title="Navigate to detail">
                <Button onClick={() => handleNavToRelated(activeClientPk)}>
                  <AspectRatioOutlined />
                </Button>
              </Tooltip>
              <Button onClick={handleModalClose}>
                <CloseOutlined />
              </Button>
            </div>
            {activeAppt ? (
              <div style={{ padding: "1rem" }}>
                <EditApptv2
                  selectedAppt={activeAppt}
                  handleModalClose={handleModalClose}
                />
              </div>
            ) : (
              <div style={{ padding: "1rem" }}>
                <AssignAppt
                  isModalVersion={true}
                  handleModalClose={handleModalClose}
                  setActiveClientPk={setActiveClientPk}
                />
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </LocalizationProvider>
  );
}
