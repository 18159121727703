import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  Alert,
  AlertTitle,
  ListItem,
} from "@mui/material";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import { formatDistanceStrict } from "date-fns";
import { Editor } from "@tinymce/tinymce-react";
import CloseIcon from "@mui/icons-material/Close";
import ModalButton from "../../../global/ModalButton";
import { ExpandMoreOutlined } from "@mui/icons-material";
import PaginationControls from "../../../global/PaginationControls";
import ApiRequestErrorHandler from "../../../global/ApiRequestErrorHandler";

export default function NeedsSupervisor({
  isDashboardVersion,
  resultClasses,
  format,
}) {
  const { accessToken, casePk, getCasePk } = useContext(DataContext);
  const [caseResultsList, setCaseResultsList] = useState();
  const [openSupervisorDialog, setOpenSupervisorDialog] = useState(false);
  const [dialogType, setDialogType] = useState("clear");
  const [supervisorReason, setSupervisorReason] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `/api/case/?${paginationUrl}` +
          `supervisor_needed_reason__isnull=false`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setCaseResultsList(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
      });
  }, [setCaseResultsList, accessToken, paginationUrl, openSuccess]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  function openDialog(type, client) {
    getCasePk(client.pk);
    setDialogType(type);
    setOpenSupervisorDialog(!openSupervisorDialog);
    setSupervisorReason(client.supervisor_needed_reason);
  }

  function handleClient(client) {
    navigate(`/client-detail/${client.pk}`);
  }

  const handleResolve = () => {
    let patchedData = {
      supervisor_needed_reason: null,
      callback: null,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSupervisorDialog(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        const res = error.response.data;
        const resArr = ApiRequestErrorHandler(res);
        setErrorMessage(resArr);
        setOpenError(true);
      });
  };

  const handleNeedsSupNotes = () => {
    let patchedData = {
      ...(supervisorReason === ""
        ? {}
        : { supervisor_needed_reason: supervisorReason }),
      callback: null,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSupervisorDialog(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        const res = error.response.data;
        const resArr = ApiRequestErrorHandler(res);
        setErrorMessage(resArr);
        setOpenError(true);
      });
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(false);
  };
  const handleOpenError = () => {
    setOpenError(false);
  };

  return (
    <div
      className={`h-full overflow-y-auto px-4 bg-white`}
    >
      <div
        className={` w-full text-center font-bold text-xl text-red-600 py-4 shadow-sm`}
      >
        Needs Supervisor
      </div>
      <p className="pl-5 py-4">
        Showing: {resultCount.current} / {resultCount.total}
      </p>
      <div className="flex px-4 text-center py-2 border-b border-1 border-gray">
        <div className="md:w-1/5 w-1/3 font-bold text-left">
          <h2>CLIENT NAME</h2>
        </div>
        <div className="w-1/5 md:block hidden font-bold">
          <h2>CASE NAME</h2>
        </div>
        <div className="w-1/5 md:block hidden font-bold">
          <h2>LAST UPDATED</h2>
        </div>
        <div className="md:w-1/5 w-1/3 font-bold">
          <h2>TIME SINCE UPDATED</h2>
        </div>
        <div className="md:w-1/5 w-1/3 font-bold">
          <h2>STATUS</h2>
        </div>
      </div>
      {caseResultsList && (
        <div className={`pb-8`}>
          {caseResultsList
            .sort((a, b) => {
              return (
                new Date(a.supervisor_needed_reason_updated_at) -
                new Date(b.supervisor_needed_reason_updated_at)
              );
            })
            .map((client, index) => {
              return (
                <Accordion
                  className="w-[100%] my-2 rounded-md border-0"
                  key={index + Math.random()}
                >
                  <AccordionSummary
                    className="border-0"
                    expandIcon={
                      <div className="flex items-center">
                        <ExpandMoreOutlined />
                      </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="flex items-center justify-between w-full">
                      <span
                        className={`block md:w-1/5 w-1/3 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-left ${resultClasses}`}
                      >
                        {currentPage > 1
                          ? index + 1 * ((currentPage - 1) * 25) + 1
                          : index + 1}
                        .&nbsp;
                        {client.client.name}
                      </span>
                      <span
                        className={`md:block hidden w-1/5 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses}`}
                      >
                        {!client ? "" : client.name}
                      </span>
                      <span
                        className={`md:block hidden w-1/5 text-left text-sm pl-2 text-center ${resultClasses}`}
                      >
                        {!client.supervisor_needed_reason_updated_at
                          ? ""
                          : new Date(
                              client.supervisor_needed_reason_updated_at
                            ).toLocaleString("en-us", format)}
                      </span>
                      <span
                        className={`block md:w-1/5 w-1/3 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses}`}
                      >
                        {formatDistanceStrict(
                          new Date(client.supervisor_needed_reason_updated_at),
                          new Date(),
                          { includeSeconds: true }
                        )}
                      </span>
                      <span
                        className={`block md:w-1/5 w-1/3 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses} border-none`}
                      >
                        {!client ? "" : client.status}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className={
                        isDashboardVersion
                          ? "flex flex-col"
                          : "flex justify-between"
                      }
                    >
                      <div>
                        <h2
                          className="font-bold text-xl text-red-600"
                          style={{ padding: "1rem 32px" }}
                        >
                          Reason
                        </h2>
                        <div
                          className="notes"
                          dangerouslySetInnerHTML={{
                            __html: !client
                              ? ""
                              : client.supervisor_needed_reason,
                          }}
                        ></div>
                      </div>
                      <div
                        className="flex md:flex-row flex-col justify-start"
                        style={{
                          padding: isDashboardVersion ? "1rem 32px" : "",
                        }}
                      >
                        <Button
                          className="border-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white mr-2 block md:self-center md:mb-0 mb-2"
                          variant="outlined"
                          onClick={() => handleClient(client)}
                        >
                          View Client Detail
                        </Button>
                        <Button
                          className="border-orange-400 text-orange-400 hover:bg-orange-400 hover:text-white mr-2 block md:self-center md:mb-0 mb-2"
                          variant="outlined"
                          onClick={() => openDialog("update", client)}
                        >
                          Update
                        </Button>
                        <Button
                          className="border-red-600 text-red-600 hover:bg-red-600 hover:text-white block md:self-center md:mb-0 mb-2"
                          variant="outlined"
                          onClick={() => openDialog("clear", client)}
                        >
                          Resolve
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          <ListItem className={`justify-center py-10`}>
            <PaginationControls
              resultCount={resultCount}
              handleChange={handleChange}
              currentPage={currentPage}
            />
          </ListItem>
        </div>
      )}
      <Dialog open={openSupervisorDialog}>
        <Alert variant="outlined" severity="error" role="alert">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "20px",
              color: "#d32f2f",
              margin: "0 0 1rem",
            }}
          >
            <AlertTitle>Needs Supervisor</AlertTitle>

            <Button
              onClick={() => setOpenSupervisorDialog(!openSupervisorDialog)}
            >
              <CloseIcon style={{ color: "#d32f2f" }} />
            </Button>
          </div>
          {dialogType === "clear" ? (
            <div>
              <h2
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  margin: "0 0 1rem 0",
                }}
              >
                Resolve Supervisor Need
              </h2>
              <p>
                Please note: Clicking below will clear all previous available
                notes and information involving this supervisor need. If you
                need to retain any information involving this issue please save
                the existing notes in the Client Notes section on the client{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to={`/client-detail/${casePk}`}
                >
                  Dashboard
                </Link>
              </p>
              <div style={{ margin: "1rem 0" }}>
                <ModalButton
                  baseColor="#d32f2f"
                  content="RESOLVE"
                  action={handleResolve}
                />
              </div>
            </div>
          ) : (
            <div>
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                value={supervisorReason}
                onEditorChange={(newText) => {
                  setSupervisorReason(newText);
                }}
                init={{
                  menubar: false,
                  branding: false,
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                }}
              />
              <h2
                style={{
                  fontSize: "1rem",
                  color: "#d32f2f",
                  fontWeight: "bold",
                  margin: "1rem 0 0",
                }}
              >
                WARNING
              </h2>
              <p className="">
                By submitting this form, the client information entered here
                will be submitted to supervisors for review. Callback cadence
                will be removed and uneditable until supervisor reviews this
                form and Needs Supervisor status is resolved.
              </p>
              <div style={{ margin: "1rem 0" }}>
                <ModalButton
                  baseColor="#d32f2f"
                  content="SUBMIT"
                  action={handleNeedsSupNotes}
                />
              </div>
            </div>
          )}
        </Alert>
      </Dialog>
      <Dialog open={openSuccess}>
        <Alert variant="outlined" severity="success" role="alert">
          <AlertTitle>Success!</AlertTitle>
          <p>
            {dialogType === "clear"
              ? "Successfully cleared clients supervisor needs!"
              : "Successfully Updated Content"}
            <br />
          </p>
        </Alert>
        <Button onClick={handleOpenSuccess}>OK</Button>
      </Dialog>
      <Dialog open={openError}>
        <Alert variant="outlined" severity="error" role="error">
          <AlertTitle>Error Updating</AlertTitle>
          <br />
          <div className="">
            {errorMessage.map((issue, index) => (
              <ListItem key={index} value={issue}>
                {issue}
              </ListItem>
            ))}
            <br />
            <h3>
              Fix errors and then resubmit. If errors persist, contact your
              Progression Network Representative.
            </h3>
          </div>
        </Alert>
        <Button onClick={handleOpenError}>OK</Button>
      </Dialog>
    </div>
  );
}
