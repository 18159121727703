import React, { useContext, useEffect, useState } from "react";
import AssociateAppointmentsTable from "../AssociateAppointmentsTable";
import { Button } from "@mui/material";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";

function AssociateAppointments({ document, clientId, setOpenAssociateAppointmentsModal, docTrigger, setDocTrigger }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [appointmentList, setAppointmentList] = useState([]);
  const [next, setNext] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buttonStyle = {
    width: '120px',
    height: '40px',
  };
  const canAddAssociatedDocuments = userRoles.permissions.includes("filemanager.add_document_association");

  const handleSaveSuccess = () => {
    setDocTrigger(!docTrigger);
    setOpenAssociateAppointmentsModal(false);
  };

  const handleSaveFail = () => {
    setOpenAssociateAppointmentsModal(false);
  };

  const covertDateFollowFormat = (dateObject) => {
    const dateObj = new Date(dateObject);
    const date = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const time = `${(hours % 12 || 12)}:${minutes} ${ampm}`;
    return { date, time };
  }

  const formatAppointments = (list) => {
    return list.map(app => {
      const totalAmount = app.administered_services.reduce((sum, service) => {
        return sum + parseFloat(service.price);
      }, 0);

      const amount = `$${totalAmount.toFixed(2)}`;

      const { date, time } = covertDateFollowFormat(app.scheduled);

      return {
        id: app.pk,
        date,
        time,
        amount
      };
    });
  }

  const handleSaveData = () => {
    setIsLoading(true);
    const idsAssociatedAppointments = appointmentList
      .filter(item => item.included)
      .map(item => item.id);
    axios
      .patch(`/api/documents/${document.pk}/`,
        {
          "appointments": idsAssociatedAppointments
        }, {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then(function () {
        setSaveSuccess(true);
      })
      .catch(function () {
        setSaveFailed(true);
      });
    setIsLoading(false);
  }

  const fetchAppointments = async (url) => {
    setIsLoading(true);
    axios
      .get(url, {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        const responseAppointments = formatAppointments(response.data.results);
        setAppointmentList((prevAppointments) => [
          ...prevAppointments,
          ...responseAppointments,
        ]);
        setNext(response.data.next);
        setIsLoading(false);
      })
      .catch(function () {
        setIsLoading(false);
        setSaveFailed(true);
      });
  }

  const handleScroll = () => {
    if (next && !isLoading) {
      fetchAppointments(next);
    }
  };

  useEffect(() => {
    fetchAppointments(`/api/appointments/?limit=100&client=${clientId}&ordering=-scheduled&include_administered=true`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? <div className="grid grid-cols-6 min-h-[65vh] w-full gap-4">
        <LoadingIndicator isLoading={isLoading} />
      </div> : (
        <div className="grid grid-cols-3 min-h-[65vh] w-full gap-4">
          <div className="col-span-2 min-h-[60vh]">
            {document && document.file
              ? <embed
                aria-label={document.file_name}
                src={document.file}
                width="100%"
                height="100%"
              />
              : <p
                style={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  fontWeight: "bold"
                }}
              >
                No File Preview Available
              </p>}
          </div>
          <div className="col-span-1">
            <AssociateAppointmentsTable next={next} document={document} includedAppointments={document.appointments} appointmentList={appointmentList} setAppointmentList={setAppointmentList} handleScroll={handleScroll} canAddAssociatedDocuments={canAddAssociatedDocuments} />
          </div>
          {canAddAssociatedDocuments &&
            (
              <div className="col-span-3 flex justify-end gap-4 mt-6">
                <Button style={buttonStyle} variant="contained" color="error" onClick={() => setOpenAssociateAppointmentsModal(false)}>Cancel</Button>
                <Button disabled={appointmentList.length === 0} style={buttonStyle} variant="contained" color="primary" onClick={() => handleSaveData()}>Save</Button>
              </div>
            )
          }
          <ResponseModal
            title="Appointments successfully associated and saved"
            description="You’ve updated the Association of Appointments to Documents successfully"
            openBool={saveSuccess}
            setOpenBool={setSaveSuccess}
            handleCloseFunc={handleSaveSuccess}
          />
          <ResponseModal
            title="Can't save your associated appointments"
            isError={true}
            openBool={saveFailed}
            setOpenBool={setSaveFailed}
            errorMessage={null}
            handleCloseFunc={handleSaveFail}
          />
        </div>)}
    </>
  );
}

export default AssociateAppointments;
