import { Checkbox, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import { ArrowDownward } from "@mui/icons-material";

const AssociateAppointmentsTable = ({ includedAppointments, appointmentList, setAppointmentList, handleScroll, next, canAddAssociatedDocuments }) => {
    const scrollContainerRef = useRef(null);
    const scrollPositionRef = useRef(0);

    const StyledTableCell = styled(TableCell)({
        borderBottom: 'none',
        padding: '0px',
        textAlign: 'center'
    });

    const StyledTableContainer = styled(TableContainer)({
        boxShadow: 'none',
        margin: '0 auto',
        border: 'none',
        maxHeight: '550px',
        overflowY: 'auto'
    });

    const StyledTable = styled(Table)({
        border: 'none'
    });

    const StyledTableHead = styled(TableHead)({
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1
    });

    useEffect(() => {
        const updatedAppointments = appointmentList.map(appointment => {
            const isIncluded = includedAppointments.some(included => included === appointment.id);
            return {
                ...appointment,
                included: isIncluded
            };
        });
        setAppointmentList(updatedAppointments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (index) => {
        scrollPositionRef.current = scrollContainerRef.current.scrollTop;
        setAppointmentList(prevCheckedItems => {
            const updatedCheckedItems = [...prevCheckedItems];
            updatedCheckedItems[index].included = !updatedCheckedItems[index].included;
            return updatedCheckedItems;
        });
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollPositionRef.current;
        }
    });

    return (
        <Fragment>
            <Typography variant="h6" align="center" gutterBottom className="mb-10">
                Associate Appointments
            </Typography>
            {appointmentList.length === 0 ? (<div className="text-center">
                No scheduled appointments yet
            </div>) : (
                <StyledTableContainer ref={scrollContainerRef} data-testid= "styled-table-container" component={Paper}>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold', fontSize: '18px' }}>Date</StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold', fontSize: '18px' }}>Time</StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold', fontSize: '18px' }}>Amount</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {appointmentList.map((appointment, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>
                                    <Checkbox
                                        className="checkbox-included"
                                        data-testid="checkbox-included"
                                        checked={appointment.included}
                                        onChange={() => handleCheckboxChange(index)}
                                        disabled={!canAddAssociatedDocuments}
                                    />
                                    <span>Included</span>
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontSize: '18px' }}>{appointment.date}</StyledTableCell>
                                <StyledTableCell sx={{ fontSize: '18px' }}>{appointment.time}</StyledTableCell>
                                <StyledTableCell sx={{ fontSize: '18px' }}>{appointment.amount}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
                <div className="flex justify-center">
                    {next ? (
                        <Tooltip title="Show more results">
                            <IconButton
                                size="small"
                                onClick={() => handleScroll()}
                            >
                                <ArrowDownward />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </div>
            </StyledTableContainer>
            )}
        </Fragment>
    )
}

export default AssociateAppointmentsTable;


