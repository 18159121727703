import FollowUpSection from "./ui/FollowUpSection";
import FollowUpProvider from "../context/FollowUpContext";

const FollowUpViews = ({ divId, actionType, caseInfo, limit, title, icon }) => {
  return (
    <div id="Follow-Ups-Views" data-testid="Follow-Ups-Views">
      <FollowUpProvider>
        <FollowUpSection
          divId={divId}
          title={title}
          icon={icon}
          limit={limit}
          actionType={actionType}
          caseInfo={caseInfo}
        />
      </FollowUpProvider>
    </div>
  );
};
export default FollowUpViews;
