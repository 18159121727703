import React, { useContext, useEffect, useState } from "react";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import DataContext from "../../../../../context/DataContext";
import LoadingIndicator from "../../../../global/LoadingIndicator";
import axios from "../../../../api/axios";
import qs from "query-string";
import {
  Chip,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import PaginationControls from "../../../../global/PaginationControls";
import ViewDocumentModal from "../ClientDetail/DocumentManager/DocumentUpload/ViewDocuments/Modals/ViewDocumentModal";
import { Close, FilterAlt } from "@mui/icons-material";
import SearchOptionGroup from "../../../../global/SearchOptionGroup";
import DocumentListItem from "./DocumentListItem";
import DocumentUpdateListItem from "../ClientDetail/DocumentManager/DocumentUpload/ViewDocuments/DocumentUpdateListItem";
import { CustomChip } from "../../../../global/CustomQueryChip";
import MultiSelectSearchV2 from "../../../../global/MultiSelectSearchV2";

export default function DocumentSearch({ isDashboardVersion }) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [documentResults, setDocumentResults] = useState([]);
  const [documentTypeObj, setDocumentTypeObj] = useState();
  const [docTrigger, setDocTrigger] = useState(false);
  const [searchDocs, setSearchDocs] = useState("");
  const [docLoading, setDocLoading] = useState(false);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [viewDocument, setViewDocument] = useState(false);
  const [activeDoc, setActiveDoc] = useState(false);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docValidation, setDocValidation] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [activeTypes, setActiveTypes] = useState([]);
  const [isChecked, setIsChecked] = useState(null);
  const [isApproved, setIsApproved] = useState(null);

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const BasicQueryChip = ({ setValue, value, title }) => {
    return (
      <>
        {value === null || value === "" ? (
          ""
        ) : (
          <Tooltip title={"Clear query"}>
            <Chip
              className={`px-2 m-1 text-left rounded-lg shadow-inner hover:bg-red-400 py-2`}
              onClick={() => {
                setValue(null);
                resetPagination();
              }}
              label={`${title}: ${value}`}
              sx={{
                height: "auto",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const closeDocModal = () => {
    setActiveDoc("");
    setViewDocument(false);
  };
  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  useEffect(() => {
    axios
      .get(
        `/api/field_options/?content_type=document&field_name=document_type&ordering=order&limit=100`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        let data = response.data.results;
        let obj = {};

        for (const item in data) {
          obj[data[item].value] = data[item].display_name;
        }
        setDocumentTypeObj(obj);
      })
      .catch((response) => {
        console.log(response);
      });

    axios
      .get(`/api/documents/validate`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDocValidation(response.data);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
  }, [setDocumentTypeObj, accessToken, setDocTypeOptions]);

  useDebouncedEffect(
    () => {
      if (userRoles.permissions.includes("filemanager.view_document")) {
        setDocLoading(true);
        let query = qs.stringify(
          {
            search: !searchDocs ? null : searchDocs,
            checked: isChecked === null ? null : isChecked,
            approved: isApproved === null ? null : isApproved,
          },
          {
            skipNull: true,
          }
        );
        const typeQuery =
          selectedTypes.length === 0
            ? ""
            : `document_type__in=${selectedTypes}`;
        axios
          .get(`/api/documents/?` + paginationUrl + query + typeQuery, {
            headers: { Authorization: `Token ${accessToken}` },
          })
          .then((response) => {
            let data = response.data.results;
            setResultCount({
              total: response.data.count,
              current: response.data.results.length,
            });
            setDocumentResults(data);

            setDocLoading(false);
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        return;
      }
    },
    [
      accessToken,
      setDocumentResults,
      docTrigger,
      userRoles.permissions,
      searchDocs,
      paginationUrl,
      selectedTypes,
      isApproved,
      isChecked,
    ],
    250
  );
  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };
  return (
    <div className={`mt-2 pb-8 bg-white`}>
      <h1 className="font-bold text-[1.5rem] text-center">Search Documents</h1>
      <div
        style={{
          width: "95%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Tooltip title="Search Documents">
          <TextField
            style={{ width: "25%" }}
            placeholder="Search Clients Docs"
            size="small"
            name="search"
            value={searchDocs}
            InputLabelProps={{ shrink: true }}
            label="Search"
            onChange={(event) => setSearchDocs(event.target.value)}
          />
        </Tooltip>
        <div className="mx-4 rounded-md shadow-md px-4 py-2 absolute left-[28%] top-0 z-10 bg-white">
          <MultiSelectSearchV2
            title="Sort by Document Type"
            searchTitle="Document Types"
            selectedItems={selectedTypes}
            setSelectedItems={setSelectedTypes}
            setActiveItems={setActiveTypes}
            activeItems={activeTypes}
            resetPagination={resetPagination}
            nameKey={"label"}
            valueKey={"key"}
            apiUrl="/api/field_options/?content_type=document&field_name=document_type&ordering=order&"
          />
        </div>
        <div style={{ position: "relative", zIndex: "20" }}>
          <Tooltip
            title={`${
              !toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
            }`}
          >
            <IconButton onClick={() => setToggleFilterMenu(!toggleFilterMenu)}>
              <FilterAlt />
            </IconButton>
          </Tooltip>
          {toggleFilterMenu ? (
            <div
              className={`shadow-lg text-center 
                    left-0 w-fit absolute bg-white z-10 overflow-y-auto rounded-md p-4`}
              onMouseLeave={() => setToggleFilterMenu(false)}
            >
              <div className="font-bold text-lg p-4 flex lg:justify-between justify-end min-w-[400px]">
                <h2>Filter</h2>
                <Tooltip
                  title={`${
                    toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                  }`}
                >
                  <IconButton
                    onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
                    className={`${toggleFilterMenu ? "" : "rotate-180"}`}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="px-4">
                <div className="my-2">
                  <SearchOptionGroup
                    setValue={setIsChecked}
                    value={isChecked}
                    title="Checked Documents"
                    resetPagination={resetPagination}
                  />
                </div>
                <div className="my-2">
                  <SearchOptionGroup
                    setValue={setIsApproved}
                    value={isApproved}
                    title="Approved Documents"
                    resetPagination={resetPagination}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <p className="w-[95%] mx-auto my-2">
        {" "}
        Showing: {resultCount.current} / {resultCount.total}
      </p>
      <div className="flex">
        {" "}
        <CustomChip
          setActiveItems={setActiveTypes}
          activeItems={activeTypes}
          selectedItems={selectedTypes}
          setSelectedItems={setSelectedTypes}
          title="Type"
          resetPagination={resetPagination}
        />
        <BasicQueryChip
          setValue={setIsChecked}
          value={isChecked}
          title="Checked"
        />
        <BasicQueryChip
          setValue={setIsApproved}
          value={isApproved}
          title="Approved"
        />
        {/* {activeFilters.map((filt) => <BasicQueryChip setValue={} value={} title={} />)} */}
      </div>
      {docLoading ? (
        <LoadingIndicator isLoading={docLoading} />
      ) : (
        <>
          {" "}
          <div
            style={{
              borderBottom: "1px solid #EBEBEB",
              padding: "16px 0",
              display: "flex",
              width: "100%",
              maxWidth: "100%",
              margin: "0 16px",
            }}
          >
            <div
              style={{
                width: isDashboardVersion ? "100%" : "75%",
                display: "flex",
                padding: "0 16px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  alignSelf: "center",
                  width: "25%",
                  fontWeight: "bold",
                }}
              >
                Document Type
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                  width: "15%",
                  fontWeight: "bold",
                }}
              >
                Time Stamps
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                  width: "25%",
                  fontWeight: "bold",
                }}
              >
                Case
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                  width: "25%",
                  fontWeight: "bold",
                }}
              >
                Provider/Referral
              </div>
            </div>
          </div>
          <List
            className={`mx-4 ${
              isDashboardVersion ? "max-h-[70vh] overflow-y-auto" : ""
            }`}
          >
            {!documentResults
              ? "No Results"
              : documentResults.length === 0
              ? "No Results"
              : documentResults.map((doc) => {
                  if (doc.document_type === null) {
                    return (
                      <DocumentUpdateListItem
                        document={doc}
                        key={doc.pk}
                        documentTypeObj={documentTypeObj}
                        activeDoc={activeDoc}
                        setActiveDoc={setActiveDoc}
                        setViewDocument={setViewDocument}
                        docTypeOptions={docTypeOptions}
                        docValidation={docValidation}
                        truncate={truncate}
                        docTrigger={docTrigger}
                        setDocTrigger={setDocTrigger}
                      />
                    );
                  } else {
                    return (
                      <DocumentListItem
                        document={doc}
                        key={doc.pk}
                        documentTypeObj={documentTypeObj}
                        activeDoc={activeDoc}
                        setActiveDoc={setActiveDoc}
                        setViewDocument={setViewDocument}
                        docTypeOptions={docTypeOptions}
                        docValidation={docValidation}
                        truncate={truncate}
                        docTrigger={docTrigger}
                        setDocTrigger={setDocTrigger}
                        isDashboardVersion={isDashboardVersion}
                      />
                    );
                  }
                })}
            <ListItem
              className={`justify-center py-10`}
              data-testid="client-list-item"
            >
              <PaginationControls
                resultCount={resultCount}
                handleChange={handleChange}
                currentPage={currentPage}
              />
            </ListItem>
          </List>
          <ViewDocumentModal
            viewDocument={viewDocument}
            closeDocModal={closeDocModal}
            activeDoc={activeDoc}
          />
        </>
      )}
    </div>
  );
}
