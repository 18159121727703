import { Launch, Phone } from "@mui/icons-material";
import { ACTION_TYPE } from "../../../../types/actionTypes";
import DateTimeModel from "./fields/DateTimeModel";
import STRINGS from "./utils/strings";
import { Button } from "@mui/material";
import ValueWithFallback from "../../../../../StaticBar/components/ui/ValueWithFallback";

const SelectedFollowUpView = ({
  title,
  selectedFollowUp,
  navigateToDetail,
}) => {
  const FormatPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }
    return " ";
  };

  return (
    <div id="Follow-Up-View" data-testid="Follow-Up-View">
      <div className="border-1 border-solid border-slate-500 text-left p-2">
        <div>
          {STRINGS.SCHEDULED_FOR}:{" "}
          <div className="text-[1rem]">{selectedFollowUp.label}</div>
        </div>
        <div className="border-1 border-solid border-slate-500 text-left py-2">
          <div className="text-[.75rem]">Client Name: </div>
          <div className="text-[1rem]">{selectedFollowUp.client.name}</div>
        </div>
        <div className="border-1 border-solid border-slate-500 text-left py-2">
          <div className="text-[.75rem]">Due Date: </div>
          <div className="text-[1rem]">
            {selectedFollowUp.fields.dueDate &&
              DateTimeModel(selectedFollowUp.fields.dueDate)}
          </div>
          <span className="italic">
            {selectedFollowUp.timeTillDueDate &&
              " (" + selectedFollowUp.timeTillDueDate + ")"}
          </span>
        </div>
        <div className="border-1 border-solid border-slate-500 text-left py-2">
          <div className="text-[.75rem]">Reason: </div>
          <div className="text-[1rem]">{selectedFollowUp.fields.detail}</div>
        </div>

        {selectedFollowUp.actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP && (
          <div className="border-1 border-solid border-slate-500 text-left py-2">
            <div className="text-[.75rem]">Provider Name:</div>
            <div className="text-[1rem]">
              {selectedFollowUp?.fields.provider.name}
            </div>
            <div className="text-[.75rem] pt-2">Phone Number: </div>
            <div className="text-[1rem]">
              {ValueWithFallback(
                FormatPhoneNumber(selectedFollowUp?.fields.provider.phone)
              )}
            </div>
          </div>
        )}
         {selectedFollowUp.actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP && (
          <div className="border-1 border-solid border-slate-500 text-left py-2">
            <div className="text-[.75rem]">Law Firm Name:</div>
            <div className="text-[1rem]">
              {selectedFollowUp.lawFirm.name}
            </div>
            <div className="text-[.75rem] pt-2">Phone Number: </div>
            <div className="text-[1rem]">
              {ValueWithFallback(
                FormatPhoneNumber(selectedFollowUp.lawFirm.phone)
              )}
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="border-1 border-solid border-slate-500 text-left py-2">
        <div className="text-[.75rem]">
          {" "}
          Status:{" "}
          <span className="uppercase">
            {selectedFollowUp.status && selectedFollowUp.status === "pending"
              ? "In-Complete"
              : "Complete"}
          </span>
        </div>

        {selectedFollowUp.status && selectedFollowUp.status === "done" && (
          <>
            <div className="text-[0.85rem]">
              <div className="text-[.75rem]">Completed By: </div>
              {selectedFollowUp.completedBy &&
                selectedFollowUp.completedBy.name}
            </div>
            <div className="text-[0.85rem]">
              Completed At:{" "}
              {selectedFollowUp.completedAt &&
                DateTimeModel(selectedFollowUp.completedAt)}
            </div>
          </>
        )}

        <Button
          title="Open Case Details"
          className="hover:text-white hover:bg-sky-600 text-sky-600"
          onClick={() => navigateToDetail(selectedFollowUp.caseId)}
        >
          Navigate to Case <Launch />
        </Button>
      </div>
    </div>
  );
};

export default SelectedFollowUpView;
