import { useCallback } from "react";
import {
  setCalendarEvents,
  setfetchError,
  setfetchInProgress,
  setfetchIsLoading,
  setfetchSuccess,
  setResultCount,
  setShouldFetchData,
} from "../state/actions";
import { case_detail } from "../../../../../../../api/endpoints/case";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";
import { ACTION_TYPE } from "../../types/actionTypes";

export default function useGetAllActionTypes(
  assignedUserId,
  orderByKey,
  paginationUrl,
  actionTypes,
  managedUserFilter,
  providerFilter,
  followUpFilter,
  medicalStatusFilter,
  caseStatusFilter,
  lawFirmFilter,
  startDateFilter,
  endDateFilter,
  search,
  accessToken,
  dispatch
) {
  const getActionTypeKey = (actionType) => {
    if (actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) {
      return "on_provider";
    } else if (actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP) {
      return "on_firm";
    } else {
      return "on_case";
    }
  };

  const fetchAllFollowUpData = useCallback(() => {
    let eventsArr = [];
    if (!managedUserFilter.includes(assignedUserId)) {
      managedUserFilter.push(assignedUserId);
    }

    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);

    case_detail
      .getFollowUpDataByActingUser(
        assignedUserId,
        orderByKey,
        paginationUrl,
        actionTypes,
        managedUserFilter,
        providerFilter,
        followUpFilter,
        medicalStatusFilter,
        caseStatusFilter,
        lawFirmFilter,
        startDateFilter,
        endDateFilter,
        search,
        accessToken
      )
      .then((res) => {
        setResultCount(dispatch, {
          total: res.count,
          current: res.results.length,
        });

        setfetchInProgress(dispatch, res.results);
        res.results.forEach((item) => {
          let newObj = {
            id: item.id,
            fieldName: item.id,
            client: {
              id: item.on_case.client.pk,
              name: item.on_case.client.name,
            },
            caseId: item.on_case.id,
            clientId: item.on_case.client.pk,
            label:
              item.action_type === ACTION_TYPE.CLIENT_FOLLOW_UP
                ? item[getActionTypeKey(ACTION_TYPE.CLIENT_FOLLOW_UP)].client
                    .name
                : item[getActionTypeKey(item.action_type)].name,
            actionType: item.action_type,
            action_detail: item.action_detail,
            relative_due_date: item.relative_due_date,
            start: item.due_date,
            due_date: item.due_date,
            clock_state: item.clock_state,
            status: item.status,
            createdBy: item.created_by,
            updatedAt: item.updated_at,
            updatedBy: item.updated_by,
            completedAt: item.completed_at,
            completedBy: item.completed_by,
            ...(item.action_type === ACTION_TYPE.LAW_FIRM_FOLLOW_UP
              ? { lawFirmId: item.on_firm.pk }
              : {}),
            ...(item.action_type === ACTION_TYPE.LAW_FIRM_FOLLOW_UP
              ? {
                  lawFirm: {
                    id: item.on_firm.pk,
                    name: item.on_firm.name,
                    phone: item.on_firm.phone,
                  },
                }
              : {}),
            ...(item.action_type === ACTION_TYPE.PROVIDER_FOLLOW_UP
              ? {
                  provider: {
                    id: item.on_provider.pk,
                    name: item.on_provider.name,
                    phone: item.on_provider.phone,
                  },
                }
              : {}),
          };
          eventsArr.push(newObj);
        });

        setCalendarEvents(dispatch, eventsArr);
        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [
    assignedUserId,
    search,
    managedUserFilter,
    providerFilter,
    followUpFilter,
    medicalStatusFilter,
    caseStatusFilter,
    lawFirmFilter,
    startDateFilter,
    endDateFilter,
    actionTypes,
    orderByKey,
    paginationUrl,
    accessToken,
    dispatch,
  ]);

  return [fetchAllFollowUpData];
}
