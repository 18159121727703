import React, { useState } from "react";
import { Button, Link, Tooltip } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { ContentCopy } from "@mui/icons-material";

function ContactControlsMobile({ number }) {
  const [copiedAlert, setCopiedAlert] = useState(`Copy`);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedAlert(`Copied Phone Number`);
  };

  return (
    <div className="mx-auto flex my-2">
      <Link
        href={`tel:${number}`}
        target="_blank"
        rel="noreferrer"
        className="border-green-600 border-2 mr-1 px-5 py-1 rounded-md text-green-600 hover:bg-green-600 hover:text-white"
      >
        <PhoneIcon />
      </Link>
      <Tooltip title={copiedAlert}>
        {" "}
        <Button
          onClick={() => copyToClipboard(number)}
          variant="outlined"
          className="border-sky-600 border-2 px-5 text-sky-600 hover:bg-sky-600 hover:text-white rounded-md"
          onMouseLeave={() => {
            setCopiedAlert("Copy");
          }}
        >
          <ContentCopy />
        </Button>
      </Tooltip>
    </div>
  );
}

export default ContactControlsMobile;
