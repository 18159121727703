import { useState, useEffect, useContext } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import { inputStyles, selectStyles } from "../../constant";
import FormField from "../../../../../global/FormComponents/field/FormField";
import Modal from "../../../../../global/Modal";
import { CalendarMonth } from "@mui/icons-material";

export default function CreateAppointmentv3({
  setOpenAddAppts,
  activeProvObj,
  caseInfo,
  open,
  onClose,
  title,
}) {
  const { accessToken, selectedClient, trigger, setTrigger, loggedInUser } =
    useContext(DataContext);
  const [dateTimeVal, setDateTimeValue] = useState();
  const [value, setValue] = useState();
  const [scheduledApptStatus, setScheduledApptStatus] = useState("");
  const [scheduledApptNotes, setScheduledApptNotes] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerList, setProviderList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (newValue) => {
    if (newValue === null || newValue === undefined) {
      setValue("");
    } else {
      setDateTimeValue(new Date(newValue).toISOString());
    }
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenAddAppts(false);
    setValue("");
    setDateTimeValue("");
    setScheduledApptStatus("");
    setScheduledApptNotes("");
    setSelectedProvider("");
    setTrigger(!trigger);
  };

  const isProvider =
    loggedInUser.entity && loggedInUser.entity.entity_type_label === "Provider";

  //  UseEffects
  useEffect(() => {
    if (caseInfo) {
      axios
        .get(`/api/pro_cli_status/?client=${caseInfo.client.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          if (isProvider) {
            data = data.filter(
              (item) => item.provider.pk === activeProvObj[item.provider.pk]
            );
          }
          setProviderList(data);
        });
    }
    // eslint-disable-next-line
  }, [selectedClient, accessToken]);

  const handleNewApptSubmit = () => {
    setLoading(true);
    let createNewAppt = {
      client: caseInfo.client.pk,
      provider: selectedProvider,
      scheduled: dateTimeVal,
      status: scheduledApptStatus,
      ...(scheduledApptNotes === "" ? {} : { notes: scheduledApptNotes }),
    };

    axios
      .post("/api/appointments/", createNewAppt, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
          setOpenError(!openError);
          setLoading(false);
        }
      });
  };
  const scheduledApptStatuses  = [
    { value: "Assigned", label: "Scheduled" },
    { value: "Overdue", label: "Missed" },
    { value: "Done", label: "Complete" },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      actions={[
        {
          label: "Create Appointment",
          onClick: handleNewApptSubmit,
          isLoading: loading,
        },
      ]}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <div className="w-full flex flex-col gap-4 justify-center bg-white">
            <FormField label="Appointment Date">
              <DesktopDateTimePicker
                value={value}
                sx={{
                  ...inputStyles,
                  "& input" :{
                    marginLeft: "-8px"
                  }
                }}
                className="w-full"
                onChange={handleChange}
                components={{
                  OpenPickerIcon: CalendarMonth,
                }}
                slotProps={{
                  textField: { variant: "outlined" },
                  inputAdornment: {
                    position: "start",
                  },
                }}
              />
            </FormField>
            <FormField label="Provider">
              <Select
                required
                className="w-full"
                id="Provider"
                sx={selectStyles}
                value={selectedProvider}
                onChange={(event) => {
                  setSelectedProvider(event.target.value);
                }}
              >
                {providerList.map((prov, idx) => {
                  return (
                    <MenuItem
                      value={prov.provider.pk}
                      key={idx}
                      onClick={() => setSelectedProvider(prov.provider.pk)}
                    >
                      {prov.provider.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormField>

            <FormField label="Appointment Status">
              <Select
                required
                className="w-full"
                id="Provider-Status"
                sx={selectStyles}
                value={scheduledApptStatus}
                onChange={(event) => {
                  setScheduledApptStatus(event.target.value);
                }}
              >
                {scheduledApptStatuses.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormField>
            <FormField label="Appointment Notes">
              <Editor
                textareaName="content"
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                value={scheduledApptNotes}
                onEditorChange={(newText) => {
                  setScheduledApptNotes(newText);
                }}
                init={{
                  menubar: false,
                  branding: false,
                  plugins: ["lists"],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                }}
              />
            </FormField>
          </div>
          <ResponseModal
            title="Appointment Created"
            description="Appointment has been added to clients case"
            openBool={openSuccess}
            setOpenBool={setOpenSuccess}
            handleCloseFunc={handleOpenSuccess}
          />
          <ResponseModal
            title="Error Creating Appointment"
            isError={true}
            openBool={openError}
            setOpenBool={setOpenError}
            errorMessage={errorArray}
          />
        </div>
      </LocalizationProvider>
    </Modal>
  );
}
