import { useContext, useState, useEffect } from "react";
import ManagedUserFilter from "./components/customfilters/ManagedUserFilter";
import FollowUpFilter from "./components/customfilters/FollowUpFilter";
import CaseStatusFilter from "./components/customfilters/CaseStatusFilter";
import MedicalStatusFilter from "./components/customfilters/MedicalStatusFilter";
import LawFirmFilter from "./components/customfilters/LawFirmFilter";
import ProviderFilter from "./components/customfilters/ProviderFilter";
import { ArrowForwardIos } from "@mui/icons-material";
import { Tooltip, IconButton, List } from "@mui/material";
import FilterContext from "../../context/FilterContext";
import DataContext from "../../../../../../../../../context/DataContext";
import PermissionWrapper from "../../../../../../../../global/PermissionWrapper";

export default function Filters(
  {
    isDashboardVersion,
    setFilterState,
  }
)
{
    const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
    const {loggedInUser, userRoles } = useContext(DataContext);
    
    const {
      selectedUsers,
      setSelectedUsers,
      activeUsers,
      setActiveUsers,
      selectedProviders,
      setSelectedProviders,
      activeProviders,
      setActiveProviders,
      selectedFollowUps,
      setSelectedFollowUps,
      activeFollowUps,
      setActiveFollowUps,
      selectedMedicalStatus,
      setSelectedMedicalStatus,
      activeMedicalStatus,
      setActiveMedicalStatus,
      selectedCaseStatus,
      setSelectedCaseStatus,
      activeCaseStatus,
      setActiveCaseStatus,
      selectedLawFirms,
      setSelectedLawFirms,
      activeLawFirms,
      setActiveLawFirms,
      setPaginationUrl,
      setCurrentPage,
    } = useContext(FilterContext);
  
    useEffect(() => {
      if (!selectedUsers.length) {
        setSelectedUsers([loggedInUser.pk]);
        setActiveUsers([loggedInUser.name]);
      } else if (
        selectedUsers.length > 1 &&
        selectedUsers.includes(loggedInUser.pk)
      ) {
        setSelectedUsers(
          selectedUsers.filter((item) => item !== loggedInUser.pk)
        );
        setActiveUsers(activeUsers.filter((item) => item !== loggedInUser.name));
      }
    }, [
      setSelectedUsers,
      loggedInUser,
      setActiveUsers,
      activeUsers,
      selectedUsers,
    ]);
  
    const resetPagination = () => {
      setPaginationUrl("");
      setCurrentPage(1);
    };

    return(
        <div className={`text-center 
          ${
            isDashboardVersion
              ? !toggleFilterMenu
                ? "shadow-lg lg:w-[30%] bg-white z-10"
                : "w-fit h-fit"
              : toggleFilterMenu
                ? "lg:w-[30%] bg-white z-10"
                : "w-fit h-fit"
          }`}
          >
              {!toggleFilterMenu || isDashboardVersion ? (
                    
                    <div className={`${!toggleFilterMenu ?'w-full':'w-fit'} flex flex-row items-start`}>
                        <Tooltip
                        title={`${
                          toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                        }`}
                      >
                        <IconButton
                          onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
                          className={`m-1 p-4 ${!toggleFilterMenu ? "rotate-180":""}`}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </Tooltip>
                      {!toggleFilterMenu?
                      <div className="flex flex-col w-full h-fit">
                        <h1 className={`text-xl text-purple-600 text-center m-4 font-bold flex-1`}>Filters</h1>
                        <div className="bg-white z-10 overflow-y-auto">
                          <List>
                            <PermissionWrapper
                              permission={
                                userRoles.permissions.includes("users.view_user")
                              }
                            >
                                <ManagedUserFilter 
                                  selectedItems = {selectedUsers} 
                                  setSelectedItems = {setSelectedUsers} 
                                  setActiveItems = {setActiveUsers} 
                                  activeItems = {activeUsers} 
                                  resetPagination = {resetPagination}
                                  setFilterState = {setFilterState}
                                />
                            </PermissionWrapper>
                            <ProviderFilter 
                              selectedItems = {selectedProviders} 
                              setSelectedItems = {setSelectedProviders} 
                              setActiveItems = {setActiveProviders} 
                              activeItems = {activeProviders} 
                              resetPagination = {resetPagination} 
                              setFilterState = {setFilterState}
                            />
                            <FollowUpFilter 
                              selectedItems = {selectedFollowUps} 
                              setSelectedItems = {setSelectedFollowUps} 
                              setActiveItems = {setActiveFollowUps} 
                              activeItems = {activeFollowUps} 
                              resetPagination = {resetPagination} 
                              setFilterState = {setFilterState}
                            />
                            <MedicalStatusFilter 
                              selectedItems = {selectedMedicalStatus} 
                              setSelectedItems = {setSelectedMedicalStatus} 
                              setActiveItems = {setActiveMedicalStatus} 
                              activeItems = {activeMedicalStatus} 
                              resetPagination = {resetPagination} 
                              setFilterState = {setFilterState}
                            />
                            <CaseStatusFilter 
                              selectedItems = {selectedCaseStatus} 
                              setSelectedItems = {setSelectedCaseStatus} 
                              setActiveItems = {setActiveCaseStatus} 
                              activeItems = {activeCaseStatus} 
                              resetPagination = {resetPagination} 
                              setFilterState = {setFilterState}
                            />
                            <LawFirmFilter 
                              selectedItems = {selectedLawFirms} 
                              setSelectedItems = {setSelectedLawFirms} 
                              setActiveItems = {setActiveLawFirms} 
                              activeItems = {activeLawFirms} 
                              resetPagination = {resetPagination} 
                              setFilterState = {setFilterState}
                            />
                          </List>
                        </div>
                      </div>
                      :
                      ''}
                    </div>
                    ) : (
                      ""
                    )}
            </div>
    )
};