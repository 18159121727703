import React from "react";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "./TooltipStyling";
import SelectedFollowUpView from "../SelectedFollowUpView";

const InfoToolTip = ({
  id,
  headerTitle,
  selectedFollowUp,
  navigateToDetail,
  children,
}) => {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <HtmlInfoTooltip
      title={
        <>
          {headerTitle && (
            <div>
              {headerTitle}
              <hr />
            </div>
          )}
          {selectedFollowUp && (
            <div>
              {
                <SelectedFollowUpView
                  title={headerTitle}
                  selectedFollowUp={selectedFollowUp}
                  navigateToDetail={navigateToDetail}
                />
              }
              <hr />
            </div>
          )}
        </>
      }
    >
      {children}
    </HtmlInfoTooltip>
  );
};

export default InfoToolTip;
