import React, { useContext, useReducer, useState } from "react";
import { addUserReducer, initialState } from "./state/addUserReducer";
import Form from "./components/Form";
import { Button, IconButton } from "@mui/material";
import { AddUserStateContext } from "./context/AddUserStateContext";
import { AddUserDispatchContext } from "./context/AddUserDispatchContext";
import { toggleSuccessUI, toggleErrorUI, setAPIErrors } from "./state/actions";
import Success from "./components/Success";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import { Close, ControlPointOutlined } from "@mui/icons-material";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";

export default function AddUser({
  permissionSets,
  submitForm,
  close,
  isStandalone,
  reloadTrigger,
  setReloadTrigger,
}) {
  /**
   * AddUser Component
   *  - Communicates with User Profile Manager.
   *  - Handles Form States, Form Data(User Entered and default values for fields) and API Requests
   *  -- Loading - Renders Loading when Form is busy/not ready to be rendered
   *  -- Form Init - Initiates and Renders Form when ready
   *  -- Form Submit - Gathers Data and Submits User to Backend
   *  -- Success - Shows SUCCESS Message upon Success Submission to API
   *  -- Error - Shows ERROR Message upon unsuccessful Submission to API
   */
  const STRINGS = {
    TITLE: "New Application User",
    SUCCESS: "User Creation Successful!",
    ERROR: "User Creation Failed!",
  };

  const { accessToken } = useContext(DataContext);
  const [state, dispatch] = useReducer(addUserReducer, initialState);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSuccessUI = () => {
    dispatch({ type: "CLEAR_USER" });
    toggleSuccessUI(dispatch, false);
    if (setReloadTrigger) {
      setReloadTrigger(!reloadTrigger);
    }
  };

  const handleErrorUI = () => toggleErrorUI(dispatch, !state.toggleErrorUI);

  const renderSuccess = () => <Success handleOpenSuccess={handleSuccessUI} />;

  const unMountComponent = () => close();

  // Handle adding user to prov relations
  const postLocationPromise = (location, userInfo) => {
    new Promise((resolve, reject) => {
      axios
        .post(
          `/api/user_provider/`,
          {
            user: userInfo.pk,
            provider: location.pk,
            role: selectedRole.pk,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${accessToken}`,
            },
          }
        )
        .then(() => {
          resolve("Posted");
        })
        .catch((error) => {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setIsError(true);
          setResponseModal(true);
          setResponseBreakdown(errArr);
          reject("Failed");
        });
    });
  };

  const postLocations = (userInfo) => {
    let postCount = 0;
    Promise.all(
      selectedLocations.map((location) => {
        postCount = postCount + 1;
        postLocationPromise(location, userInfo);
        return postCount;
      })
    )
      .then(() => {
        if (postCount === selectedLocations.length) {
          setResponseModal(true);
          setResponseBreakdown("User to Provider Locations added");
          setIsError(false);
          setSelectedRole("");
          setSelectedLocations([]);
        }
      })
      .catch(() => {
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(["An error occured, please try again"]);
      });
  };

  const handleSubmitForm = async (e) => {
    setLoading(true);
    if (
      (selectedLocations.length > 0 && !selectedRole) ||
      (selectedRole && !selectedLocations.length)
    ) {
      alert(
        "Please add details highlighted in red, or remove location assignment details from user"
      );
      setLoading(false);
    } else {
      axios
        .post(`/api/users/`, state.newUserForm, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        })
        .then((res) => {
          toggleSuccessUI(dispatch, true);
          setLoading(false);
          if (selectedLocations.length > 0) {
            postLocations(res.data);
          }
          return res;
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response.data);
          setAPIErrors(dispatch, errArr);
          setLoading(false);
          handleErrorUI();
        });
    }
  };
  
  const closeResModal = () => {
    setResponseModal(false);
    setSelectedLocations([]);
    setSelectedRole("");
  };

  return (
    <AddUserStateContext.Provider value={state}>
      <AddUserDispatchContext.Provider value={dispatch}>
        <div data-testid="Add-User" className="bg-white p-4">
          <div className="flex justify-between">
            <div>&nbsp;</div>
            <h1 className="text-2xl py-2 text-center text-purple-600 uppercase font-bold">
              {STRINGS.TITLE}
            </h1>
            {isStandalone ? (
              <div>&nbsp; </div>
            ) : (
              <IconButton
                data-testid="button-close"
                onClick={(e) => unMountComponent(e)}
              >
                <Close />
              </IconButton>
            )}
          </div>

          {!state.toggleSuccessUI ? (
            <div>
              <Form
                permission_set_list={permissionSets}
                onClickSubmit={handleSubmitForm}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                loading={loading}
              />
            </div>
          ) : (
            <div className="mx-auto">
              {renderSuccess()}
              {isStandalone ? (
                <div className="my-2 mx-auto w-fit">
                  <Button
                    variant="outlined"
                    onClick={() => handleSuccessUI()}
                    startIcon={<ControlPointOutlined />}
                    className="text-green-800 border-green-800 hover:bg-green-800 hover:text-white hover:border-green-800"
                  >
                    Add another user
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <ResponseModal
            title={STRINGS.ERROR}
            isError={true}
            openBool={state.toggleErrorUI}
            setOpenBool={handleErrorUI}
            errorMessage={state.errorArray}
          />
          <ResponseModal
            title={`${isError ? "Failed" : "Successful"}`}
            isError={isError}
            description={isError ? "" : responseBreakdown}
            openBool={responseModal}
            setOpenBool={setResponseModal}
            errorMessage={responseBreakdown}
            handleCloseFunc={closeResModal}
          />
        </div>
      </AddUserDispatchContext.Provider>
    </AddUserStateContext.Provider>
  );
}
