import { InputLabel, Button } from "@mui/material";
import AvailabilityHourWindow from "./AvailabilityHourWindow";
import { useState } from "react";

export default function AvailabilityTableRow({
  day,
  dayKey,
  defaultValues,
  client,
  trigger,
  setTrigger,
}) {
  const [formValues, setFormValues] = useState([]);

  const addHourWindow = () => {
    setFormValues([...formValues, { start_time: "", end_time: "" }]);
  };

  const handleCancelEdit = () => {
    setFormValues([]);
  };

  return (
    <div className="flex flex-col shadow-md m-1 py-4 px-1">
      <InputLabel className="text-sm font-bold p-1">{day}</InputLabel>
      {defaultValues &&
      defaultValues.length === 0 &&
      formValues &&
      formValues.length === 0 ? (
        <InputLabel className="text-sm text-center p-1 m-1 bg-gray-100 rounded-md">
          {"No Availability"}
        </InputLabel>
      ) : (
        defaultValues.map((item) => {
          return (
            <AvailabilityHourWindow
              activeWindow={item}
              handleCancelEdit={handleCancelEdit}
              dayKey={dayKey}
              trigger={trigger}
              setTrigger={setTrigger}
              client={client}
              newField={false}
            />
          );
        })
      )}
      {formValues && formValues.length === 0
        ? ""
        : formValues.map((_, index) => {
            return (
              <AvailabilityHourWindow
                index={index}
                setFormValues={setFormValues}
                formValues={formValues}
                handleCancelEdit={handleCancelEdit}
                dayKey={dayKey}
                trigger={trigger}
                setTrigger={setTrigger}
                client={client}
                newField={true}
              />
            );
          })}

      <Button onClick={() => addHourWindow()} className="m-2">
        Add New Hour
      </Button>
    </div>
  );
}
