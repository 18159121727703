import { add } from "date-fns";
import FollowUpFormProvider from "./context/FollowUpFormContext";
import Form from "./components/Form";

import { initialState } from "./state/followUpFormReducer";
import { emptyField } from "./components/emptyField";

const FollowUpForm = ({
  selectedFollowUp,
  submitForm,
  title,
  buttonsArr,
  isProviderFollowUp,
  isProviderEditable,
  providerOptions,
  setGetProCliOptions,
  isFormTouched,
}) => {
  const isFormValid = (fields) => {
    let isValid = true;
    Object.keys(fields).forEach((field) => {
      if (!fields[field].isValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  const getInitialState = () => {
    let init = {
      dueDate: { ...emptyField },
      detail: { ...emptyField },
      ...(isProviderFollowUp ? { provider: { ...emptyField } } : {}),
    };
    //field will always load with due date and will always be valid
    init.dueDate.isValid = true;
    //set provider field required if is create provider form
    if (isProviderFollowUp && isProviderEditable) {
      init.provider.required = true;
      init.provider.isValid = false;
    }

    let emptyInitialState = {
      isValid: true,
      isTouched: true,
      isFormInitialized: true,
      fields: init,
    };
    if (!isProviderFollowUp) {
      emptyInitialState.isTouched = true;
    }
    

    if (selectedFollowUp) {
      emptyInitialState.isTouched = true;
      emptyInitialState.isValid = true;

      Object.keys(emptyInitialState.fields).forEach((field) => {
        emptyInitialState.fields[field].isTouched = true;
        emptyInitialState.fields[field].isValid = true;
        if(selectedFollowUp.fields[field]){
          if(selectedFollowUp.fields[field].id){
          emptyInitialState.fields[field].value = selectedFollowUp.fields[field].id;
          emptyInitialState.fields[field].displayValue = selectedFollowUp.fields[field].name;
        }else{
          emptyInitialState.fields[field].value = selectedFollowUp.fields[field];
        emptyInitialState.fields[field].displayValue = selectedFollowUp.fields[field];
        }
      }
        

      });
    }
    if (emptyInitialState.fields.dueDate.value === "") {
      emptyInitialState.fields.dueDate.value = add(new Date(), { days: 1 });
    }
    emptyInitialState.isValid = isFormValid(emptyInitialState.fields);
    return emptyInitialState;
  };

  return (
    <FollowUpFormProvider initialState={getInitialState()}>
      <div id="Follow-Up-Form" data-testid="Follow-Up-Form">
        <Form
          submitForm={submitForm}
          title={title}
          buttonsArr={buttonsArr}
          isProviderFollowUp={isProviderFollowUp}
          isProviderEditable={isProviderEditable}
          providerOptions={providerOptions}
          setGetProCliOptions={setGetProCliOptions}
          isFormTouched={isFormTouched}
        />
      </div>
    </FollowUpFormProvider>
  );
};
export default FollowUpForm;
