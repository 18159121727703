import React, { useContext, useEffect, useState } from "react";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import qs from "query-string";
import NearestProvidersList from "./NearestProviderList";
import InteractiveMap from "./InteractiveMap/index";
import ClientSelect from "./ClientSelect";

export default function ProviderMap() {
  const { accessToken, userRoles } = useContext(DataContext);
  const [addressType, setAddressType] = useState("alt");
  const [address, setAddress] = useState({
    lat: 34.03671,
    lng: -84.07817,
  });
  const [activeClient, setActiveClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [nearestProviders, setNearestProviders] = useState([]);
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [clientListResponse, setClientListResponse] = useState([]);
  const [clientSearch, setClientSearch] = useState("");
  const [provType, setProvType] = useState("All");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [outOfNetwork, setOutOfNetwork] = useState(false);
  const [maxDistance, setMaxDistance] = useState(null);
  const [search, setSearch] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [time, setTime] = useState("");
  const [defaultWeek, setDefaultWeek] = useState([]);
  // const [selectedSchedules, setSelectedSchedules] = useState([]);
  // const [activeSchedules, setActiveSchedules] = useState([]);

  useEffect(() => {
    if (!userRoles.permissions.includes("utilities.view_fieldoptions")) {
      return;
    } else {
      axios
        .get(
          `/api/field_options/?content_type=providerofficehours&field=day&ordering=order`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then(function (response) {
          setDefaultWeek(response.data.results);
        })
        .catch(function (error) {});
    }
  }, [accessToken, userRoles.permissions, setDefaultWeek]);

  const getNearestProviders = (type) => {
    setLoading(true);
    let apiUrl =
      type === "alt"
        ? `/api/provider/map/${address.lng}/${address.lat}/?`
        : `/api/provider/map/${activeClient.pk}/?`;
    if (!userRoles.permissions.includes("provider.view_provider")) {
      return;
    } else if (!address) {
      alert(
        "This user has no address. Please use custom address option to find locations."
      );
      setLoading(false);
      return;
    } else {
      let timeString = !time ? null : new Date(time).toTimeString();
      const querytest = qs.stringify(
        {
          type: provType === "All" ? null : provType,
          in_network: outOfNetwork ? null : true,
          max_distance: !maxDistance ? null : maxDistance,
          name__icontains: !search ? null : search,
          time: !timeString
            ? null
            : timeString.substring(0, timeString.length - 34),
          day: dayOfWeek === "" ? null : dayOfWeek,
        },
        {
          skipNull: true,
        }
      );

      // const locationsQuery =
      // selectedSchedules.length === 0
      //   ? ""
      //   : `&schedule__in=${selectedSchedules}`;

      axios
        .get(apiUrl + paginationUrl + `${querytest}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then(function (response) {
          let data = response.data.results;
          setNearestProviders(data);
          setResultCount({
            total: response.data.count,
            current: response.data.results.length,
          });
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  };

  useDebouncedEffect(
    () => {
      getNearestProviders(addressType);
    },
    [
      address,
      provType,
      outOfNetwork,
      maxDistance,
      paginationUrl,
      search,
      time,
      dayOfWeek,
    ],
    250
  );

  const filterUpdate = () => {
    setMaxDistance(false);
    setOutOfNetwork(false);
    resetPagination();
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: clientSearch === "" ? null : clientSearch,
      },
      {
        skipNull: true,
      }
    );

    axios
      .get(
        `/api/case/?` + querytest,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setClientListResponse(response.data.results);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [clientSearch],
    250
  );

  return (
    <div className="bg-white h-full flex flex-col justify-between">
      <div className="max-h-full">
        <div className="flex flex-col md:h-[50vh] md:flex-row">
          <div className="md:w-1/2">
            <ClientSelect
              clientSearch={clientSearch}
              setClientSearch={setClientSearch}
              clientListResponse={clientListResponse}
              address={address}
              setAddress={setAddress}
              setActiveClient={setActiveClient}
              activeClient={activeClient}
              setAddressType={setAddressType}
              addressType={addressType}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              filterUpdate={filterUpdate}
            />
          </div>
          <div className="w-[100%] md:w-1/2">
            <InteractiveMap providers={nearestProviders} address={address} />
          </div>
        </div>
        <NearestProvidersList
          results={nearestProviders}
          currentPage={currentPage}
          handleChange={handleChange}
          resultCount={resultCount}
          loading={loading}
          provType={provType}
          setProvType={setProvType}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          resetPagination={resetPagination}
          outOfNetwork={outOfNetwork}
          setOutOfNetwork={setOutOfNetwork}
          maxDistance={maxDistance}
          setMaxDistance={setMaxDistance}
          search={search}
          setSearch={setSearch}
          time={time}
          setTime={setTime}
          defaultWeek={defaultWeek}
          dayOfWeek={dayOfWeek}
          setDayOfWeek={setDayOfWeek}
          filterUpdate={filterUpdate}
        />
      </div>
    </div>
  );
}
