import AvailabilityTableRow from "./AvailabilityTableRow";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import { useEffect, useState, useContext } from "react";

export default function AvailabilityTable({ caseObj }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [operationHours, setOperationHours] = useState([]);
  const [defaultWeek, setDefaultWeek] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    axios
      .get(
        `/api/field_options/?content_type=usercontacthours&field=day&ordering=order`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setDefaultWeek(response.data.results);
      })
      .catch(function (error) {
        return error;
      });
  }, [accessToken, userRoles.permissions, setDefaultWeek, trigger]);

  const createHoursArr = (data, valKey) => {
    let arr = [];
    if (data.length === 0) {
      return arr;
    } else {
      for (const item in data) {
        if (valKey === data[item].day) {
          arr.push(data[item]);
        }
      }
    }
    return arr;
  };

  useEffect(() => {
    axios
      .get(`api/user_contact_hours/?user=${caseObj.client.pk}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        let data = response.data.results;
        setOperationHours(data);
      })
      .catch(function (error) {
        return error;
      });
  }, [caseObj, accessToken, trigger, userRoles.permissions]);

  return !defaultWeek ? (
    ""
  ) : (
    <div>
      {defaultWeek.map((day) => {
        return (
          <AvailabilityTableRow
            key={day.key}
            day={day.label}
            dayKey={day.key}
            defaultValues={createHoursArr(operationHours, day.key)}
            client={caseObj.client.pk}
            setTrigger={setTrigger}
            trigger={trigger}
          />
        );
      })}
    </div>
  );
}
