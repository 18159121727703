import MultiSelectFilterV3 from "./MultiSelectFilterV3";
import { ListItem } from "@mui/material";
export default function FollowUpFilter({
  selectedItems,
  setSelectedItems,
  setActiveItems,
  activeItems,
  resetPagination,
  setFilterState,
}
)
{

  const setSelectedState = (selectedValues) => {
    setSelectedItems(selectedValues);
    setFilterState({ fieldName: "followUpFilter", data: selectedValues });
  };

    return(
        <ListItem>
        <div className="shadow p-2 w-full">
            <MultiSelectFilterV3
            title="Filter by Follow Up Types"
            selectedItems={selectedItems}
            setSelectedItems={setSelectedState}
            setActiveItems={setActiveItems}
            activeItems={activeItems}
            nameKey="label"
            valueKey="key"
            resetPagination={resetPagination}
            searchTitle="Follow Ups"
            apiUrl = {`/api/field_options/?content_type=actionableitems&field_name=action_type&`}
            buttonFullW={true}
            dropdown={false}
            filterName={'followUpFilter'}
            setFilterState={setFilterState}
          />
        </div>
      </ListItem>
    );

}