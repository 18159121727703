import indicatorColors from "./IndicatorColors";

export const getColor = (value) => {
  switch (value) {
    case 1:
      return indicatorColors.green;
    case 2:
      return indicatorColors.yellow;
    case 3:
      return indicatorColors.red;
    case 4:
      return indicatorColors.orange;
    case null:
      return indicatorColors.black;
    default:
      return indicatorColors.black;
  }
};

export const getTailwindColorStr = (value) => {
  switch (value) {
    case 1:
      return 'green';
    case 2:
      return 'yellow';
    case 3:
      return 'red';
    case 4:
      return 'orange';
    case null:
      return 'black';
    default:
      return 'black';
  }
};