import { Add, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useId, useState } from "react";
import { useEffect } from "react";
import MessageDialog from "./MessageDialog";

const validImageTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/svg+xml",
  "image/tiff",
  "image/x-icon",
  "image/vnd.microsoft.icon"
];

function UploadImage({
  fileUrl = "",
  annotation,
  onUploadFile,
  maxSizeByMB = 0
}) {
  const [previewUrl, setPreviewUrl] = useState("");
  const [error, setError] = useState("");
  const id = useId();

  const isUploaded = !previewUrl.includes("blob");
  const maxFileSize = maxSizeByMB * 1024 * 1024;

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (file) {
      if (
        (maxFileSize && file.size > maxFileSize) ||
        !validImageTypes.includes(file.type)
      ) {
        setError(
          "The uploaded file is either of an unsupported type or exceeds the allowed size limit. Please upload a valid file."
        );
        return;
      }
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
      onUploadFile(file);
      setError("");
    }
  };

  const handleRemoveImage = () => {
    onUploadFile(null);
    setPreviewUrl("");
  };

  useEffect(
    () => {
      if (fileUrl) {
        setPreviewUrl(fileUrl);
      }
    },
    [fileUrl]
  );

  return (
    <div className="border border-solid border-gray-400 rounded-xl overflow-hidden h-fit">
      <div className="relative">
        {!previewUrl
          ? <div className="w-full aspect-video flex justify-center items-center">
              <label htmlFor={id}>
                <input
                  className="hidden"
                  id={id}
                  type="file"
                  accept={validImageTypes}
                  onChange={handleImageChange}
                />
                <div className="flex flex-col items-center font-bold">
                  <IconButton component="span" color="primary">
                    <Add fontSize="large" />
                  </IconButton>
                  <p>Upload</p>
                </div>
              </label>
            </div>
          : <div className="aspect-video">
              <img
                src={previewUrl}
                alt="Preview"
                className="object-contain h-full m-auto"
              />
              {!isUploaded &&
                <IconButton
                  color="error"
                  onClick={handleRemoveImage}
                  className="absolute top-1 right-1 p-1 text-white bg-gray-500 bg-opacity-50"
                >
                  <Close className="text-sm" />
                </IconButton>}
            </div>}
      </div>
      <div className="p-4 border-t border-solid">
        <p className="font-bold text-sm text-center">
          {annotation}
        </p>
      </div>
      {error &&
        <MessageDialog
          open
          isError
          message={error}
          onClose={() => setError("")}
          title="Upload Failed"
        />}
    </div>
  );
}

export default UploadImage;
