import { Tooltip, IconButton, Button, CircularProgress } from "@mui/material";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState, useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import ModalShell from "../../../../../../global/ModalShell";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InlineResponse from "./InlineReponse";

export default function AvailabilityHourWindow({
  index,
  activeWindow,
  client,
  dayKey,
  trigger,
  setTrigger,
  formValues,
  setFormValues,
  newField,
}) {
  const { accessToken } = useContext(DataContext);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr[0]);
    setResponseSuccess(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
  };

  const handleApiSuccess = (response) => {
    setLoading(false);
    const res = response;
    setTrigger(!trigger);
    setResponseSuccess(true);
    setToggleEdit(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const postNewTimeWindow = () => {
    setLoading(true);
    let start = new Date(startTime).toTimeString();
    let end = new Date(endTime).toTimeString();
    const postData = {
      user: client,
      time_start: start.substring(0, start.length - 34),
      time_end: end.substring(0, end.length - 34),
      day: dayKey,
    };

    axios
      .post(`/api/user_contact_hours/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setResponseBreakdown("Client Availability Added");
        handleApiSuccess(response);
        setTimeout(() => {
          removeFormFields(index);
        }, 5000);
      })
      .catch((error) => {
        console.log("In error");
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setResponseBreakdown(errArr[0]);
        handleApiError(error);
      });
  };

  const patchExistingTimeWindow = () => {
    setLoading(true);
    let start = new Date(startTime).toTimeString();
    let end = new Date(endTime).toTimeString();

    const patchData = {
      day: dayKey,
      time_start: !startTime
        ? activeWindow.start_time
        : start === "Invalid Date"
        ? ""
        : start.substring(0, start.length - 34).length === 0
        ? activeWindow.start_time
        : start.substring(0, start.length - 34),
      time_end: !endTime
        ? activeWindow.end_time
        : end === "Invalid Date"
        ? ""
        : end.substring(0, end.length - 34).length === 0
        ? activeWindow.end_time
        : end.substring(0, end.length - 34),
      user: client,
    };

    axios
      .patch(`/api/user_contact_hours/${activeWindow.id}/`, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setResponseBreakdown("Client Availability Updated");
        handleApiSuccess(response);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setResponseBreakdown(errArr[0]);
        handleApiError(error);
      });
  };

  const deleteExistingTimeWindow = () => {
    axios
      .delete(`/api/user_contact_hours/${activeWindow.id}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseBreakdown("Client Availability Deleted");
        setToggleEdit(false);
        setTrigger(!trigger);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setResponseBreakdown(errArr);
        handleApiError(error);
      });
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <>
      <div className={`${!toggleEdit && !newField ? "border-y-2  m-1" : ""}`}>
        {!toggleEdit && !newField ? (
          <div className="flex flex-col justify-between items-start">
            <div className="flex flex-row justify-between items-center w-full">
              <p key={index} className="text-center text-xs p-2">
                {new Date(
                  `2024-06-25T${activeWindow.time_start}`
                ).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {new Date(
                  `2024-06-25T${activeWindow.time_end}`
                ).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
              <IconButton onClick={() => setToggleEdit(true)}>
                <Edit className="text-gray-400 text-[18px]" />
              </IconButton>
            </div>
          </div>
        ) : responseBreakdown && responseSuccess ? (
          ""
        ) : (
          <div className="flex flex-row items-center w-full">
            <div className="mr-4 w-3/4 flex md:flex-row flex-col">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  className="my-2 mx-1 w-1/2"
                  label="Start"
                  value={
                    !activeWindow
                      ? startTime
                      : new Date(`2024-06-25T${activeWindow.time_start}`)
                  }
                  views={["hours", "minutes"]}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                  onChange={(newValue) => setStartTime(newValue)}
                />
                <TimePicker
                  className="my-2 mx-1 w-1/2"
                  label="End"
                  value={
                    !activeWindow
                      ? endTime
                      : new Date(`2024-06-25T${activeWindow.time_end}`)
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                  views={["hours", "minutes"]}
                  onChange={(newValue) => setEndTime(newValue)}
                />
              </LocalizationProvider>
            </div>

            <div className="p-0.1 w-1/4">
              <Tooltip title="Submit">
                <IconButton
                  className="text-green-600 mr-2"
                  onClick={() => {
                    !activeWindow
                      ? postNewTimeWindow(index)
                      : patchExistingTimeWindow();
                  }}
                  size="small"
                  disabled={loading}
                >
                  <Check className="text-[20px]" sx={{ fontSize: "1rem" }} />
                </IconButton>
              </Tooltip>

              {!activeWindow ? (
                ""
              ) : (
                <Tooltip title="Delete existing time window">
                  <IconButton
                    onClick={() => setConfirmDelete(true)}
                    className="text-red-600 mr-2"
                  >
                    <Delete sx={{ fontSize: "1rem" }} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Cancel">
                <IconButton
                  className="text-red-600 mr-2"
                  onClick={() =>
                    !activeWindow
                      ? removeFormFields(index)
                      : setToggleEdit(false)
                  }
                  size="small"
                >
                  <Close className="text-[20px]" sx={{ fontSize: "1rem" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <InlineResponse
            responseBreakdown={responseBreakdown}
            responseSuccess={responseSuccess}
          />
        )}
      </div>

      {!activeWindow ? (
        ""
      ) : (
        <ModalShell
          open={confirmDelete}
          setOpen={setConfirmDelete}
          color={"red"}
          title="Confirm time window removal"
        >
          <div>
            <p className="text-center">
              Are you sure you want to remove this time window?
            </p>
            <p className="text-center font-bold">
              {new Date(
                `2024-06-25T${activeWindow.time_start}`
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {new Date(
                `2024-06-25T${activeWindow.time_end}`
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
            </p>
            <div className="w-fit mx-auto my-2">
              {" "}
              <Button
                onClick={() => deleteExistingTimeWindow()}
                endIcon={<Delete />}
                variant="outlined"
                className="text-red-600 border-red-600"
                disabled={loading}
              >
                Confirm Delete
              </Button>
            </div>
          </div>
        </ModalShell>
      )}
    </>
  );
}
