import React, { useContext, useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";
import "../../../../../../../../../styles/FullCal.css";
import FollowUpModal from "../../../FollowUpModal/FollowUpModal";
import {
  FollowUpCalendarDispatchContext,
  FollowUpCalendarStateContext,
} from "../../context/FollowUpCalendarContext";
import { getColor } from "../../../CaseFollowUps/components/utils/getColor";
import { CONTENT_VIEW_FOLLOWUP } from "../../../FollowUpModal/components/modalcontent/HistoryListView/state/actions";
import { STATUS_TYPES } from "../../../CaseFollowUps/components/types/statusTypes";
import {
  CONTENT_EDIT_FOLLOWUP,
  setCaseInfo,
  setSelectedFollowUp,
  showContent,
  toggleDialog,
} from "../../state/actions";
import {
  ACTION_TYPE,
  ACTIONABLE_ITEM_TYPE_STRING,
} from "../../../types/actionTypes";
import "./CalendarView.css";
import InfoToolTip from "./components/InfoTooltip";

const CalendarView = ({
  followUpEvents,
  dataIsLoading,
  refreshCalendar,
  setDateFilterState,
}) => {
  const bgShade = 100;
  const defaultShade = 500;
  const state = useContext(FollowUpCalendarStateContext);
  const dispatch = useContext(FollowUpCalendarDispatchContext);
  const [startDate, setStartDate] = useState(new Date().toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().substring(0, 10));

  const navigateToDetail = (caseId) => {
    window.open(`/client-detail/${caseId}`, "_blank");
  };

  const renderInnerContent = (calEvent) => {
    return (
      <InfoToolTip
        headerTitle={
          ACTIONABLE_ITEM_TYPE_STRING[calEvent.event.extendedProps.actionType]
        }
        navigateToDetail={navigateToDetail}
        selectedFollowUp={{
          id: calEvent.event.extendedProps.fieldName,
          caseId: calEvent.event.extendedProps.caseId,
          clock: calEvent.event.extendedProps.clock_state,
          label: calEvent.event.extendedProps.label,
          timeTillDueDate: calEvent.relative_due_date,
          status: calEvent.event.extendedProps.status,
          actionType: calEvent.event.extendedProps.actionType,
          completedBy: calEvent.event.extendedProps.completedBy,
          completedAt: calEvent.event.extendedProps.completedAt,
          updatedBy: calEvent.event.extendedProps.updatedBy,
          updatedAt: calEvent.event.extendedProps.updatedAt,
          fields: {
            dueDate: calEvent.event.extendedProps.due_date,
            detail: calEvent.event.extendedProps.action_detail,
            ...(calEvent.event.extendedProps.actionType ===
            ACTION_TYPE.PROVIDER_FOLLOW_UP
              ? { provider: calEvent.event.extendedProps.provider }
              : {}),
          },
          client: calEvent.event.extendedProps.client,
          ...(calEvent.event.extendedProps.actionType ===
            ACTION_TYPE.LAW_FIRM_FOLLOW_UP
              ? { lawFirm: calEvent.event.extendedProps.lawFirm }
              : {}),
        }}
      >
        <div
          className="followup-item capitalize p-1 text-xs text-slate-900 w-full border-t-[2px] border-solid border-slate-500 bg-white rounded-md"
          style={{
            borderTop: calEvent.event.extendedProps.clock_state
              ? "2px solid " +
                getMainColor(calEvent.event.extendedProps.clock_state)
              : "2px solid bg-slate-500",
            backgroundColor: calEvent.event.extendedProps.clock_state
              ? getBgColor(calEvent.event.extendedProps.clock_state)
              : "bg-slate-100",
          }}
        >
          {calEvent.event.extendedProps.startDate && (
            <div>{calEvent.event.extendedProps.startDate}</div>
          )}
          <div>
            <div>{calEvent.event.extendedProps.label}</div>
          </div>
        </div>
      </InfoToolTip>
    );
  };

  const getMainColor = (value) => {
    return getColor(value, defaultShade);
  };

  const getBgColor = (value) => {
    return getColor(value, bgShade) + "50";
  };

  const showDialog = (calEvent) => {
    setCaseInfo(dispatch, {
      clientId: calEvent.event.extendedProps.clientId,
      caseId: calEvent.event.extendedProps.caseId,
      lawFirmId: calEvent.event.extendedProps.lawFirmId,
    });
    let dialogContent = CONTENT_VIEW_FOLLOWUP;

    if (calEvent.event.extendedProps.status === STATUS_TYPES.PENDING) {
      dialogContent = CONTENT_EDIT_FOLLOWUP;
    }
    showContent(dispatch, dialogContent);
    toggleDialog(dispatch, true);

    setSelectedFollowUp(dispatch, {
      id: calEvent.event.extendedProps.fieldName,
      clock: calEvent.event.extendedProps.clock_state,
      label: calEvent.event.extendedProps.label,
      timeTillDueDate: calEvent.relative_due_date,
      status: calEvent.event.extendedProps.status,
      actionType: calEvent.event.extendedProps.actionType,
      completedBy: calEvent.event.extendedProps.completedBy,
      completedAt: calEvent.event.extendedProps.completedAt,
      updatedBy: calEvent.event.extendedProps.updatedBy,
      updatedAt: calEvent.event.extendedProps.updatedAt,
      fields: {
        dueDate: calEvent.event.extendedProps.due_date,
        detail: calEvent.event.extendedProps.action_detail,
        ...(calEvent.event.extendedProps.actionType ===
        ACTION_TYPE.PROVIDER_FOLLOW_UP
          ? { provider: calEvent.event.extendedProps.provider }
          : {}),
      },
      client: calEvent.event.extendedProps.client,
      ...(calEvent.event.extendedProps.actionType ===
        ACTION_TYPE.LAW_FIRM_FOLLOW_UP
          ? { lawFirm: calEvent.event.extendedProps.lawFirm }
          : {}),
    });
  };

  const handleEventClick = (props) => {
    showDialog(props);
  };

  const handleDateSelect = () => {
    // For future add followup enhancement
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if(state.startDateFilter !== startDate || state.endDateFilter !== endDate){
        let date = {
          startDate:startDate,
          endDate: endDate
        };
        setDateFilterState(date);
        document.querySelector(".fc-now-indicator")?.scrollIntoView({block: "center"});

      }
    }
    return () => {
      active = false;
    };
  }, [startDate, endDate, state.endDateFilter, state.startDateFilter, setDateFilterState]);

  return (
    <div
      id="FollowUp-CalendarView-Container"
      data-testid="FollowUp-CalendarView-Container"
    >
      
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="mb-10 mt-4 w-full">
            <div className="l:w-[90%] md:mx-auto mx-2">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridDay"
                nowIndicator={true}
                nowIndicatorClassNames={"fc-now-indicator"}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "timeGridDay,timeGridWeek,dayGridMonth",
                }}
                datesSet={(dateInfo) => {
                  setStartDate(
                    new Date(dateInfo.start).toISOString().substring(0, 10)
                  ); //start of the range the calendar date
                  
                  setEndDate(
                    new Date(dateInfo.end).toISOString().substring(0, 10)
                  ); //end of the range the calendar date
                  
                }}
                events={followUpEvents}
                selectable={true}
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventContent={renderInnerContent}
                eventMaxStack={1}
              />
            </div>
          </div>
        </LocalizationProvider>
      
      {state.selectedFollowUp && state.selectedFollowUp.actionType && (
        <FollowUpModal
          divId={"Follow-Up-Calendar-Event"}
          title={ACTIONABLE_ITEM_TYPE_STRING[state.selectedFollowUp.actionType]}
          actionType={state.selectedFollowUp.actionType}
          state={state}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};

export default CalendarView;
