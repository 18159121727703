import { useContext } from "react";
import FilterContext from "../../context/FilterContext";
import { CustomChip } from "../../../../../../../../global/CustomQueryChip";
import PermissionWrapper from "../../../../../../../../global/PermissionWrapper";
import DataContext from "../../../../../../../../../context/DataContext";
import { Chip } from "@mui/material";

export default function Chips({currentSearchValue, setSearchState})
{
    const {userRoles} = useContext(DataContext);

    const {
        selectedUsers,
        setSelectedUsers,
        activeUsers,
        setActiveUsers,
        selectedProviders,
        setSelectedProviders,
        activeProviders,
        setActiveProviders,
        selectedFollowUps,
        setSelectedFollowUps,
        activeFollowUps,
        setActiveFollowUps,
        selectedMedicalStatus,
        setSelectedMedicalStatus,
        activeMedicalStatus,
        setActiveMedicalStatus,
        selectedCaseStatus,
        setSelectedCaseStatus,
        activeCaseStatus,
        setActiveCaseStatus,
        selectedLawFirms,
        setSelectedLawFirms,
        activeLawFirms,
        setActiveLawFirms,
        setPaginationUrl,
        setCurrentPage,
      } = useContext(FilterContext);

      const resetPagination = () => {
        setPaginationUrl("");
        setCurrentPage(1);
      };

      const clearQuery = () => {
        setSearchState("");
        if (resetPagination) {
          resetPagination();
        }
      };

      return(
        <div className="m-2">
        <PermissionWrapper
            permission={
              userRoles.permissions.includes("users.view_user")}
        >
          <CustomChip
            setActiveItems={setActiveUsers}
            activeItems={activeUsers}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            title="Users"
            resetPagination={resetPagination}
          />
        </PermissionWrapper>
        <CustomChip
          setActiveItems={setActiveProviders}
          activeItems={activeProviders}
          selectedItems={selectedProviders}
          setSelectedItems={setSelectedProviders}
          title="Providers"
          resetPagination={resetPagination}
        />
        <CustomChip
          setActiveItems={setActiveFollowUps}
          activeItems={activeFollowUps}
          selectedItems={selectedFollowUps}
          setSelectedItems={setSelectedFollowUps}
          title="Follow Ups"
          resetPagination={resetPagination}
        />
        <CustomChip
          setActiveItems={setActiveMedicalStatus}
          activeItems={activeMedicalStatus}
          selectedItems={selectedMedicalStatus}
          setSelectedItems={setSelectedMedicalStatus}
          title="Medical Status"
          resetPagination={resetPagination}
        />
        <CustomChip
          setActiveItems={setActiveCaseStatus}
          activeItems={activeCaseStatus}
          selectedItems={selectedCaseStatus}
          setSelectedItems={setSelectedCaseStatus}
          title="Case Status"
          resetPagination={resetPagination}
        />
        <CustomChip
          setActiveItems={setActiveLawFirms}
          activeItems={activeLawFirms}
          selectedItems={selectedLawFirms}
          setSelectedItems={setSelectedLawFirms}
          title="Law Firm"
          resetPagination={resetPagination}
        />
        {
        
        currentSearchValue && currentSearchValue.trim()?
          <Chip 
          className={`px-2 m-1 text-left rounded-lg shadow-inner hover:bg-red-400 py-[5px]`}
          label={"Search: "+currentSearchValue}
          onClick={() => clearQuery()}
          onDelete={() => clearQuery()}
          sx={{
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
            },
          }}/>
        : 
          ""
        }
        </div>
      )
}