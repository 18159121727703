const validateField = (isRequired, newFieldData) => {
  let isValid = true;
  if (isRequired) {
    if(typeof newFieldData == 'undefined'){
      isValid = false;
    }
    if (newFieldData.toString() === "" || newFieldData.toString() === " ") {
      isValid = false;
    }
  }
  return isValid;
};

export default validateField;
