import { Launch } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { ACTION_TYPE } from "../../../types/actionTypes";
import STRINGS from "../../../CaseFollowUps/components/utils/strings";

const DescriptionHeader = ({
  navigateToDetail,
  followUpName,
  clientName,
  actionType,
  caseId,
}) => {
  const params = useParams();

  return (
    <div
      id="Follow-Up-Description-Header"
      data-testid="Follow-Up-Description-Header"
      className="py-2 text-[1.25rem]"
    >
      <div className="py-2 text-[1.25rem]">
      {STRINGS.SCHEDULED_FOR}: <span className="bold">{followUpName}</span>
      </div>
      {(actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP ||
        actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) && (
          <div className="py-2 text-[1rem]">
            Client Name: <span className="bold">{clientName}</span>
          </div>
        )}
      {params.id !== caseId.toString() && (
        <div>
          <Button
            title="Open Case Details"
            className="hover:text-white hover:bg-sky-600 text-sky-600"
            onClick={() => navigateToDetail(caseId)}
          >
            Navigate to Case <Launch />
          </Button>
        </div>
      )}
    </div>
  );
};

export default DescriptionHeader;
