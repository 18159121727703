import { Alert, AlertTitle, Button, Dialog } from "@mui/material";
import React from "react";

function MessageDialog({
  open,
  message,
  isError,
  onClose,
  title,
  fullWidth = true,
  maxWidth = "sm"
}) {
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
      <Alert
        variant="outlined"
        severity={isError ? "error" : "success"}
        role={isError ? "error" : "alert"}
      >
        {title &&
          <AlertTitle>
            {title}
          </AlertTitle>}
        {message &&
          <p>
            {message}
          </p>}
      </Alert>
      {onClose &&
        <Button color={isError ? "error" : "success"} onClick={onClose}>
          OK
        </Button>}
    </Dialog>
  );
}

export default MessageDialog;
