import MultiSelectSearchV3 from "./MultiSelectSearchV3";
import { ListItem } from "@mui/material";
export default function LawFirmFilter({
    selectedItems,
    setSelectedItems,
    setActiveItems,
    activeItems,
    resetPagination,
    setFilterState,
}
)
{

    const setSelectedState = (selectedValues) => {
        setSelectedItems(selectedValues);
        setFilterState({ fieldName: "lawFirmFilter", data: selectedValues });
    };

    return(
        <ListItem>
            <div className="shadow p-2 w-full">
                <MultiSelectSearchV3
                title="Filter by Law Firm"
                searchTitle="Law Firm Search"
                selectedItems={selectedItems}
                setSelectedItems={setSelectedState}
                setActiveItems={setActiveItems}
                activeItems={activeItems}
                resetPagination={resetPagination}
                nameKey={"name"}
                valueKey={"pk"}
                apiUrl={`/api/lawfirm/?`}
                buttonFullW={true}
                dropdown={false}
                />
            </div>
        </ListItem>
    );

}