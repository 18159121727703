import { styled, Tooltip, tooltipClasses } from "@mui/material";
import BaseField from "../../ui/BaseField";
import ValueWithFallback from "../../ui/ValueWithFallback";
import AppointmentNextModel from "./AppointmentNextModel";
import { TooltipStyling } from "../../utils/TooltipStyling";
import STRINGS from "../../utils/strings";

const AppointmentNext = ({ fieldName, value, providerName, flag, clock }) => {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));
  return (
    <BaseField
      id="SB-Appointment-Next"
      testId="SB-Appointment-Next"
      fieldName={fieldName}
      label={"Next"}
      flag={flag}
      clock={clock}
      customFontSize={"14px"}
    >
      <HtmlInfoTooltip
        title={
          providerName && (
            <div>
              {STRINGS.NEXT_APPOINTMENT}
              <br />
              {providerName}
            </div>
          )
        }
      >
        <span className="whitespace-pre">{ValueWithFallback(AppointmentNextModel(value, true), true)}</span>
      </HtmlInfoTooltip>
    </BaseField>
  );
};

export default AppointmentNext;
