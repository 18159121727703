import { createContext, useReducer } from "react";
import { followUpReducer, initialState } from "../state/followupReducer";

export const FollowUpDispatchContext = createContext();
export const FollowUpStateContext = createContext();

const FollowUpProvider = ({ children }) => {
  const [state, dispatch] = useReducer(followUpReducer, initialState);

  return (
    <FollowUpDispatchContext.Provider value={dispatch}>
      <FollowUpStateContext.Provider value={state}>
        {children}
      </FollowUpStateContext.Provider>
    </FollowUpDispatchContext.Provider>
  );
};

export default FollowUpProvider;
