import { createContext, useState } from "react";
const CaseSummaryContext = createContext({});

export const CaseSummaryProvider = ({ children }) => {
  const [caseDetail, setCaseDetail] = useState("");
  const [caseSummaryTrigger, setCaseSummaryTrigger] = useState(false);
  const [policyDetail, setPolicyDetail] = useState("");
  const [policyTrigger, setPolicyTrigger] = useState(false);
  const [coverageDetail, setCoverageDetail] = useState("");
  const [coverageTrigger, setCoverageTrigger] = useState(false);
  const [updatedValObj, setUpdatedValObj] = useState({});
  const [caseValueLabels, setCaseValueLabels] = useState({});
  const [coverageValueLabels, setCoverageValueLabels] = useState({});
  const [policyValueLabels, setPolicyValueLabels] = useState({});
  const [caseSources, setCaseSources] = useState({});
  const [documentTrigger, setDocumentTrigger] = useState(false);
  const [clientInfoTab, setClientInfoTab] = useState(0);

  return (
    <CaseSummaryContext.Provider
      value={{
        caseDetail,
        setCaseDetail,
        caseSummaryTrigger,
        setCaseSummaryTrigger,
        policyDetail,
        setPolicyDetail,
        policyTrigger,
        setPolicyTrigger,
        coverageDetail,
        setCoverageDetail,
        coverageTrigger,
        setCoverageTrigger,
        updatedValObj,
        setUpdatedValObj,
        caseValueLabels,
        setCaseValueLabels,
        coverageValueLabels,
        setCoverageValueLabels,
        policyValueLabels,
        setPolicyValueLabels,
        caseSources,
        setCaseSources,
        documentTrigger,
        setDocumentTrigger,
        clientInfoTab,
        setClientInfoTab,
      }}
    >
      {children}
    </CaseSummaryContext.Provider>
  );
};

export default CaseSummaryContext;
