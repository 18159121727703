import { ControlPointOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material/";
import React, { useContext } from "react";
import PaginationControls from "../../../global/PaginationControls";
import DataContext from "../../../../context/DataContext";

export default function LawfirmQuickUpdateList({
  title,
  isDashboardVersion,
  responseData,
  loading,
  resultClasses,
  format,
  search,
  setSearch,
  navigateToDetail,
  updateValue,
  toolTipTitle,
  checkBoxInput,
  inputVal,
  handleCheck,
  handleChange,
  resultCount,
  currentPage,
}) {
  const { userRoles } = useContext(DataContext);
  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };
  const disableButtons = userRoles.permissions.includes(
    "casemanager.change_case"
  )
    ? false
    : true;
  return (
    <div className="w-full md:ml-4">
      {isDashboardVersion ? (
        ""
      ) : (
        <h1 className={`text-xl text-purple-600 text-center my-4 font-bold`}>
          {title}
        </h1>
      )}
      <div className="flex justify-center mt-4">
        <Tooltip title="Search by Name">
          <TextField
            className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
            placeholder="Search"
            size="small"
            name="search"
            value={search}
            InputLabelProps={{ shrink: true }}
            label="Search"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Tooltip>
      </div>
      <div className="h-full overflow-hidden">
        <div className="flex justify-between my-2">
          <p className="pl-5 py-4">
            Results: {resultCount.current}&nbsp;/&nbsp;{resultCount.total}
          </p>
          <div>&nbsp;</div>
        </div>
        <div className="flex md:px-4 px-2 text-center py-2 border-b border-1 border-gray">
          <div className="md:w-1/5 w-1/3 font-bold">
            <h2>CLIENT NAME</h2>
          </div>
          <div className="w-1/5 md:block hidden font-bold">
            <h2>CASE NAME</h2>
          </div>
          <div className="w-1/5 md:block hidden font-bold">
            <h2>DATE CREATED</h2>
          </div>
          <div className="md:w-1/5 w-1/3 font-bold">
            <h2>STATUS</h2>
          </div>
          <div className="md:w-1/5 w-1/3 font-bold">
            <h2>QUICK UPDATE</h2>
          </div>
        </div>
        <List
          className={`${
            isDashboardVersion ? `max-h-[65vh]` : "max-h-[100%]"
          } overflow-y-auto`}
        >
          {loading === "true" ? (
            <div className="flex justify-center my-24">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              {responseData.map((client, index) => (
                <ListItem
                  key={index}
                  value={client}
                  className="py-0 pt-0 md:px-4 px-2"
                >
                  <div
                    className={
                      "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y justify-between"
                    }
                  >
                    <span
                      className={`block md:w-1/5 w-1/3 text-left pl-2 text-left ${resultClasses}`}
                    >
                      <Button
                        onClick={() => navigateToDetail(client)}
                        className="text-purple-600 hover:bg-purple-600 hover:text-white"
                      >
                        {!client ? "" : client.client.name}{" "}
                      </Button>
                    </span>
                    <span
                      className={`block w-1/5 md:block hidden text-left pl-2 text-center ${resultClasses}`}
                    >
                      <Tooltip title={!client ? "" : client.name}>
                        {!client
                          ? ""
                          : isDashboardVersion
                          ? truncate(client.name, 5, 15)
                          : client.name}{" "}
                      </Tooltip>
                    </span>
                    <span
                      className={`block w-1/5 md:block hidden text-left pl-2 text-center ${resultClasses}`}
                    >
                      {!client.created_at
                        ? ""
                        : new Date(client.created_at)
                            .toLocaleString("en-us", format)
                            .split(",")[0]}
                    </span>
                    <span
                      className={`block md:w-1/5 w-1/3 text-left pl-2 text-center ${resultClasses}`}
                    >
                      {!client ? "" : client.status}
                    </span>
                    <span
                      className={`block w-1/5 text-left pl-2 text-center ${resultClasses} border-none`}
                    >
                      {!checkBoxInput ? (
                        <IconButton
                          disabled={disableButtons}
                          onClick={() => updateValue(client.pk)}
                          className="hover:bg-green-600 hover:text-white text-green-600 rounded-md"
                          style={disableButtons ? { color: "gray" } : {}}
                        >
                          {" "}
                          <Tooltip title={toolTipTitle}>
                            <ControlPointOutlined />
                          </Tooltip>
                        </IconButton>
                      ) : (
                        <FormControlLabel
                          name="police_report_received"
                          disabled={disableButtons}
                          control={
                            <Tooltip title={toolTipTitle}>
                              <Checkbox color="success" checked={inputVal} />
                            </Tooltip>
                          }
                          onChange={(e) => handleCheck(e, client.pk)}
                        />
                      )}
                    </span>
                  </div>
                </ListItem>
              ))}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </>
          )}
        </List>
      </div>
    </div>
  );
}
