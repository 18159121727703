import { useCallback, useContext, useEffect, useState } from "react";
import { ButtonsConfig } from "../../../CaseFollowUps/components/utils/buttonsConfig";
import STRINGS from "../../../CaseFollowUps/components/utils/strings";
import DialogHeader from "../DialogHeader";
import FollowUpForm from "./FollowUpForm/FollowUpForm";
import DataContext from "../../../../../../../../../context/DataContext";
import { SelectOptionsProCliStatusDataProcessor } from "../../../../../../../../global/FormComponents/SelectBasic/utils/SelectOptionsProCliStatusDataProcessor";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import { case_detail } from "../../../../../../../../api/endpoints/case";
import { ACTION_TYPE } from "../../../CaseFollowUps/components/types/actionTypes";
import DescriptionHeader from "./DescriptionHeader";

const EditFollowUp = ({
  title,
  selectedFollowUp,
  onClickSubmit,
  actionType,
  caseInfo,
  formIsTouched,
  navigateToDetail,
}) => {
  const buttons = [
    ButtonsConfig.SubmitButtons.update,
    ButtonsConfig.SubmitButtons.markComplete,
  ];
  const { accessToken } = useContext(DataContext);
  const [proCliOptions, setProCliOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [getProCliOptions, setGetProCliOptions] = useState(true);

  const createFieldOptions = useCallback(async (results) => {
    return SelectOptionsProCliStatusDataProcessor(results, "No Selection");
  }, []);

  const fetchFieldOptionsData = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorMessage(errArr);
      });
  }, []);

  useEffect(() => {
    let active = true;

    if (active) {
      if (!proCliOptions) {
        if (getProCliOptions) {
          const fieldOptionsUrls = [
            case_detail.getProvidersOnCase(caseInfo.caseId, accessToken),
          ];
          fetchFieldOptionsData(fieldOptionsUrls)
            .then(async (results) => {
              if (results) {
                if (results[0]) {
                  let options = await createFieldOptions(results[0].results);
                  setProCliOptions(options);
                }
              }

              return results;
            })
            .then((results) => {
              if (getProCliOptions) {
                setGetProCliOptions(false);
              }
              return results;
            })
            .catch((error) => {
              let errArr = ApiRequestErrorHandler(error.response);
              setErrorMessage(errArr);
            });
        }
      }
    }
    return () => {
      active = false;
    };
  }, [
    accessToken,
    getProCliOptions,
    proCliOptions,
    caseInfo.caseId,
    createFieldOptions,
    fetchFieldOptionsData,
  ]);

  const handleSubmit = (submitType, formData) => {
    let newFormData = {
      ...formData,
      action_type: actionType,
      on_case: caseInfo?.caseId,
      ...(selectedFollowUp.actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP &&
      caseInfo?.lawFirmId
        ? { on_firm: caseInfo.lawFirmId }
        : {}),
    };
    onClickSubmit(submitType, newFormData);
  };

  return (
    <div id="Follow-Up-Edit" data-testid="Follow-Up-Edit">
      <DialogHeader>{title}</DialogHeader>
      {selectedFollowUp && (
        <DescriptionHeader
          navigateToDetail={navigateToDetail}
          followUpName={selectedFollowUp.label}
          clientName={selectedFollowUp.client?.name ? selectedFollowUp.client.name : ""}
          caseId={caseInfo.caseId}
          actionType={selectedFollowUp.actionType}
        />
      )}
      <FollowUpForm
        id={null}
        title={STRINGS.DIALOG_HEADER_EDIT_FOLLOWUP}
        selectedFollowUp={selectedFollowUp}
        submitForm={handleSubmit}
        buttonsArr={buttons}
        isProviderFollowUp={
          selectedFollowUp.actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP
        }
        isProviderEditable={false}
        providerOptions={proCliOptions}
        setGetProCliOptions={setGetProCliOptions}
        isFormTouched={formIsTouched}
      />
    </div>
  );
};

export default EditFollowUp;
