export const SHOULD_FETCH_DATA = "SHOULD_FETCH_DATA";
export const FETCH_IS_LOADING = "FETCH_IS_LOADING";
export const FETCH_INPROGRESS = "FETCH_INPROGRESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_DONE = "FETCH_DONE";

export const SET_FILTERS = "SET_FILTERS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_ACTION_TYPES = "SET_ACTION_TYPES";
export const SET_DATES = "SET_DATES";

export const RESLIMIT_SET = "RESLIMIT_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";
export const CURRENT_PAGE_URL_SET = "CURRENT_PAGE_URL_SET";

export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";

export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const SET_FIELD_DATA_IS_LOADING = "SET_FIELD_DATA_IS_LOADING";

export const SET_FIELD_META_DATA = "SET_FIELD_META_DATA";
export const SHOULD_FETCH_META_DATA = "SHOULD_FETCH_META_DATA";
export const SET_FIELD_META_DATA_IS_LOADING = "SET_FIELD_META_DATA_IS_LOADING";
export const SET_CASE_INFO = "SET_CASE_INFO";
export const SET_SELECTED_FOLLOWUP = "SET_SELECTED_FOLLOWUP";
export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const SHOW_CONTENT_SET = "SHOW_CONTENT_SET";
export const SHOW_BACK_TO_FOLLOWUPLIST_BUTTON =
  "SHOW_BACK_TO_FOLLOWUPLIST_BUTTON";
export const CONTENT_ADD_FOLLOWUP = "CONTENT_ADD_FOLLOWUP";
export const CONTENT_FOLLOWUPLIST = "CONTENT_FOLLOWUPLIST";
export const CONTENT_EDIT_FOLLOWUP = "CONTENT_EDIT_FOLLOWUP";
export const CONTENT_ERROR = "CONTENT_ERROR";
export const CONTENT_SUCCESS = "CONTENT_SUCCESS";
export const CONTENT_LOADING_TOGGLE = "CONTENT_LOADING_TOGGLE";

export const DIALOG_IS_TOUCHED = "DIALOG_IS_TOUCHED";

export const SET_SHOW_ADD_BUTTON = "SET_ADD_SHOW_ADD_BUTTON";


export const setShouldFetchData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_DATA, payload: bool });

export const setfetchIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_IS_LOADING, payload: bool });

export const setfetchInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_INPROGRESS, payload: obj });

export const setfetchSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_SUCCESS, payload: bool });

export const setfetchError = (dispatch, arr) =>
  dispatch({ type: FETCH_ERROR, payload: arr });

export const setfetchDone = (dispatch, bool) =>
  dispatch({ type: FETCH_DONE, payload: bool });

export const setfilters = (dispatch, obj) =>
  dispatch({ type: SET_FILTERS, payload: obj });

export const setResetLimit = (dispatch, num) =>
  dispatch({ type: RESLIMIT_SET, payload: num });

export const setResultCount = (dispatch, num) =>
  dispatch({ type: RESULT_COUNT_SET, payload: num });

export const setCurrentPage = (dispatch, num) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: num });

export const setCurrentPageURL = (dispatch, str) =>
  dispatch({ type: CURRENT_PAGE_URL_SET, payload: str });

export const setSearch = (dispatch, str) =>
  dispatch({ type: SET_SEARCH, payload: str });

export const setActionTypes = (dispatch, arr) =>
  dispatch({ type: SET_ACTION_TYPES, payload: arr });
export const setCalendarEvents = (dispatch, arr) =>
  dispatch({ type: SET_CALENDAR_EVENTS, payload: arr });


export const toggleContentIsLoading = (dispatch, bool) =>
  dispatch({ type: CONTENT_LOADING_TOGGLE, payload: bool });

export const setSelectedFollowUp = (dispatch, obj) =>
  dispatch({ type: SET_SELECTED_FOLLOWUP, payload: obj });

export const showContent = (dispatch, text) =>
  dispatch({ type: SHOW_CONTENT_SET, payload: text });

export const toggleDialog = (dispatch, bool) =>
  dispatch({ type: TOGGLE_DIALOG, payload: bool });

export const setShowAddButton = (dispatch, bool) =>
  dispatch({ type: SET_SHOW_ADD_BUTTON, payload: bool });

export const setShowBackToFollowUpListButton = (dispatch, bool) =>
  dispatch({ type: SHOW_BACK_TO_FOLLOWUPLIST_BUTTON, payload: bool });

export const setDialogIsTouched = (dispatch, bool) =>
  dispatch({ type: DIALOG_IS_TOUCHED, payload: bool });

export const setCaseInfo = (dispatch, obj) =>
  dispatch({ type: SET_CASE_INFO, payload: obj });

export const setDates = (dispatch, obj) =>
  dispatch({ type: SET_DATES, payload: obj});