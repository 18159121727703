import React, { useContext } from "react";
import ContactMangementSection from "./ContactManagementSection";
import BasicInfoSection from "./BasicInfoSection";
import AvailabilitySection from "./AvailabilitySection";
import DataContext from "../../../../../../context/DataContext";
import PhotoGallery from "./PhotoGallery";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../../../global/TabPanel";
import a11yProps from "../TabClientNotesCallLog/a11yProps";
import CaseSummaryContext from "../CaseSummaryV2/context/CaseSummaryContext";

export default function ClientInfoV2({
  caseObj,
  setUploadDocument,
  docNeedsType,
  setViewDocuments,
  docTrigger,
  setDocTrigger,
}) {
  const { userRoles } = useContext(DataContext);
  const { clientInfoTab, setClientInfoTab } = useContext(CaseSummaryContext);

  const openUploadModal = () => {
    if (!docNeedsType || docNeedsType.docs_with_no_type === 0) {
      setUploadDocument(true);
      return;
    } else if (docNeedsType.docs_with_no_type > 0) {
      setUploadDocument(true);
      setViewDocuments(true);
    }
  };

  const handleChange = (event, newValue) => {
    //keep event param even though unused
    setClientInfoTab(newValue);
  };

  const generateDetailTabs = () => {
    let detailComps = [];
    if (userRoles.permissions.includes("users.view_usercontacthours")) {
      detailComps.push({
        tabTitle: "Availability",
        order: 2,
        component: <AvailabilitySection caseObj={caseObj} />,
      });
    }
    if (userRoles.permissions.includes("users.view_user")) {
      detailComps.push(
        {
          tabTitle: "Personal",
          order: 1,
          component: (
            <BasicInfoSection
              caseObj={caseObj}
              viewPermissions={userRoles.permissions.includes(
                "users.view_user"
              )}
            />
          ),
        },
        {
          tabTitle: "Contact",
          order: 3,
          component: <ContactMangementSection caseObj={caseObj} />,
        }
      );
    }
    if (userRoles.permissions.includes("filemanager.view_document")) {
      detailComps.push({
        tabTitle: "Images",
        order: 4,
        component: (
          <PhotoGallery
            openUploadModal={openUploadModal}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
            docNeedsType={docNeedsType}
          />
        ),
      });
    }
    return detailComps;
  };

  const DetailTabs = generateDetailTabs();

  return (
    <div className="xl:max-h-[60vh] h-[60vh] overflow-y-auto w-full flex">
      <Box
        sx={{
          display: "flex",
          flexFlow: 1,
          width: "100%",
          margin: "0 1rem",
        }}
      >
        <Tabs
          orientation="vertical"
          value={clientInfoTab}
          onChange={handleChange}
          aria-label="client-info-tab-panel"
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            width: "25%",
            borderRight: 0,
            borderColor: "white",
            "& button": {
              border: "1px solid #9333EA",
              borderRadius: "8px",
              margin: "10px 0",
              textTransform: "none",
            },
            "& button.Mui-selected": {
              backgroundColor: `#9333EA`,
              color: "#FFF",
              borderRight: 0,
            },
            ".Mui-selected": {
              color: "#FFF",
              borderRight: 0,
            },
            ".MuiBox-root.div": {
              padding: 0,
            },
          }}
        >
          {DetailTabs.sort((a, b) => a.order - b.order).map((tab, idx) => (
            <Tab key={idx} label={tab.tabTitle} {...a11yProps(idx)} />
          ))}
        </Tabs>
        <div className="w-[70%] mx-2">
          {DetailTabs.sort((a, b) => a.order - b.order).map((tab, idx) => (
            <TabPanel value={clientInfoTab} index={idx} key={idx}>
              <div>{tab.component}</div>
            </TabPanel>
          ))}
        </div>
      </Box>
    </div>
  );
}
