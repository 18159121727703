import ApiServices from "../service";
import qs from "query-string";

export const case_detail = {
  patch: (casePk, params) => `/api/case/${!casePk ? params.id : casePk}/`,

  getProvidersOnCase: (id, accessToken) =>
    ApiServices.getData(
      `api/pro_cli_status/?case=${id}&ordering=-provider__name`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getCaseByClientId: (id, accessToken) =>
    ApiServices.getData(`/api/case/?client=${id}&simplified=true`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getStaticBarData: (id, accessToken) =>
    ApiServices.getData(`/api/case/${id}/static_bar/`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getStaticBarTooltipData: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=staticbar&limit=100`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getFollowUpData: (id, orderingValue, pagination, actionType, accessToken) => {
    let ordering = orderingValue ? `ordering=${orderingValue}` : "";
    let paginate = pagination ? `${pagination}` : "";

    let deliminator = paginate ? "&" : "";
    let deliminator2 = ordering ? "&" : "";
    return ApiServices.getData(
      `/api/actionable_items/?on_case=${id}&action_type__in=${actionType}${deliminator}${paginate}${deliminator2}${ordering}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err);
  },

  getPendingFollowUpData: (id, actionType, accessToken) =>
    ApiServices.getData(
      `/api/actionable_items/?on_case=${id}&action_type=${actionType}&status=pending`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getFollowUpTooltipData: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=actionableitems&field_name=action_type`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  createFollowUp: (data, accessToken) =>
    ApiServices.postData(`/api/actionable_items/`, data, accessToken)
      .then((res) => {
        if (res.status === 400) {
          throw new Error(res.data);
        }
        return res;
      })
      .catch((err) => {
        throw new Error(err.response.data["__all__"]);
      }),

  updateFollowUp: (followUpId, data, accessToken) =>
    ApiServices.patchData(
      `/api/actionable_items/${followUpId}`,
      data,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getFollowUpDataByActingUser: (
    actingUserId,
    orderingValue,
    pagination,
    actionType,
    managedUserFilter,
    providerFilter,
    followUpFilter,
    medicalStatusFilter,
    caseStatusFilter,
    lawFirmFilter,
    startDateFilter,
    endDateFilter,
    searchValue,
    accessToken
  ) => {
    let ordering = orderingValue ? `ordering=${orderingValue}` : "";
    let paginate = pagination ? `${pagination}` : "";
    if (
      new Set(managedUserFilter).size > 1 &&
      managedUserFilter.includes(actingUserId)
    ) {
      managedUserFilter = managedUserFilter.filter((x) => x !== actingUserId);
    }
    let filterObj = {
      ...(managedUserFilter
        ? managedUserFilter.length === 1
          ? { assigned_to_case: Array.from(new Set(managedUserFilter)) }
          : { assigned_to_case__in: Array.from(new Set(managedUserFilter)) }
        : {}),
      ...(providerFilter
        ? { on_provider__in: Array.from(new Set(providerFilter)) }
        : {}),
      ...(caseStatusFilter
        ? { on_case__overall_status__in: Array.from(new Set(caseStatusFilter)) }
        : {}),
      ...(medicalStatusFilter
        ? { on_case__status__in: Array.from(new Set(medicalStatusFilter)) }
        : {}),
      ...(lawFirmFilter
        ? { on_firm__in: Array.from(new Set(lawFirmFilter)) }
        : {}),
    };
    let filters = qs.stringify(filterObj, { arrayFormat: "comma" });
    actionType =
      !followUpFilter ||
      followUpFilter.length === 0 ||
      (followUpFilter.length === 2 && followUpFilter[0] === followUpFilter[1])
        ? [2, 3, 4]
        : Array.from(new Set(followUpFilter));
    let search = searchValue && searchValue.trim() ? `${searchValue}` : "";

    let startDate = startDateFilter ? "&due_date__gte=" + startDateFilter : "";
    let endDate = endDateFilter ? "&due_date__lte=" + endDateFilter : "";
    let dates = startDate + endDate;

    let deliminator = paginate ? "&" : "";
    let deliminator2 = ordering ? "&" : "";
    let deliminator3 = search ? "&search=" : "";
    let deliminator4 = filters ? "&" : "";

    return ApiServices.getData(
      `/api/actionable_items/?action_type__in=${actionType}${deliminator3}${search}${deliminator4}${filters}${deliminator}${paginate}${deliminator2}${ordering}${dates}&status=pending&limit=1000`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err);
  },
};

export const case_fee_schedule = {
  retrieve: (id, accessToken) =>
    ApiServices.getData(`/api/case/${id}/fee_schedule/`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
};
