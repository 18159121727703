import { styled, Tooltip, tooltipClasses } from "@mui/material";
import BaseField from "../../ui/BaseField";
import ValueWithFallback from "../../ui/ValueWithFallback";
import AppointmentLastModel from "./AppointmentLastModel";
import { TooltipStyling } from "../../utils/TooltipStyling";
import STRINGS from "../../utils/strings";

const AppointmentLast = ({ fieldName, value, providerName, flag, clock }) => {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));
  return (
    <BaseField
      id="SB-Appointment-Last"
      testId="SB-Appointment-Last"
      fieldName={fieldName}
      label={"Last"}
      flag={flag}
      clock={clock}
      customFontSize={"14px"}
    >
      <HtmlInfoTooltip
        title={
          providerName && (
            <div>
              {STRINGS.LAST_APPOINTMENT}
              <br />
              {providerName}
            </div>
          )
        }
      >
        <span className="whitespace-pre">{ValueWithFallback(AppointmentLastModel(value, true), true)}</span>
      </HtmlInfoTooltip>
    </BaseField>
  );
};

export default AppointmentLast;
