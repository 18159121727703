import { CloseOutlined } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Dialog, TextField } from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ModalButton from "../../../global/ModalButton";
import PrintableClientList from "../../../global/PrintableClientList";
import LawfirmQuickUpdateList from "./LawfirmQuickUpdateList";
import ResponseModal from "../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";

export default function LawfirmPropertyDamage({ isDashboardVersion }) {
  const { trigger, setTrigger, accessToken } = useContext(DataContext);
  const [search, setSearch] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [activeClientId, setActiveClientId] = useState();
  const [propertyDamage, setPropertyDamage] = useState();
  const [paginationUrl, setPaginationUrl] = useState("");
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const navigateToDetail = (client) => {
    navigate(`/client-detail/${client.pk}`);
  };

  const sendRequest = () => {
    setLoading(true);
    axios
      .get(
        `/api/case/?${qs.stringify(
          {
            property_damage_amount__isnull: true,
            search: !search ? null : search,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [search, paginationUrl, setPaginationUrl, trigger],
    250
  );

  function propertyDmgUpdate(clientId) {
    setModalActive(true);
    setActiveClientId(clientId);
  }

  function updatePropertyDmg() {
    const data = {
      property_damage_amount: propertyDamage,
    };
    axios
      .patch(`/api/case/${activeClientId}/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setTrigger(!trigger);
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
          setModalActive(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
        setOpenError(!openError);
      });
  }

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `Clients Missing Property Damage`,
  // });

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`header-wrapper px-4 flex ${
          isDashboardVersion
            ? ""
            : "h-full overflow-hidden"
        }`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <LawfirmQuickUpdateList
          title="Clients Missing Property Damage"
          responseData={responseData}
          isDashboardVersion={isDashboardVersion}
          resultClasses={resultClasses}
          loading={loading}
          format={format}
          search={search}
          setSearch={setSearch}
          navigateToDetail={navigateToDetail}
          updateValue={propertyDmgUpdate}
          handleChange={handleChange}
          resultCount={resultCount}
          currentPage={currentPage}
          toolTipTitle="Add Property Damage"
        />
        {/* PRINTABLE SECTION */}
        <PrintableClientList
          responseData={responseData}
          componentRef={componentRef}
          loading={loading}
          format={format}
          title="Clients Missing Property Damage"
        />
        <Dialog open={modalActive}>
          <Alert
            variant="outlined"
            severity="info"
            role="alert"
            style={{ paddingRight: "40px" }}
          >
            <AlertTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 0 1rem",
              }}
            >
              Policy Limit
              <Button
                onClick={() => setModalActive(false)}
                style={{ padding: "0" }}
              >
                <CloseOutlined />
              </Button>
            </AlertTitle>
            <h1
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                margin: "1rem 0",
              }}
            >
              Quick Post Property Damage Cost:{" "}
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                className="w-full text-center mb-6 mt-4 px-2"
                id="property_damage_cost"
                label="Property Damage Cost"
                value={propertyDamage}
                type="number"
                onChange={(event) => {
                  setPropertyDamage(event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
              />
              <br />
              <ModalButton
                className="block"
                action={updatePropertyDmg}
                baseColor="#1976d2"
                icon="add"
                content={"Add Property Damage"}
              />
            </div>
          </Alert>
        </Dialog>

        <ResponseModal
          title="Property Damage Updated"
          description="Successfully updated client Property Damage"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
        />
        <ResponseModal
          title="Error Updating Property Damage"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
