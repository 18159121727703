import {createContext, useState } from "react";
const FilterContext = createContext({});

export const FilterProvider = ({children}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);

    const [selectedProviders, setSelectedProviders] = useState([]);
    const [activeProviders, setActiveProviders] = useState([]);
  
    const [selectedFollowUps, setSelectedFollowUps] = useState([]);
    const [activeFollowUps, setActiveFollowUps] = useState([]);
  
    const [selectedCaseStatus, setSelectedCaseStatus] = useState([]);
    const [activeCaseStatus, setActiveCaseStatus] = useState([]);
  
    const [selectedMedicalStatus, setSelectedMedicalStatus] = useState([]);
    const [activeMedicalStatus, setActiveMedicalStatus] = useState([]);
  
    const [selectedLawFirms, setSelectedLawFirms] = useState([]);
    const [activeLawFirms, setActiveLawFirms] = useState([]);

    const [paginationUrl, setPaginationUrl] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    return(
        <FilterContext.Provider
        value ={{
            selectedUsers,
            setSelectedUsers,
            activeUsers,
            setActiveUsers,
            selectedProviders,
            setSelectedProviders,
            activeProviders,
            setActiveProviders,
            selectedFollowUps,
            setSelectedFollowUps,
            activeFollowUps,
            setActiveFollowUps,
            selectedMedicalStatus,
            setSelectedMedicalStatus,
            activeMedicalStatus,
            setActiveMedicalStatus,
            selectedCaseStatus,
            setSelectedCaseStatus,
            activeCaseStatus,
            setActiveCaseStatus,
            selectedLawFirms,
            setSelectedLawFirms,
            activeLawFirms,
            setActiveLawFirms,
            currentPage,
            setCurrentPage,
            paginationUrl,
            setPaginationUrl,
        }}>
            {children}
        </FilterContext.Provider>
    )
}

export default FilterContext;