import React, { useContext } from "react";
import AvailabilityTable from "../../components/AvailabilityTable";
import DataContext from "../../../../../../../../context/DataContext";

export default function ClientAvailability({ caseObj }) {
  const { userRoles } = useContext(DataContext);

  return (
    <div className="flex flex-col items-start w-full p-1">
      <h2 className="text-sm text-gray-400 uppercase mb-5">
        {"Client Availability: "}
      </h2>

      <div className="max-h-[45vh] overflow-y-auto w-full mb-5">
        {!userRoles.permissions.includes("users.view_usercontacthours") ||
        !caseObj ? (
          <h2 className="text-center underline my-2 text-gray-500">
            Content details not available for this account at this time
          </h2>
        ) : (
          <AvailabilityTable caseObj={caseObj} />
        )}
      </div>
    </div>
  );
}
