import MultiSelectFilterV3 from "./MultiSelectFilterV3";
import { ListItem } from "@mui/material";
export default function MedicalStatusFilter({
    selectedItems,
    setSelectedItems,
    setActiveItems,
    activeItems,
    resetPagination,
    setFilterState,
}
)
{

    const setSelectedState = (selectedValues) => {
        setSelectedItems(selectedValues);
        setFilterState({ fieldName: "medicalStatusFilter", data: selectedValues });
    };

    return(
        <ListItem>
            <div className="shadow p-2 w-full">
                <MultiSelectFilterV3
                title="Filter by Medical Status"
                selectedItems={selectedItems}
                setSelectedItems={setSelectedState}
                setActiveItems={setActiveItems}
                activeItems={activeItems}
                nameKey="label"
                valueKey="key"
                resetPagination={resetPagination}
                searchTitle="Follow Ups"
                apiUrl = {`/api/field_options/?content_type=case&field_name=status&ordering=order&`}
                buttonFullW={true}
                dropdown={false}
                filterName={'medicalStatusFilter'}
                setFilterState={setFilterState}
                />
            </div>
      </ListItem>
    );

}