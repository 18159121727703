import { useContext } from "react";
import Clock from "./Clock/Clock";
import {
  CONTENT_EDIT_FOLLOWUP,
  setSelectedFollowUp,
  showContent,
  toggleDialog,
} from "../../state/actions";
import { getColor } from "../utils/getColor";
import { FollowUpDispatchContext } from "../../context/FollowUpContext";
import DateModel from "../fields/DateModel";
import NameModel from "../fields/NameModel";
import { CONTENT_VIEW_FOLLOWUP } from "../../../FollowUpModal/components/modalcontent/HistoryListView/state/actions";
import { STATUS_TYPES } from "../types/statusTypes";
import {tooltipData} from "../utils/tooltipData";
import { Button } from "@mui/material";

const FollowUpItem = ({
  id,
  clock,
  label,
  timeTillDueDate,
  dueDate,
  detail,
  provider,
  actionType,
  status,
  completedAt,
  completedBy,
  updatedAt,
  updatedBy,
  client
}) => {
  const dispatch = useContext(FollowUpDispatchContext);
  const bgShade = 100;
  const defaultShade = 500;

  const getMainColor = (value) => {
    return getColor(value, defaultShade);
  };
  const getBgColor = (value) => {
    return getColor(value, bgShade) + '50';
  };
  const showDialog = () => {
    let dialogContent = CONTENT_VIEW_FOLLOWUP;

    if (status === STATUS_TYPES.PENDING) {
      dialogContent = CONTENT_EDIT_FOLLOWUP;
    }
    showContent(dispatch, dialogContent);
    toggleDialog(dispatch, true);

    setSelectedFollowUp(dispatch, {
      id: id,
      clock: clock,
      label: label,
      timeTillDueDate: timeTillDueDate,
      status: status,
      actionType: actionType,
      completedBy: completedBy,
      completedAt: completedAt,
      updatedBy: updatedBy,
      updatedAt: updatedAt,
      client:client,
      fields: {
        dueDate: dueDate,
        detail: detail,
        ...(provider
          ? { provider: provider }
          : {}),
      },
    });
  };

  return (
    <Button
      className="followup-item capitalize text-slate-900 w-full grid grid-cols-12 gap-0 py-2 border-t-[2px] border-solid border-slate-500 bg-white rounded-md opacity-100 hover:opacity-80"
      style={{
        borderTop: clock
          ? "2px solid " + getMainColor(clock)
          : "2px solid bg-slate-500",
        backgroundColor: clock ? getBgColor(clock) : "bg-slate-100",
      }}
      onClick={() => showDialog()}
    >
      <div>
        {clock && (
          <Clock
            clockValue={clock}
            tooltipTitle={null}
            tooltipDesc={null}
            tooltipObj={tooltipData.clock_state_tooltip}
            size={1}
          />
        )}
      </div>
      <div className="col-span-3 text-sm">{label && NameModel(label)}</div>
      <div className="col-span-5 text-sm">
        {timeTillDueDate && timeTillDueDate}
      </div>
      <div className="col-span-3  text-sm">{dueDate && DateModel(dueDate)}</div>
    </Button>
  );
};

export default FollowUpItem;
