import { TextField } from "@mui/material";
import Chips from "./Filters/Chips";

const Search = ({ currentSearchValue, setSearchState }) => {
  return (
    <div className="">
      <div className="flex items-center mx-3">
        <TextField
          className="placeholder:text-center rounded-lg w-full text-sm"
          placeholder={"Search by client name, reason, assigned user, creation user"}
          size="small"
          name="callLog-search"
          value={currentSearchValue}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => {
            let searchVal = event.target.value;
            searchVal = searchVal.length === 0? ' ':searchVal;
            setSearchState(searchVal);
          }}
        />
      </div>
      <Chips
      currentSearchValue = {currentSearchValue}
      setSearchState = {setSearchState}
      />
    </div>
  );
};
export default Search;